import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Row, Form, Modal, Col, Spin } from "antd";
import Logo from "../../assets/images/blinko-azul.png";
import Localize from "../../components/Localized/Localize.comp";
import _Localize from "../../libs/localization";
import Jwt from "jsonwebtoken";

import { VALIDATE_MESSAGE } from "../../libs/utils";

import "./login.styles.scss";
import { AUTHORIZE_DONE, goTo } from "../../actions";
import { forgotPwdApi, loginApi } from "../../api/auth.api";
import TenantSelector from "../../components/TenantSelector/TenantSelector";
import { getFromLocalStorage } from "../../libs/helpers/localstorage";
import ArrowLeftOutlined from "@ant-design/icons/es/icons/ArrowLeftOutlined";

const LoginPage = () => {
  const [tenant, setTenant] = useState(
    getFromLocalStorage("lefacWebTenant") &&
      getFromLocalStorage("lefacWebTenant").data
  );

  const [loading, setLoading] = useState(false);
  const [forgotPwd, setForgotPwd] = useState(false);

  const dispatch = useDispatch();

  const login = async (values) => {
    const { email, password } = values;
    /*    if (email !== "" && password !== "") {
        console.log("login");
        dispatch(goTo("/"));
      }*/
    setLoading(true);
    const res = await loginApi(values);
    const { user, token, licence, scopes, error = true } = res || {};
    const decodedLicence = Jwt.decode(licence);
    if (!forgotPwd) {
      if (!error) {
        // TOKEN === LICENCE
        dispatch({
          type: AUTHORIZE_DONE,
          data: {
            token,
            licence: decodedLicence,
            user,
            scopes,
          },
        });
        dispatch(goTo("/"));
      } else {
        Modal.info({
          type: !error ? "success" : "error",
          title: _Localize("LOGIN.LOGIN"),
          content: (
            <Row>
              <Col span={24}>
                {user && user.displayName}
                <br />
                {error && _Localize(`ERROR.${res.clientType}`)}
              </Col>
            </Row>
          ),
          okButtonProps: {
            size: "small",
            type: "default",
          },
          okText: _Localize("COMMON.CLOSE"),
          centered: true,
        });
      }
    } else {
      const res = await forgotPwdApi(values);
      Modal.info({
        type: res ? "success" : "error",
        title: Localize("LOGIN.SEND_RESET"),
        content: (
          <Row>
            <Col span={24}>
              {res ? (
                <>{_Localize("LOGIN.SENT_RESET")}</>
              ) : (
                <>{_Localize("ERROR.DATA_ERROR")}</>
              )}
            </Col>
          </Row>
        ),
        okButtonProps: {
          size: "small",
          type: "default",
        },
        okText: _Localize("COMMON.CLOSE"),
        centered: true,
      });
    }
    setLoading(false);
  };

  const layout = {
    wrapperCol: {
      span: 24,
    },
  };

  const tailLayout = {
    wrapperCol: { span: 12 },
  };

  return (
    <>
      {loading && (
        <Modal centered visible={loading} closable={false} footer={null}>
          <Col
            span={24}
            style={{ textAlign: "center", verticalAlign: "middle" }}
          >
            <Spin
              style={{ marginTop: 20, marginBottom: 20, marginRight: 10 }}
            />
            {forgotPwd
              ? Localize("COMMON.SENDING_REQUEST")
              : Localize("LOGIN.LOGGING_IN")}
          </Col>
        </Modal>
      )}
      <div className="login animation fadein-down slow">
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ minHeight: "100vh" }}
        >
          <Row className="login__container ">
            <Row className="login__logoContainer">
              <h1>Sign In</h1>
              <div className="logo">
                <img
                  className="siteLayout__header-logo"
                  alt="logo"
                  src={Logo}
                />
              </div>
            </Row>
            <Row
              justify="space-around"
              align="middle"
              className="login__content"
            >
              <Form
                {...layout}
                layout="horizontal"
                name="login-form"
                onFinish={login}
                validateMessages={VALIDATE_MESSAGE}
              >
                <Form.Item
                  name="email"
                  rules={[{ type: "email", required: true }]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
                {!forgotPwd && (
                  <>
                    <Form.Item name="password" rules={[{ required: true }]}>
                      <Input.Password placeholder="Password" />
                    </Form.Item>

                    <Col span={24} style={{ margin: "30px 0" }}>
                      <TenantSelector onSelect={(value) => setTenant(value)} />
                    </Col>
                  </>
                )}

                <Form.Item className="">
                  <Row justify="space-between" align="middle">
                    <Button
                      {...tailLayout}
                      type="primary"
                      htmlType="submit"
                      disabled={!tenant}
                    >
                      {forgotPwd
                        ? Localize("LOGIN.SEND_RESET")
                        : Localize("LOGIN.LOGIN")}
                    </Button>
                    <span
                      className="pointer"
                      onClick={() => setForgotPwd(!forgotPwd)}
                    >
                      {forgotPwd ? (
                        <>
                          <ArrowLeftOutlined /> {Localize("COMMON.BACK")}
                        </>
                      ) : (
                        Localize("LOGIN.FORGOT_PASSWORD")
                      )}
                    </span>
                  </Row>
                </Form.Item>
              </Form>
            </Row>
          </Row>
        </Row>
      </div>
    </>
  );
};
export default LoginPage;
