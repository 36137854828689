import React, { useEffect, useState } from "react";
import { Button, Checkbox, Col, List, Row, Spin, Tag } from "antd";
import { useLocation } from "react-router-dom";
import StarOutlined from "@ant-design/icons/es/icons/StarOutlined";
import StarFilled from "@ant-design/icons/es/icons/StarFilled";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import PhoneOutlined from "@ant-design/icons/es/icons/PhoneOutlined";
import { MailOutlined, LinkedinOutlined } from "@ant-design/icons/es/icons";
import { searchContactsAction } from "../../actions/search.action";
import ContactModal from "../../pages/contact/contact.modal";
import Localize from "../../libs/localization";
import { deleteAssociatedIdTagAction } from "../../actions/tag.actions";
import { toTitleCase } from "../../libs/utils";

const ContactList = (props) => {
  const {
    data,
    pageSize,
    title = "",
    onRowClick,
    total,
    filters = "",
    tagMode,
    setSelectedContact,
    isTagPage = false,
    loadingTag,
    associatedTagsIds,
    setContactTagPage,
    tags,
  } = props;

  const [pSize, setPSize] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(1);
  const [showContactModal, setShowContactModal] = useState(false);
  const [detailId, setDetailId] = useState(null);

  const [contactIds, setContactIds] = useState([]);
  const [isItemSelected, setIsItemSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false); // attiva/disattiva checkbox

  const saveTags = [];

  const dispatch = useDispatch();
  const location = useLocation();

  const fetchData = (page, pageSize) => {
    if (!isTagPage) {
      if (pSize !== pageSize) {
        setPSize(pageSize);
      }
      const query = queryString.parse(location.search);
      let params = "";

      if (filters) {
        params = {
          ...filters,
          pageNum: page,
          limit: pageSize,
        };
      }

      if (query.key && !params.surname) {
        params = {
          surname: query.key,
          pageNum: page,
          limit: pageSize,
        };
      }
      dispatch(
        searchContactsAction({
          data: params,
        })
      );
    }
  };

  useEffect(() => {
    if (!tagMode) {
      setIsItemSelected([]);
    }
  }, [tagMode]);

  useEffect(() => {
    fetchData(1, pageSize);
  }, [location.key]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters]);

  const openContactDetail = (item) => {
    if (!tagMode) {
      setDetailId(item._id);
      setShowContactModal(true);
    }
  };

  useEffect(() => {
    setIsItemSelected([]);
    setSelectedContact && setSelectedContact([]);
    setContactIds([]);
    setSelectAll(false);
  }, [tagMode]);

  const onStarClick = (item) => {
    let idsToSend = [];
    const founded = isItemSelected.find(
      (itemSelected) => itemSelected.id === item._id
    );

    if (founded) {
      const itemFoundedArr = [...isItemSelected];
      const filtered = itemFoundedArr.filter((itemF) => itemF !== founded);
      setIsItemSelected(filtered);
      idsToSend = filtered.map((el) => el.id);
      setSelectedContact(idsToSend);
    } else {
      setIsItemSelected([...isItemSelected, { id: item._id }]);
      contactIds.push(item._id);
      setSelectedContact(contactIds);
    }
  };

  const renderTag = (item) => {
    let renderedTags = [];
    associatedTagsIds &&
      associatedTagsIds.map((association) => {
        const founded = association._id === item._id;
        if (founded) {
          renderedTags = association.tags.map((tag) => {
            return (
              <Tag
                key={tag.tagId}
                color={`#${tag.color}`}
                className="searchPage__item-tag"
                closable
                onClose={() =>
                  dispatch(
                    deleteAssociatedIdTagAction({
                      tagId: tag.tagId,
                      associatedId: association._id,
                    })
                  )
                }
              >
                {tag.name}
              </Tag>
            );
          });
          const idTagsToSave = renderedTags.map((t) => t.key);
          saveTags.push({ id: item._id, tags: idTagsToSave });
        }
      });
    return renderedTags;
  };

  const selectAllContacts = (e) => {
    const ids = [];

    if (e) {
      // select all checked
      data.length > 0 &&
        data.forEach((contact) => ids.push({ id: contact._id }));
      setIsItemSelected([...isItemSelected, ...ids]);
      const arrayIds = ids.map((id) => id.id);
      const isItemSelectedIds = isItemSelected.map((item) => item.id);
      setSelectedContact([...isItemSelectedIds, ...arrayIds]);
    } else {
      // select all unchecked
      let itemOnPrevPage = [];
      if (ids.length === pageSize) {
        setIsItemSelected([]);
        setSelectedContact([]);
      }
      if (isItemSelected.length === pageSize) {
        setIsItemSelected([]);
        setSelectedContact([]);
      } else {
        itemOnPrevPage = isItemSelected && isItemSelected.splice(0, pageSize);
        setIsItemSelected(itemOnPrevPage);
        const idItemOnPrevPage = itemOnPrevPage.map((i) => i.id);
        setSelectedContact(idItemOnPrevPage);
      }
    }
  };

  const renderStar = (item) => {
    const founded = isItemSelected.find(
      (itemSelected) => itemSelected.id === item._id
    );
    if (founded) {
      return <StarFilled style={{ fontSize: 20 }} />;
    }
    return <StarOutlined style={{ fontSize: 20 }} />;
  };

  return (
    <>
      <div title={title}>
        {tagMode && (
          <Checkbox
            checked={selectAll}
            onChange={({ target }) => {
              setSelectAll(!selectAll);
              selectAllContacts(target.checked);
            }}
          >
            {Localize("COMMON.SELECT_ALL")}
          </Checkbox>
        )}
        <List
          className="searchPage__list animation"
          itemLayout="horizontal"
          dataSource={data}
          pagination={{
            onChange: (page, pageSize) => {
              setSelectAll(false);
              setCurrentPage(page);
              setContactTagPage && setContactTagPage(page);
              fetchData(page, pageSize);
            },
            current: currentPage,
            pageSize: pSize,
            total,
          }}
          renderItem={(item) => {
            return (
              <div className="searchPage__item-more animation fadein-right slow">
                <List.Item
                  className="searchPage__item pl-3"
                  onClick={() => openContactDetail(item)}
                >
                  <List.Item.Meta
                    title={
                      <Row align="middle" justify="space-between">
                        <Col>
                          <h3 className="searchPage__item-title">
                            {tagMode && (
                              <Button
                                type="text"
                                shape="circle"
                                size="small"
                                className="mr-2"
                                onClick={() => onStarClick(item)}
                              >
                                {renderStar(item)}
                              </Button>
                            )}
                            <b>
                              {toTitleCase(item.surname)}{" "}
                              {toTitleCase(item.name)}{" "}
                            </b>{" "}
                            | {item.title}
                            <span className="mr-2" />
                            {loadingTag ? (
                              <Spin size="small" className="ml-4" />
                            ) : (
                              renderTag(item)
                            )}
                          </h3>
                        </Col>
                        <Col>
                          {item.linkedin && item.linkedin != 0 && (
                            <a
                              onClick={(e) => e.stopPropagation()}
                              href={item.linkedin}
                              target="_blank"
                            >
                              <LinkedinOutlined className="searchPage__item-icon mr-3" />
                            </a>
                          )}
                          {item.email && (
                            <a
                              onClick={(e) => e.stopPropagation()}
                              href={`mailto:${item.email}`}
                            >
                              <MailOutlined className="searchPage__item-icon mr-3" />
                            </a>
                          )}
                        </Col>
                      </Row>
                    }
                    description={
                      <>
                        <Row justify="space-between" align="bottom">
                          <Col span={12}>
                            <Row align="top">
                              <p className="mr-3 m-0">{item.company.name}</p>
                              <Tag>{item.function}</Tag>
                            </Row>
                          </Col>
                          <Col span={8} className="mr-3">
                            <Row align="end">
                              <PhoneOutlined className="mr-2" />
                              {item.tel}{" "}
                            </Row>
                          </Col>
                        </Row>
                      </>
                    }
                  />
                </List.Item>
              </div>
            );
          }}
        />
      </div>
      {detailId && (
        <ContactModal
          id={detailId}
          savedTags={saveTags}
          visible={showContactModal}
          close={() => {
            setDetailId(null);
            setShowContactModal(false);
          }}
        />
      )}
    </>
  );
};

export default ContactList;
