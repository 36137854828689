import { push } from "connected-react-router";
import callApi from "../libs/helpers/callApi";
import { authorizeApi, getUserInfoApi } from "../api/auth.api";
import { removeFromLocalStorage } from "../libs/helpers/localstorage";
import { setErrorAction } from "./app.actions";
import Localize from "../components/Localized/Localize.comp";

export const AUTHORIZE_DONE = "AUTHORIZE_DONE";
export const AUTHORIZE_STARTED = "AUTHORIZE_STARTED";

export const authorizeAction = callApi({
  apiCall: ({ code, redirectUri }) => authorizeApi({ code, redirectUri }),
  startAction: AUTHORIZE_STARTED,
  successAction: AUTHORIZE_DONE,
  processError: (error, dispatch, errorAction, getState, params) => {
    removeFromLocalStorage("user");
    removeFromLocalStorage("token");
    dispatch(updateAuth({ user: null, auth: null }));
    let { id, name, status, message, stack, date, code } = error;
    if (status == 401 || status == 403) {
      /*eslint eqeqeq: 0*/
      dispatch(push("/login"));
    } else if (status == 500) {
      dispatch(
        setErrorAction({
          id,
          name,
          status,
          message: null,
          stack,
          date,
          code: null,
        })
      );
    } else {
      message = Localize("ERROR.LOGIN");
      dispatch(
        setErrorAction({
          id,
          name,
          status,
          message,
          stack,
          date,
          code,
        })
      );
    }
    // dispatch(push("/login"));
  },
});

export const updateAuth = (data) => ({
  type: AUTHORIZE_DONE,
  data,
});

export const USERINFO_LOADING = "USERINFO_LOADING";
export const USERINFO_LOADED = "USERINFO_LOADED";

export const getUserInfoAction = callApi({
  apiCall: (p) => getUserInfoApi(p),
  secured: true,
  startAction: USERINFO_LOADING,
  successAction: USERINFO_LOADED,
  processError: (error, dispatch, errorAction, getState, params) => {
    removeFromLocalStorage("user");
    removeFromLocalStorage("token");
    dispatch(updateAuth({ user: null, auth: null }));
    dispatch(push("/login"));
  },
});

export const USER_LOGOUT = "USER_LOGOUT";

export const userLogout = () => ({
  type: USER_LOGOUT,
});
