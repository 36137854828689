import React from "react";
import Slider from "react-slick";
import PlaySquareOutlined from "@ant-design/icons/es/icons/PlaySquareOutlined";
import LeftCircleOutlined from "@ant-design/icons/es/icons/LeftCircleOutlined";
import RightCircleOutlined from "@ant-design/icons/es/icons/RightCircleOutlined";
import moment from "moment";
import { Link } from "react-router-dom";
import Localize from "../../libs/localization";
import "./CreativityCarousel.scss";
import { upperCase } from "lodash";

const CreativityCarousel = ({
  data: creaList,
  setBrandId,
  setShowBrandModal,
}) => {
  const renderCrea =
    creaList &&
    creaList.map((c) => (
      <div className="homePage__creaContainer__creaCard" key={c._id}>
        <h3 className="searchPage__item-title creativityCarousel__title">
          {c.title}
        </h3>
        <p style={{ fontSize: 12 }}>{moment(c.date).format("DD/MM/YYYY")}</p>
        {c.snapshot_url ? (
          <div
            align="middle"
            justify="center"
            style={{
              position: "relative",
              backgroundImage: `url(${c.snapshot_url})`,
              backgroundSize: "cover",
              width: "auto",
              backgroundPosition: "center center",
              height: 150,
              backgroundRepeat: "no-repeat",
              marginBottom: 10,
            }}
          >
            <a
              href={c.url}
              target="_blank"
              className="creativityCarousel__play"
            >
              <PlaySquareOutlined className="customCard__crea-playCentered" />
            </a>
          </div>
        ) : (
          <a href={c.url} target="_blank" rel="noreferrer">
            <PlaySquareOutlined className="customCard__crea-placeholder--play mb-3" />
          </a>
        )}
        <div className="mr-4">
          {/* c.company && (
            <p className="m-0 p-0">
              <b className="secondary">
                {Localize("PAGES.HOME.CARDS.COMPANY")}:
              </b>{" "}
              <Link to={`/companies/${c.company.id}`}>{c.company.name}</Link>
            </p>
          ) */}
          {c.brand && (
            <p
              className="m-0 p-0"
              onClick={() => {
                setBrandId(c.brand.id);
                setShowBrandModal(true);
              }}
            >
              <b className="secondary">{Localize("PAGES.HOME.CARDS.BRAND")}:</b>{" "}
              <a>{upperCase(c.brand.name)}</a>
            </p>
          )}
          {c.agency && (
            <p className="m-0 p-0">
              <b className="secondary">
                {Localize("PAGES.COMPANIES.DETAIL.SECTIONS.AGENCIES")}:
              </b>{" "}
              <Link to={`/companies/${c.agency.id}`}>
                {upperCase(c.agency.name)}
              </Link>
            </p>
          )}
        </div>
      </div>
    ));

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    nextArrow: <LeftCircleOutlined />,
    prevArrow: <RightCircleOutlined />,
  };

  return (
    <>
      <Slider {...settings}>{renderCrea}</Slider>
    </>
  );
};

export default CreativityCarousel;
