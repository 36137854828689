import React from "react";
import { Card, Row, Tag, Col } from "antd";

import "./Card.scss";
import { groupBy } from "lodash";
import PictureOutlined from "@ant-design/icons/es/icons/PictureOutlined";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import LeftOutlined from "@ant-design/icons/es/icons/LeftOutlined";
import RightOutlined from "@ant-design/icons/es/icons/RightOutlined";
import Localize from "../../libs/localization";
import PlaySquareOutlined from "@ant-design/icons/es/icons/PlaySquareOutlined";
import moment from "moment";

const Next = (props) => {
  const { onClick } = props;
  return <RightOutlined className="arrow" onClick={onClick} />;
};

const Prev = (props) => {
  const { onClick } = props;
  return <LeftOutlined className="arrow" onClick={onClick} />;
};

const CardCreas = (props) => {
  const { data } = props;

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: data.length >= 3 ? 3 : data.length,
    slidesToScroll: 1,
    nextArrow: <Next />,
    prevArrow: <Prev />,
  };

  return (
    <Card
      title={
        <h2>
          <b>{Localize("PAGES.COMPANIES.DETAIL.SECTIONS.CREATIVITY")}</b>
        </h2>
      }
      bordered={false}
      className="companyPage__body-card companyPage__body-card--custom"
    >
      <Row className="customCard__crea-slider">
        <Col span={24}>
          <Slider {...settings}>
            {data &&
              data.map((c) => (
                <div key={c._id}>
                  <Row
                    justify="center"
                    style={{ border: "1px solid #CCC", margin: "0 5px" }}
                  >
                    {c.snapshot_url ? (
                      <Row
                        align="middle"
                        justify="center"
                        className="customCard__crea-image"
                        style={{ backgroundImage: `url(${c.snapshot_url})` }}
                      >
                        <a href={c.url} target="_blank">
                          <PlaySquareOutlined className="customCard__crea-image--play" />
                        </a>
                      </Row>
                    ) : (
                      <Row
                        align="middle"
                        justify="center"
                        className="customCard__crea-placeholder"
                      >
                        <a href={c.url} target="_blank">
                          <PlaySquareOutlined className="customCard__crea-placeholder--play" />
                        </a>
                      </Row>
                    )}
                  </Row>
                  <div className="text-center">
                    <p className="customCard__crea-title px-3 m-0">
                      <b>{c.title}</b>
                    </p>
                    <p>{c.date && moment(c.date).format("DD/MM/YYYY")}</p>
                  </div>
                </div>
              ))}
          </Slider>
        </Col>
      </Row>
    </Card>
  );
};

export default CardCreas;
