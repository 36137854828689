import React from "react";
import { Card } from "antd";
import Localize from "../Localized/Localize.comp";
import PhoneOutlined from "@ant-design/icons/es/icons/PhoneOutlined";
import PrinterOutlined from "@ant-design/icons/es/icons/PrinterOutlined";
import GlobalOutlined from "@ant-design/icons/es/icons/GlobalOutlined";
import "./Card.scss";
import MailOutlined from "@ant-design/icons/es/icons/MailOutlined";
import EuroOutlined from "@ant-design/icons/es/icons/EuroOutlined";

const CardContacts = (props) => {
  const { data } = props;

  return (
    <Card
      title={
        <h2>
          <b>{Localize("PAGES.COMPANIES.DETAIL.SECTIONS.GENERAL.INFO")}</b>
        </h2>
      }
      bordered={false}
      className="companyPage__body-card companyPage__body-card--custom customCard"
    >
      {data.website && (
        <p>
          <GlobalOutlined />
          <a href={data.website} rel="noreferrer" target="_blank">
            {data.website}
          </a>
        </p>
      )}
      {data.e_commerce && (
        <p>
          <EuroOutlined />
          <a href={data.e_commerce} rel="noreferrer" target="_blank">
            {data.e_commerce}
          </a>
        </p>
      )}

      {data.tel && (
        <p>
          <PhoneOutlined />
          {data.tel}
        </p>
      )}
      {data.phone && (
        <p>
          <PhoneOutlined />
          {data.phone}
        </p>
      )}
      {data.fax && (
        <p>
          <PrinterOutlined />
          {data.fax}
        </p>
      )}
      {data.email && (
        <>
          <MailOutlined />
          <a href={`mailto:${data.email}`}>{data.email}</a>
        </>
      )}
    </Card>
  );
};

export default CardContacts;
