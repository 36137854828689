import callApi from "../libs/helpers/callApi";

import {
  getContactEntityApi,
  getContactListApi,
  getContactDetailApi,
  createContactDetailApi,
  editContactDetailApi,
  getContactCompanyApi,
  getContactBrandsApi,
  exportContactListApi,
} from "../api/contact.api";

export const CONTACT_LIST_LOADING = "CONTACT_LIST_LOADING";
export const CONTACT_LIST_LOADED = "CONTACT_LIST_LOADED";
export const CONTACT_LIST_LOAD_ERROR = "CONTACT_LIST_LOAD_ERROR";

export const getContactListAction = callApi({
  apiCall: (p) => getContactListApi(p),
  secured: true,
  startAction: CONTACT_LIST_LOADING,
  successAction: CONTACT_LIST_LOADED,
  errorAction: CONTACT_LIST_LOAD_ERROR,
});

export const CONTACT_DETAIL_LOADING = "CONTACT_DETAIL_LOADING";
export const CONTACT_DETAIL_LOADED = "CONTACT_DETAIL_LOADED";
export const CONTACT_DETAIL_LOAD_ERROR = "CONTACT_DETAIL_LOAD_ERROR";

export const getContactDetailAction = callApi({
  apiCall: (p) => getContactDetailApi(p),
  secured: true,
  startAction: CONTACT_DETAIL_LOADING,
  successAction: CONTACT_DETAIL_LOADED,
  errorAction: CONTACT_DETAIL_LOAD_ERROR,
});

export const CONTACT_COMPANY_LOADING = "CONTACT_COMPANY_LOADING";
export const CONTACT_COMPANY_LOADED = "CONTACT_COMPANY_LOADED";
export const CONTACT_COMPANY_LOAD_ERROR = "CONTACT_COMPANY_LOAD_ERROR";

export const getContactCompanyAction = callApi({
  apiCall: (p) => getContactCompanyApi(p),
  secured: true,
  startAction: CONTACT_COMPANY_LOADING,
  successAction: CONTACT_COMPANY_LOADED,
  errorAction: CONTACT_COMPANY_LOAD_ERROR,
});

export const CONTACT_BRAND_LOADING = "CONTACT_BRAND_LOADING";
export const CONTACT_BRAND_LOADED = "CONTACT_BRAND_LOADED";
export const CONTACT_BRAND_LOAD_ERROR = "CONTACT_BRAND_LOAD_ERROR";

export const getContactBrandsAction = callApi({
  apiCall: (p) => getContactBrandsApi(p),
  secured: true,
  startAction: CONTACT_BRAND_LOADING,
  successAction: CONTACT_BRAND_LOADED,
  errorAction: CONTACT_BRAND_LOAD_ERROR,
});

export const EXPORT_CONTACTS_LOADING = "EXPORT_CONTACTS_LOADING";
export const EXPORT_CONTACTS_LOADED = "EXPORT_CONTACTS_LOADED";
export const EXPORT_CONTACTS_LOAD_ERROR = "EXPORT_CONTACTS_LOAD_ERROR";

export const exportContactsAction = callApi({
  apiCall: (p) => exportContactListApi(p),
  secured: true,
  startAction: EXPORT_CONTACTS_LOADING,
  successAction: EXPORT_CONTACTS_LOADED,
  errorAction: EXPORT_CONTACTS_LOAD_ERROR,
  afterSuccessAction: ({ result }) => {
    let a = document.createElement("a");
    a.href = URL.createObjectURL(result);
    a.download = "contact-list.xlsx";
    a.click();
  },
});
