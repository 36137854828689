export const statusOptions = [
  {
    key: "ALL",
    value: "Tutti",
  },
  {
    key: "ONGOING",
    value: "In corso",
  },
  {
    key: "CLOSED",
    value: "Chiusi",
  },
];
