import React, { useEffect } from "react";
import { Row, Col, Modal, Spin, Tag } from "antd";
import BannerTop from "../../components/BannerTop/BannerTop";

import { useDispatch, useSelector } from "react-redux";
import { getBrandDetailAction } from "../../actions/brand.actions";

import "./brand.scss";
import CardAgencies from "../../components/Cards/Card.agency";
import CardCreas from "../../components/Cards/Card.creas";
import CardPitch from "../../components/Cards/Card.pitch";
import { turnKeyIntoLabel } from "../../libs/utils";
import { LinkOutlined } from "@ant-design/icons";
import ContactsBrandList from "../../components/List/Contacts.brand.list";
import { upperCase } from "lodash";

const BrandModal = (props) => {
  const { visible, close, id } = props;

  const data = useSelector((state) => state.brand.detail);
  const loading = useSelector((state) => state.brand.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBrandDetailAction({ id }));
  }, [id]);

  const render = (data) => {
    if (data && data.data) {
      const { brand, pitchs, creas, contacts } = data.data;

      return (
        <div className="brandPage">
          <>
            {loading ? (
              <Spin
                style={{ marginTop: 20, marginBottom: 20, marginRight: 10 }}
              />
            ) : (
              <>
                <Modal
                  wrapClassName="brandPage__modal"
                  visible={visible}
                  onCancel={() => close()}
                  width="900px"
                  title={
                    <div className="brandPage__head profile__container">
                      <BannerTop>
                        <Row
                          className="profile__container--left"
                          align="middle"
                          style={{ width: "100%" }}
                        >
                          {brand.data.logo && (
                            <div
                              style={{
                                backgroundImage: `url(${brand.data.logo})`,
                              }}
                              className="brandPage__head-logo"
                            />
                          )}

                          <div className="profile__data">
                            <h2>{upperCase(brand.data.name)}</h2>
                            {brand.data.website && (
                              <div className="profile__data-function">
                                <a href={brand.data.website} target="_blank">
                                  {brand.data.website}
                                </a>
                              </div>
                            )}
                            {brand.data?.sector?.length > 0 &&
                              brand.data.sector.map((sector, i) => (
                                <Tag className="my-2" key={sector._id}>
                                  {sector.key}
                                </Tag>
                              ))}
                            <br />
                            {brand.data?.external_links?.length > 0 &&
                              brand.data.external_links.map(
                                (external_link, i) =>
                                  external_link.link && (
                                    <Tag
                                      className="my-2 filled tag-bg"
                                      key={external_link._id}
                                    >
                                      <LinkOutlined />
                                      <a
                                        href={external_link.link}
                                        target="_Blank"
                                        style={{ paddingLeft: 5 }}
                                      >
                                        {external_link.label
                                          ? turnKeyIntoLabel(
                                              external_link.label
                                            )
                                          : i.link}
                                      </a>
                                    </Tag>
                                  )
                              )}
                          </div>
                        </Row>
                      </BannerTop>
                    </div>
                  }
                >
                  <Col className="brandPage__body">
                    <Row style={{ width: "100%" }}>
                      <Col span={24}>
                        <Row gutter={16}>
                          <Col span={24}>
                            <CardAgencies data={brand.data} />
                          </Col>
                          {creas?.data && creas?.data?.length > 0 && (
                            <Col span={24}>
                              <CardCreas data={creas?.data} />
                            </Col>
                          )}
                          {pitchs?.data && pitchs?.data?.length > 0 && (
                            <Col span={24}>
                              <CardPitch data={pitchs?.data} />
                            </Col>
                          )}
                          {contacts?.data && contacts?.data?.length > 0 && (
                            <Col span={24}>
                              <ContactsBrandList data={contacts.data} />
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Modal>
              </>
            )}
          </>
        </div>
      );
    }
  };

  return <>{render(data)}</>;
};

export default BrandModal;
