import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { Layout, Menu, Typography, BackTop, Button } from "antd";
import { UpCircleFilled, ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { goTo, setSidebarCollapsed } from "../../actions/app.actions";

import routes from "../../routes";
import { getFlatRouteList } from "../../libs/router";

import "./admin.styles.scss";
import { matchPath } from "react-router";
import { get } from "../../libs/helpers/ioc";
import Header from "../../components/Header/Header";
import appInfo from "../../libs/helpers/appInfo";

const { SubMenu, Item } = Menu;
const { Content, Sider } = Layout;
const { Title } = Typography;

const AdminLayout = ({
  // user,
  title,
  children,
  history,
  // path,
  id,
  parent_id,
  sidebarCollapsed,
  setSidebarCollapsed,
  boxShadow = true,
  noPadding = false,
  showInMenu = false,
  goTo,
}) => {
  const permissions = get("scopes");
  console.log(children, "children");

  const menu = routes.filter((r) => r.showInMenu);

  // const currentPath = getFlatRouteList(menu).find((v) =>
  //   matchPath(location.pathname, { path: v.path })
  // );

  const filteredMenu = menu.filter((m) =>
    permissions && m.scopes && m.scopes.length > 0
      ? (m.scopes || []).some((s) =>
          Object.keys(permissions).some((p) => p === s)
        )
      : m
  );

  const drawMenuItem = (i) =>
    i.children ? (
      // <Menu.ItemGroup key={i.id} title={i.title}>
      // </Menu.ItemGroup>
      <SubMenu key={i.id} icon={i.icon} title={i.title}>
        {i.children.map((j) => drawMenuItem(j))}
      </SubMenu>
    ) : (
      i.showInMenu && (
        <Item key={i.id} icon={i.icon}>
          <Link to={i.menuPath || i.path}>{i.title}</Link>
        </Item>
      )
    );

  return (
    <Layout className="admin-layout">
      <Header />
      <Layout>
        <Sider
          width={200}
          collapsible
          collapsed={sidebarCollapsed}
          onCollapse={setSidebarCollapsed}
        >
          <Menu
            className="left-menu"
            mode="inline"
            defaultSelectedKeys={[id]}
            selectedKeys={[id]}
            defaultOpenKeys={[parent_id]}
          >
            {filteredMenu.map((i) => drawMenuItem(i))}
          </Menu>
        </Sider>
        <Layout className="main-layout dot-party dot-bottom dot-right dot-purple">
          <Title
            className="main-title dot-party dot-fill dot-left dot-green-small"
            level={3}
          >
            {!showInMenu && (
              <Button type="text" onClick={() => history.goBack(-1)}>
                <ArrowLeftOutlined />
              </Button>
            )}
            {title}
          </Title>
          <Content
            className={[
              "main-cont",
              boxShadow ? "box-shadow soft-border" : "no-box-shadow",
            ].join(" ")}
            style={{
              padding: noPadding ? 0 : 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            {children}
          </Content>
          <BackTop>
            <UpCircleFilled style={{ fontSize: 40 }} />
          </BackTop>
          <div className="version">{appInfo.version}</div>
        </Layout>
      </Layout>
    </Layout>
  );
};
const mapStateToProps = ({ app, auth, router }) => ({
  sidebarCollapsed: app.sidebarCollapsed,
  user: auth.user,
  location: router.location,
});

const mapDispatchToProps = { setSidebarCollapsed, goTo };

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);
