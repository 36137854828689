import React from "react";
import { ConnectedRouter } from "connected-react-router";
import { connect } from "react-redux";
import { get } from "./libs/helpers/ioc";

import routes from "./routes";

import history from "./store/history";
import Router from "./libs/router";

import Loading from "./components/Loading/Loading";
import ErrorBoundary from "./libs/ErrorBoundary";
import { setSidebarCollapsed } from "./actions/app.actions";

import "./App.scss";
import "./App.less";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    // this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
  }

  componentDidMount() {
    if (window.screen.width < 1024) {
      setSidebarCollapsed(true);
    }
  }

  componentDidCatch(error, info) {
    this.setState({ caughtError: true });
    console.log("[ERROR Catched]", error, info);
  }

  render() {
    const { authorized, user, loading } = this.props;
    const permissions = get("scopes");
    return (
      <>
        {loading && !user && <Loading />}
        <ConnectedRouter history={history}>
          <ErrorBoundary>
            <Router
              routes={routes}
              authorized={authorized}
              permissions={permissions}
            />
          </ErrorBoundary>
        </ConnectedRouter>
      </>
    );
  }

  // _onAction(e) {
  //   console.log('user did something', e);
  // }

  _onActive(e) {
    console.log("user is active", e);
    console.log("time remaining", this.idleTimer.getRemainingTime());
    // userInfo();
    // getCuboidListAction();
  }

  _onIdle(e) {
    //   console.log('user is idle', e);
    console.log("last active", this.idleTimer.getLastActiveTime());
  }
}

const mapStateToProps = ({ auth }) => ({
  /* Sets the authorized flag on the routes object if an access_token is available */
  // authorized: false
  authorized: !!auth.user && !!auth.token,
  loading: auth.loading,
  user: auth.user,
});

export default connect(mapStateToProps, { setSidebarCollapsed })(App);
