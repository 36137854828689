import React from "react";
import { Select, Card, Row, Button, Col } from "antd";
import PlusOutlined from "@ant-design/icons/es/icons/PlusOutlined";
import SearchOutlined from "@ant-design/icons/es/icons/SearchOutlined";
import DeleteOutlined from "@ant-design/icons/es/icons/DeleteOutlined";
import Label from "../Label/Label";
import Localize from "../../libs/localization";
import { DownloadOutlined } from "@ant-design/icons";
import moment from "moment";

const { Option } = Select;

const TagOptions = ({
  tagList,
  onAddTagClick,
  onAssociateClick,
  onSearchClick,
  onExportContacts,
  associateDisabled,
  deleteDisabled,
  showExport,
  onTagChange,
  onDeleteClick,
  value,
  isForm = false,
}) => {
  return (
    <Card className="companyPage__body-card mt-3" style={{ borderRadius: 10 }}>
      <Row align="bottom" justify="start">
        <Col span={8} className="mr-3">
          <Label value={Localize("TAG.SELECT")} />
          <Select
            value={value}
            allowClear
            style={{ width: "100%" }}
            onChange={(val) => onTagChange(val)}
            onSelect={(val) => onTagChange(val)}
          >
            {tagList &&
              tagList.map((tag) => {
                return (
                  <Option value={tag.name} label={tag.name} key={tag._id}>
                    <Row align="middle">
                      <Col span={12}>
                        <Row align="middle">
                          <div
                            className="mr-2"
                            style={{
                              width: 15,
                              height: 15,
                              borderRadius: 50,
                              backgroundColor: `#${tag.color}`,
                            }}
                          />
                          {tag.name}
                        </Row>
                      </Col>
                      <Col span={12} align="center">
                        {tag.created_at &&
                          moment(tag.created_at).format("DD-MM-YYYY")}
                      </Col>
                    </Row>
                  </Option>
                );
              })}
          </Select>
        </Col>
        <>
          {isForm ? (
            <>
              <Button
                className="mr-3"
                disabled={deleteDisabled}
                onClick={onDeleteClick}
              >
                <DeleteOutlined />
                {Localize("TAG.DELETE_TAG")}
              </Button>
              {showExport && (
                <Button className="mr-3" onClick={onExportContacts}>
                  <DownloadOutlined />
                  {Localize("TAG.EXPORT_CONTACTS")}
                </Button>
              )}
              {!isForm && (
                <Button type="primary" onClick={onSearchClick}>
                  <SearchOutlined />
                  {Localize("COMMON.SEARCH")}
                </Button>
              )}
            </>
          ) : (
            <Col className="mt-2" span={8}>
              <Button
                icon={<PlusOutlined />}
                onClick={onAddTagClick}
                className="mr-3"
              >
                {Localize("TAG.CREATE")}
              </Button>
              <Button
                type="primary"
                onClick={onAssociateClick}
                disabled={associateDisabled}
              >
                {Localize("TAG.ASSOCIATE")}
              </Button>
            </Col>
          )}
        </>
      </Row>
    </Card>
  );
};

export default TagOptions;
