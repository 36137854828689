import React, { useEffect, useState } from "react";
import { Card, Row, Col, Statistic } from "antd";
import { forEach, capitalize, isEmpty } from "lodash";
import { ResponsivePie } from "@nivo/pie";
import Tabs from "../../components/Tabs/Tabs";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyNielsenAction } from "../../actions/company.actions";
import Investments from "../../components/Investments/Investments";

const CompanyInvestments = (props) => {
  const { companyId } = props;

  const nielsen = useSelector((state) => state.company.nielsen);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(nielsen)) {
      dispatch(getCompanyNielsenAction({ id: companyId }));
    }
  }, [nielsen]);

  return (
    <Card
      title={
        <h2>
          <b>Investimenti</b>
        </h2>
      }
      bordered={false}
      className="companyPage__body-card companyPage__body-card--custom customCard"
    >
      {nielsen && nielsen.data && nielsen.data.history && (
        <Investments data={nielsen.data.history} />
      )}
    </Card>
  );
};

export default CompanyInvestments;
