import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "antd";
import Table from "../../components/Table/Table";
import FinancialGraph from "../../components/FinancialGraph/FinancialGraph";
import { orderBy } from "lodash";
import Localize from "../../libs/localization";

const renderData = (data) => {
  if (!data) return [];
  const tableData = [];
  Object.keys(data).map((year, i) => {
    if (
      data[year].revenue ||
      data[year].operatingResult ||
      data[year].employees
    ) {
      const tempData = {};
      tempData.year = year;
      tempData.key = i;
      tempData.revenue = data[year].revenue ? data[year].revenue : 0;
      tempData.operatingResult = data[year].operatingResult
        ? data[year].operatingResult
        : 0;
      tempData.employees = data[year].employees ? data[year].employees : 0;
      tableData.push(tempData);
    }
  });
  return orderBy(tableData, "year", "desc");
};

const CompanyFinancial = (props) => {
  const { data } = props;

  const columns = [
    {
      title: (
        <div style={{ fontWeight: "bold" }}>{Localize("COMMON.YEAR")}</div>
      ),
      dataIndex: "year",
    },
    {
      title: (
        <div style={{ fontWeight: "bold" }}>{Localize("COMMON.REVENUE")}</div>
      ),
      dataIndex: "revenue",
    },
    {
      title: (
        <div style={{ fontWeight: "bold" }}>{Localize("COMMON.INCOME")}</div>
      ),
      dataIndex: "operatingResult",
    },
    {
      title: (
        <div style={{ fontWeight: "bold" }}>{Localize("COMMON.EMPLOYEES")}</div>
      ),
      dataIndex: "employees",
    },
  ];

  return (
    <Card
      title={
        <h2>
          <b>{Localize("PAGES.COMPANIES.DETAIL.SECTIONS.FINANCIALS")}</b>
        </h2>
      }
      bordered={false}
      className="companyPage__body-card companyPage__body-card--custom customCard"
    >
      {data && (
        <Table
          data={renderData(data)}
          columns={columns}
          size="small"
          bordered={false}
        />
      )}
      {data && (
        <div style={{ marginTop: 50 }}>
          <FinancialGraph data={renderData(data)} />
        </div>
      )}
    </Card>
  );
};

export default CompanyFinancial;
