import { reducerFromMap } from "../libs/helpers/reducers";
import {
  SEARCH_BRAND_LOAD_ERROR,
  SEARCH_BRAND_LOADED,
  SEARCH_BRAND_LOADING,
  SEARCH_COMPANY_LOAD_ERROR,
  SEARCH_COMPANY_LOADED,
  SEARCH_COMPANY_LOADING,
  SEARCH_CONTACT_LOAD_ERROR,
  SEARCH_CONTACT_LOADED,
  SEARCH_CONTACT_LOADING,
  SEARCH_LOAD_ERROR,
  SEARCH_LOADED,
  SEARCH_LOADING,
  SEARCH_PITCH_LOAD_ERROR,
  SEARCH_PITCH_LOADED,
  SEARCH_PITCH_LOADING,
  SEARCH_RESET,
  SEARCH_WORK_RELATION_LOADING,
  SEARCH_WORK_RELATION_LOADED,
  SEARCH_WORK_RELATION_LOAD_ERROR,
  SEARCH_LIST_LOADING,
  SEARCH_LIST_LOADED,
  SEARCH_LIST_LOAD_ERROR,
  CREATE_SEARCH_LOADING,
  CREATE_SEARCH_LOADED,
  CREATE_SEARCH_LOAD_ERROR,
  DELETE_SEARCH_LOADING,
  DELETE_SEARCH_LOADED,
  DELETE_SEARCH_LOAD_ERROR,
} from "../actions/search.action";

const reducer = {
  /*
   * AUTOCOMPLETE/FAST SEARCH
   */
  [SEARCH_LOADING]: (state) => ({
    ...state,
    results: { data: {}, loading: true },
  }),
  [SEARCH_LOADED]: (state, { data }) => {
    return {
      ...state,
      results: {
        loading: false,
        data: data.data,
      },
    };
  },
  [SEARCH_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    results: { loading: false },
    error: data,
  }),
  /*
   * CONTACT SEARCH
   */
  [SEARCH_CONTACT_LOADING]: (state) => ({
    ...state,
    results: { data: { ...state.results.data }, loading: true },
  }),
  [SEARCH_CONTACT_LOADED]: (state, { data }) => {
    return {
      ...state,
      results: {
        loading: false,
        data: {
          ...state.results.data,
          contacts: data,
        },
      },
    };
  },
  [SEARCH_CONTACT_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    results: { loading: false },
    error: data,
  }),
  /*
   * COMPANY SEARCH
   */
  [SEARCH_COMPANY_LOADING]: (state) => ({
    ...state,
    results: { data: { ...state.results.data }, loading: true },
  }),
  [SEARCH_COMPANY_LOADED]: (state, { data }) => {
    return {
      ...state,
      results: {
        loading: false,
        data: {
          ...state.results.data,
          companies: data,
        },
      },
    };
  },
  [SEARCH_COMPANY_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    results: {
      data: { ...state.results.data },
      loading: false,
    },
    error: data,
  }),
  /*
   * BRAND SEARCH
   */
  [SEARCH_BRAND_LOADING]: (state) => ({
    ...state,
    results: { data: { ...state.results.data }, loading: true },
  }),
  [SEARCH_BRAND_LOADED]: (state, { data }) => {
    return {
      ...state,
      results: {
        loading: false,
        data: {
          ...state.results.data,
          brands: data,
        },
      },
    };
  },
  [SEARCH_BRAND_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    results: {
      data: { ...state.results.data },
      loading: false,
    },
    error: data,
  }),
  /*
   * PITCH SEARCH
   */
  [SEARCH_PITCH_LOADING]: (state) => ({
    ...state,
    results: { data: { ...state.results.data }, loading: true },
  }),
  [SEARCH_PITCH_LOADED]: (state, { data }) => {
    return {
      ...state,
      results: {
        loading: false,
        data: {
          ...state.results.data,
          pitches: data,
        },
      },
    };
  },
  [SEARCH_PITCH_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    results: {
      data: { ...state.results.data },
      loading: false,
    },
    error: data,
  }),
  /*
   * WORK RELATION SEARCH
   */
  [SEARCH_WORK_RELATION_LOADING]: (state) => ({
    ...state,
    results: { data: { ...state.results.data }, loading: true },
  }),
  [SEARCH_WORK_RELATION_LOADED]: (state, { data }) => ({
    ...state,
    results: {
      loading: false,
      data: {
        ...state.results.data,
        workRelations: data,
      },
    },
  }),
  [SEARCH_WORK_RELATION_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    results: {
      data: { ...state.results.data },
      loading: false,
    },
    error: data,
  }),
  [SEARCH_RESET]: (state) => ({
    ...state,
    results: {
      data: {
        companies: {},
        contacts: {},
        brands: {},
        pitches: {},
        workRelations: {},
      },
      loading: false,
    },
  }),
  /*
   * SEARCH LIST
   */
  [SEARCH_LIST_LOADING]: (state) => ({
    ...state,
    searchList: { loading: true },
  }),
  [SEARCH_LIST_LOADED]: (state, { data }) => ({
    ...state,
    searchList: {
      loading: false,
      data: data.data,
    },
  }),
  [SEARCH_LIST_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    searchList: {
      loading: false,
      error: data,
    },
  }),
  /*
   * SEARCH CREATION
   */
  [CREATE_SEARCH_LOADING]: (state) => ({
    ...state,
    searchCreated: { loading: true },
  }),
  [CREATE_SEARCH_LOADED]: (state, { data }) => ({
    ...state,
    searchCreated: { loading: false, data: data.data },
  }),
  [CREATE_SEARCH_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    searchCreated: { loading: false, error: data },
  }),
  /*
   * DELETE SEARCH
   */
  [DELETE_SEARCH_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [DELETE_SEARCH_LOADED]: (state) => ({
    ...state,
    loading: false,
  }),
  [DELETE_SEARCH_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
};

export default reducerFromMap(reducer, {
  results: {
    data: {
      companies: {},
      contacts: {},
      brands: {},
      workRelations: {},
      pitches: {},
    },
  },
  searchList: {
    data: [],
  },
  searchCreated: {
    data: {},
  },
});
