import React, { useState } from "react";
import { Col, List, Row, Tag } from "antd";
import { Link } from "react-router-dom";
import "../../pages/home/home.styles.scss";
import Localize from "../../libs/localization";
import { get } from "../../libs/helpers/ioc";
import { toTitleCase } from "../../libs/utils";
import { upperCase } from "lodash";
import ContactModal from "../../pages/contact/contact.modal";

const ContactsHomeList = ({ data, cardHeight }) => {
  const [showContactModal, setShowContactModal] = useState(false);
  const [detailId, setDetailId] = useState(null);

  const permissions = get("scopes");

  const openContactDetail = (item) => {
    setDetailId(item._id);
    setShowContactModal(true);
  };

  return (
    <>
      <List
        className="homePage__pitchContainer__pitchCard"
        style={{ height: cardHeight }}
        dataSource={data}
        renderItem={(item) => {
          return (
            <List.Item
              key={item._id}
              onClick={() =>
                Object.keys(permissions)?.includes("contact") &&
                openContactDetail(item)
              }
            >
              <List.Item.Meta
                title={
                  <Row align="middle" className="mx-3">
                    <h3 className="mb-0 pb-0">
                      {toTitleCase(item.name)} {toTitleCase(item.surname)}
                    </h3>
                  </Row>
                }
                description={
                  <>
                    <Row>
                      <p className="mx-3 p-0" style={{ fontSize: 12 }}>
                        <b>
                          {Localize("COMMON.FUNCTION")}: {item.function}
                        </b>
                      </p>
                    </Row>
                    {item.company && (
                      <p className="mx-3 p-0 mb-0">
                        <b className="secondary">
                          {Localize("PAGES.HOME.CARDS.COMPANY")}:
                        </b>{" "}
                        <Link
                          style={{
                            pointerEvents: Object.keys(permissions)?.includes(
                              "company"
                            )
                              ? ""
                              : "none",
                          }}
                          onClick={(e) => e.stopPropagation()}
                          to={`/companies/${item.company.id}`}
                        >
                          {upperCase(item.company.name)}
                        </Link>
                      </p>
                    )}
                  </>
                }
              />
            </List.Item>
          );
        }}
      />
      {detailId && (
        <ContactModal
          id={detailId}
          visible={showContactModal}
          close={() => {
            setDetailId(null);
            setShowContactModal(false);
          }}
        />
      )}
    </>
  );
};

export default ContactsHomeList;
