import React, { useEffect, useState } from "react";
import { Row, Col, Card, Tag, Modal, Button } from "antd";
import "./company.scss";
import { useDispatch, useSelector } from "react-redux";
import TeamOutlined from "@ant-design/icons/es/icons/TeamOutlined";
import FlagOutlined from "@ant-design/icons/es/icons/FlagOutlined";
import EuroOutlined from "@ant-design/icons/es/icons/EuroOutlined";
import EnvironmentOutlined from "@ant-design/icons/es/icons/EnvironmentOutlined";
import ShopOutlined from "@ant-design/icons/es/icons/ShopOutlined";
import ScheduleOutlined from "@ant-design/icons/es/icons/ScheduleOutlined";
import TableOutlined from "@ant-design/icons/es/icons/TableOutlined";
import ClockCircleOutlined from "@ant-design/icons/es/icons/ClockCircleOutlined";
import Localize from "../../components/Localized/Localize.comp";
import CardAddress from "../../components/Cards/Card.address";
import Tabs from "../../components/Tabs/Tabs";
import CardContacts from "../../components/Cards/Card.contacts";
import CardWiki from "../../components/Cards/CardWiki";
import CardWip from "../../components/Cards/Card.wip";
import BannerTop from "../../components/BannerTop/BannerTop";
import CompanyInvestments from "./company.investments";
import { getCompanyDetailAction } from "../../actions/company.actions";
import Label from "../../components/Label/Label";
import CardField from "../../components/Cards/CardField";
import { label } from "../../libs/utils";
import { OtherSocial, Social } from "../../components/Cards/Social";
import CompanyContacts from "./company.contacts";
import CompanyBrands from "./company.brands";
import CompanyPitch from "./company.pitch";
import CompanyCrea from "./company.crea";
import _Localize from "../../libs/localization";
import CompanySocialMedia from "./company.social_media";
import CompanyFinancial from "./company.financial";
import { getTreeSectorAction } from "../../actions/registry/registry.sector.actions";
import { groupBy } from "lodash";

const CompanyDetail = (props) => {
  const { match } = props;

  const [showDetailTagModal, setShowDetailTagModal] = useState(false);

  const data = useSelector((state) => state.company.detail.data);
  const schema = useSelector((state) => state.company.detail.schema);
  const sector = useSelector((state) => state.sector.data);
  const dispatch = useDispatch();

  useEffect(() => {
    if (match.params.id) {
      dispatch(getCompanyDetailAction({ id: match.params.id }));
      dispatch(getTreeSectorAction());
    }
  }, [match.params.id]);

  /* useEffect(() => {
    if (data) {
      fetch(
        `https://it.wikipedia.org/w/api.php?format=json&action=query&prop=extracts&exintro&explaintext&redirects=1&titles=${data.name}`,
        { mode: "no-cors" }
      )
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    }
  }, [data]); */

  function sectorModal(title) {
    data.sector.map((companySector, companySectorIndex) => {
      sector.map((sectorTree, sectorIndex) => {
        if (sectorTree.children) {
          sectorTree.children.map((childrenSector) => {
            if (childrenSector.children) {
              childrenSector.children.map((c) => {
                if (c._id === companySector._id)
                  data.sector[companySectorIndex].parent =
                    sector[sectorIndex].key;
              });
            }
            if (childrenSector._id === companySector._id)
              data.sector[companySectorIndex].parent = sector[sectorIndex].key;
          });
        }
      });
    });
    const groupedSector = groupBy(data.sector, "parent");
    Modal.info({
      title,
      content: (
        <>
          {Object.keys(groupedSector).map((parent) => {
            return (
              <>
                {parent !== "undefined" && <p>{parent}</p>}
                {parent !== "undefined" ? (
                  <ul>
                    {groupedSector[parent].map((s) => (
                      <li className="m-1" key={s._id}>
                        {s.key}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <>
                    {groupedSector[parent].map((s) => (
                      <li className="m-1" key={s._id}>
                        {s.key}
                      </li>
                    ))}
                  </>
                )}
              </>
            );
          })}
        </>
      ),
      width: "800px",
      onOk: () => setShowDetailTagModal(false),
    });
  }

  function info(title, content) {
    Modal.info({
      title,
      content,
      width: "800px",
      onOk: () => setShowDetailTagModal(false),
    });
  }

  const tabBarExtraContent = (
    <a href={`mailto:lefac@test.com?subject=Company ${data?.name}`}>
      <Button type="text" className="help-btn">
        {Localize("COMMON.HELP")}
      </Button>
    </a>
  );

  return (
    <div className="companyPage">
      {data && (
        <>
          <div className="companyPage__head profile__container">
            <BannerTop>
              <Row className="profile__container--left" align="middle">
                {data.logo && (
                  <div className="profile__avatar">
                    <img
                      src={data.logo}
                      className="companyPage__head-logo"
                      alt={`logo-${data.name}`}
                    />
                  </div>
                )}
                <div className="profile__data">
                  <div>{data.main_category}</div>
                  <h2>{data.name}</h2>
                  {data.parent_firm_name && (
                    <div>
                      {Localize("COMMON.GROUP", [data.parent_firm_name])}
                    </div>
                  )}
                </div>
              </Row>
              <Row className="profile__container--right" align="middle">
                <Social external={data.external_links} />
              </Row>
            </BannerTop>
          </div>
          <Col span={24} className="companyPage__body">
            <Tabs
              options={{
                onTabClick: () => {
                  window.scrollTo({
                    behavior: "smooth",
                    left: 0,
                    top: 0,
                  });
                },
                animated: true,
                tabBarExtraContent,
              }}
            >
              <div
                title={Localize(
                  "PAGES.COMPANIES.DETAIL.SECTIONS.GENERAL.TITLE"
                )}
              >
                <Row gutter={16} className="animation fadein-up slow">
                  {/*left col*/}
                  <Col span={16}>
                    <Card
                      title={
                        <h2>
                          <b>
                            {Localize(
                              "PAGES.COMPANIES.DETAIL.SECTIONS.GENERAL.OVERVIEW"
                            )}
                          </b>
                        </h2>
                      }
                      bordered={false}
                      className="companyPage__body-card companyPage__body-card--custom"
                    >
                      <Row gutter={16}>
                        <CardField
                          data={data}
                          schema={schema}
                          val="creation_year"
                          icon={
                            <ClockCircleOutlined
                              style={{ fontSize: 20 }}
                              className="mr-2"
                            />
                          }
                        />
                        <CardField
                          data={data}
                          schema={schema}
                          val="nation_orig"
                          icon={
                            <FlagOutlined
                              style={{ fontSize: 20 }}
                              className="mr-2"
                            />
                          }
                        />
                        <CardField
                          data={data}
                          schema={schema}
                          val="store_number"
                          icon={
                            <ShopOutlined
                              style={{ fontSize: 20 }}
                              className="mr-2"
                            />
                          }
                        />
                        <CardField
                          data={data}
                          schema={schema}
                          val="medium_planning"
                          icon={
                            <ScheduleOutlined
                              style={{ fontSize: 20 }}
                              className="mr-2"
                            />
                          }
                        />
                        <CardField
                          data={data}
                          schema={schema}
                          val="income_range"
                          icon={
                            <EuroOutlined
                              style={{ fontSize: 20 }}
                              className="mr-2"
                            />
                          }
                        />
                        <CardField
                          data={data}
                          schema={schema}
                          val="employer_range"
                          icon={
                            <TeamOutlined
                              style={{ fontSize: 20 }}
                              className="mr-2"
                            />
                          }
                        />
                        {data.sector && data.sector.length > 0 && (
                          <Col span={24} className="mb-3">
                            <Row>
                              <TableOutlined
                                style={{ fontSize: 20 }}
                                className="mr-2"
                              />
                              <Label value={label(schema, "sector")} />
                            </Row>
                            {data.sector.slice(0, 5).map((s) => (
                              <Tag className="m-1" key={s.key}>
                                {s.key}
                              </Tag>
                            ))}
                            {data.sector.length > 5 && (
                              <Tag
                                className="pointer"
                                onClick={() =>
                                  sectorModal(
                                    <Label
                                      value={_Localize("COMMON.SECTORS")}
                                    />
                                  )
                                }
                              >
                                + {data.sector.length - 5}{" "}
                                {Localize("COMMON.SECTORS")}
                              </Tag>
                            )}
                          </Col>
                        )}
                        {data.international_invest &&
                          data.international_invest.length > 0 && (
                            <Col span={24} className="mb-3">
                              <Row>
                                <EnvironmentOutlined
                                  style={{ fontSize: 20 }}
                                  className="mr-2"
                                />
                                <Label
                                  value={label(schema, "international_invest")}
                                />
                              </Row>
                              {data.international_invest
                                .slice(0, 5)
                                .map((s) => (
                                  <Tag className="m-1" key={s}>
                                    {s}
                                  </Tag>
                                ))}
                              {data.international_invest.length > 5 && (
                                <Tag
                                  className="pointer"
                                  onClick={() =>
                                    info(
                                      <Label
                                        value={label(
                                          schema,
                                          "international_invest"
                                        )}
                                      />,
                                      <ul
                                        style={{
                                          columnCount: parseInt(
                                            data.international_invest.length /
                                              15
                                          ),
                                        }}
                                      >
                                        {data.international_invest.map((s) => (
                                          <li className="m-1" key={s._id}>
                                            {s}
                                          </li>
                                        ))}
                                      </ul>
                                    )
                                  }
                                >
                                  + {data.international_invest.length - 5}{" "}
                                  {Localize("COMMON.COUNTRIES")}
                                </Tag>
                              )}
                            </Col>
                          )}
                      </Row>
                    </Card>
                    <Card
                      title={
                        <h2>
                          <b>Links</b>
                        </h2>
                      }
                      bordered={false}
                      className="companyPage__body-card companyPage__body-card--custom"
                    >
                      <OtherSocial external={data.external_links} />
                    </Card>
                    <CardWiki data={data} />
                  </Col>
                  <Col span={8} className="animation fadein-up slow ">
                    <CardContacts data={data} />
                    {data.address && <CardAddress data={data} />}
                  </Col>
                </Row>
              </div>
              <div title={Localize("PAGES.COMPANIES.DETAIL.SECTIONS.CONTACTS")}>
                <Row gutter={24} className="animation ">
                  <Col span={24} style={{ marginBottom: "30px" }} className="">
                    <CompanyContacts companyId={match.params.id} />
                  </Col>
                </Row>
              </div>
              <div
                title={Localize("PAGES.COMPANIES.DETAIL.SECTIONS.FINANCIALS")}
              >
                <Row gutter={24} className="animation">
                  <Col span={24}>
                    <CompanyFinancial
                      data={data.financial_history}
                    ></CompanyFinancial>
                  </Col>
                </Row>
              </div>
              <div title="Social Network">
                <Row gutter={24} className="animation">
                  <Col span={24}>
                    <CompanySocialMedia companyId={match.params.id} />
                  </Col>
                </Row>
              </div>
              {data && (
                <div
                  title={Localize(
                    "PAGES.COMPANIES.DETAIL.SECTIONS.INVESTMENTS"
                  )}
                >
                  <Row gutter={24} className="animation">
                    <Col
                      span={24}
                      style={{ marginBottom: "30px" }}
                      className="animation"
                    >
                      <CompanyInvestments companyId={match.params.id} />
                    </Col>
                  </Row>
                </div>
              )}
              {data && (
                <div title="Brand">
                  <Row gutter={24}>
                    <Col span={24} style={{ marginBottom: "30px" }}>
                      <CompanyBrands
                        companyId={match.params.id}
                        disableDetail={
                          data.type !== "ADV" &&
                          data.type !== "ME" &&
                          data.type !== "AG" &&
                          data.type !== "CP"
                        }
                      />
                    </Col>
                  </Row>
                </div>
              )}
              {data &&
                (data.type === "ADV" ||
                  data.type === "ME" ||
                  data.type === "AG" ||
                  data.type === "CP") && (
                  <div title="Pitch">
                    <Row gutter={24}>
                      <CompanyPitch
                        companyId={match.params.id}
                        companyName={data.name}
                      />
                    </Row>
                  </div>
                )}
              {data &&
                (data.type === "ADV" ||
                  data.type === "ME" ||
                  data.type === "AG" ||
                  data.type === "CP") && (
                  <div
                    title={Localize(
                      "PAGES.COMPANIES.DETAIL.SECTIONS.CREATIVITY"
                    )}
                  >
                    <Row gutter={24}>
                      <CompanyCrea companyId={match.params.id} />
                    </Row>
                  </div>
                )}
            </Tabs>
          </Col>
        </>
      )}
    </div>
  );
};

export default CompanyDetail;
