import React, { useEffect, useState } from "react";
import { AutoComplete, Select } from "antd";
import { remove, upperCase } from "lodash";
import { headersBuilder } from "../../libs/helpers/callApi";
import { getCompanyListApi } from "../../api/company.api";

const { Option } = AutoComplete;

const CompanyAutocomplete = ({
  item,
  onChange,
  disabled,
  itemSchema,
  token,
  value,
  singleValue = false,
}) => {
  const [companyList, setCompanyList] = useState([]);
  const [currValue, setCurrValue] = useState(value || []);

  useEffect(() => setCurrValue(value), [value]);

  return (
    <Select
      mode={singleValue ? "" : "multiple"}
      showSearch
      value={currValue}
      filterOption={false}
      style={{ width: "100%" }}
      disabled={disabled}
      onClear={() => {
        setCurrValue([]);
        if (typeof onChange === "function" && !disabled) {
          onChange([], null);
        }
      }}
      onSelect={(value, opt) => {
        setCurrValue([value]);
        if (typeof onChange === "function" && !disabled) {
          if (currValue) {
            onChange([...currValue, value], opt);
          } else {
            onChange([value], opt);
          }
        }
      }}
      allowClear
      onChange={(value, opt) => {
        if (value && value.length) {
          setCurrValue([value]);
          if (typeof onChange === "function" && !disabled) {
            if (currValue) {
              onChange([...currValue, value], opt);
            } else {
              onChange([value], opt);
            }
          }
        } else onChange([], null);
      }}
      onDeselect={(value) => {
        remove(currValue, (n) => n === value);
        setCurrValue(currValue);
        if (typeof onChange === "function" && !disabled) {
          onChange(currValue, null);
        }
      }}
      onSearch={async (value) => {
        if (value && value.length > 2) {
          const res = await getCompanyListApi({
            headers: {
              ...headersBuilder(),
              Authorization: `Bearer ${token}`,
            },
            params: [`name=${value}`],
          });
          setCompanyList(
            (res.data || []).map((r) => ({
              label: r.name,
              value: r.name,
              obj: { id: r._id, name: r.name },
            }))
          );
        }
      }}
      placeholder={disabled ? "—" : ""}
    >
      {companyList.map((el, key) => (
        <Option key={key} value={el.value} obj={el.obj}>
          {upperCase(el.label)}
        </Option>
      ))}
    </Select>
  );
};

export default CompanyAutocomplete;
