export const companyTypeOptions = [
  {
    key: "ADV",
    value: "Marchio",
  },
  {
    key: "AG",
    value: "Agenzia",
  },
  {
    key: "ME",
    value: "Media",
  },
  {
    key: "CP",
    value: "Concessionaria di Pubblicità",
  },
];
