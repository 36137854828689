import callApi from "../libs/helpers/callApi";
import {
  associateTagApi,
  associationsTagIdCompanyApi,
  associationsTagIdContactApi,
  createTagApi,
  deleteAssociatedIdTagApi,
  deleteTagApi,
  exportTagContactsApi,
  getTagApi,
  getTagCompanyDetailApi,
  getTagContactDetailApi,
} from "../api/tag.api";

export const TAG_LOADING = "TAG_LOADING";
export const TAG_LOADED = "TAG_LOADED";
export const TAG_LOAD_ERROR = "TAG_LOAD_ERROR";

export const getTagAction = callApi({
  apiCall: (p) => getTagApi(p),
  secured: true,
  startAction: TAG_LOADING,
  successAction: TAG_LOADED,
  errorAction: TAG_LOAD_ERROR,
});

export const TAG_COMPANY_DETAIL_LOADING = "TAG_COMPANY_DETAIL_LOADING";
export const TAG_COMPANY_DETAIL_LOADED = "TAG_COMPANY_DETAIL_LOADED";
export const TAG_COMPANY_DETAIL_LOAD_ERROR = "TAG_COMPANY_DETAIL_LOAD_ERROR";

export const getTagCompanyDetailAction = callApi({
  apiCall: (p) => getTagCompanyDetailApi(p),
  secured: true,
  startAction: TAG_COMPANY_DETAIL_LOADING,
  successAction: TAG_COMPANY_DETAIL_LOADED,
  errorAction: TAG_COMPANY_DETAIL_LOAD_ERROR,
});

export const TAG_CONTACT_DETAIL_LOADING = "TAG_CONTACT_DETAIL_LOADING";
export const TAG_CONTACT_DETAIL_LOADED = "TAG_CONTACT_DETAIL_LOADED";
export const TAG_CONTACT_DETAIL_LOAD_ERROR = "TAG_CONTACT_DETAIL_LOAD_ERROR";

export const getTagContactDetailAction = callApi({
  apiCall: (p) => getTagContactDetailApi(p),
  secured: true,
  startAction: TAG_CONTACT_DETAIL_LOADING,
  successAction: TAG_CONTACT_DETAIL_LOADED,
  errorAction: TAG_CONTACT_DETAIL_LOAD_ERROR,
});

export const CREATE_TAG_LOADING = "CREATE_TAG_LOADING";
export const CREATE_TAG_LOADED = "CREATE_TAG_LOADED";
export const CREATE_TAG_LOAD_ERROR = "CREATE_TAG_LOAD_ERROR";

export const createTagAction = callApi({
  apiCall: (p) => createTagApi(p),
  secured: true,
  startAction: CREATE_TAG_LOADING,
  successAction: CREATE_TAG_LOADED,
  errorAction: CREATE_TAG_LOAD_ERROR,
  afterSuccessAction: ({ dispatch }) => {
    dispatch(getTagAction());
  },
});

export const ASSOCIATE_TAG_LOADING = "ASSOCIATE_TAG_LOADING";
export const ASSOCIATE_TAG_LOADED = "ASSOCIATE_TAG_LOADED";
export const ASSOCIATE_TAG_LOAD_ERROR = "ASSOCIATE_TAG_LOAD_ERROR";

export const associateTagAction = callApi({
  apiCall: (p) => associateTagApi(p),
  secured: true,
  startAction: ASSOCIATE_TAG_LOADING,
  successAction: ASSOCIATE_TAG_LOADED,
  errorAction: ASSOCIATE_TAG_LOAD_ERROR,
});

export const ASSOCIATION_TAG_ID_COMPANY_LOADING =
  "ASSOCIATION_TAG_ID_COMPANY_LOADING";
export const ASSOCIATION_TAG_ID_COMPANY_LOADED =
  "ASSOCIATION_TAG_ID_COMPANY_LOADED";
export const ASSOCIATION_TAG_ID_COMPANY_LOAD_ERROR =
  "ASSOCIATION_TAG_ID_COMPANY_LOAD_ERROR";

export const associationsTagIdCompanyAction = callApi({
  apiCall: (p) => associationsTagIdCompanyApi(p),
  secured: true,
  startAction: ASSOCIATION_TAG_ID_COMPANY_LOADING,
  successAction: ASSOCIATION_TAG_ID_COMPANY_LOADED,
  errorAction: ASSOCIATION_TAG_ID_COMPANY_LOAD_ERROR,
});

export const ASSOCIATION_TAG_ID_CONTACT_LOADING =
  "ASSOCIATION_TAG_ID_CONTACT_LOADING";
export const ASSOCIATION_TAG_ID_CONTACT_LOADED =
  "ASSOCIATION_TAG_ID_CONTACT_LOADED";
export const ASSOCIATION_TAG_ID_CONTACT_LOAD_ERROR =
  "ASSOCIATION_TAG_ID_CONTACT_LOAD_ERROR";

export const associationsTagIdContactAction = callApi({
  apiCall: (p) => associationsTagIdContactApi(p),
  secured: true,
  startAction: ASSOCIATION_TAG_ID_CONTACT_LOADING,
  successAction: ASSOCIATION_TAG_ID_CONTACT_LOADED,
  errorAction: ASSOCIATION_TAG_ID_CONTACT_LOAD_ERROR,
});

export const DELETE_TAG_LOADING = "DELETE_TAG_LOADING";
export const DELETE_TAG_LOADED = "DELETE_TAG_LOADED";
export const DELETE_TAG_LOAD_ERROR = "DELETE_TAG_LOAD_ERROR";

export const deleteTagAction = callApi({
  apiCall: (p) => deleteTagApi(p),
  secured: true,
  startAction: DELETE_TAG_LOADING,
  successAction: DELETE_TAG_LOADED,
  errorAction: DELETE_TAG_LOAD_ERROR,
  afterSuccessAction: ({ dispatch }) => {
    dispatch(getTagAction());
  },
});

export const DELETE_TAG_ASSOCIATED_ID_LOADING =
  "DELETE_TAG_ASSOCIATED_ID_LOADING";
export const DELETE_TAG_ASSOCIATED_ID_LOADED =
  "DELETE_TAG_ASSOCIATED_ID_LOADED";
export const DELETE_TAG_ASSOCIATED_ID_LOAD_ERROR =
  "DELETE_TAG_ASSOCIATED_ID_LOAD_ERROR";

export const deleteAssociatedIdTagAction = callApi({
  apiCall: (p) => deleteAssociatedIdTagApi(p),
  secured: true,
  startAction: DELETE_TAG_ASSOCIATED_ID_LOADING,
  successAction: DELETE_TAG_ASSOCIATED_ID_LOADED,
  errorAction: DELETE_TAG_ASSOCIATED_ID_LOAD_ERROR,
});

export const EXPORT_TAG_CONTACTS_LOADING = "EXPORT_TAG_CONTACTS_LOADING";
export const EXPORT_TAG_CONTACTS_LOADED = "EXPORT_TAG_CONTACTS_LOADED";
export const EXPORT_TAG_CONTACTS_LOAD_ERROR = "EXPORT_TAG_CONTACTS_LOAD_ERROR";

export const exportTagContactsAction = callApi({
  apiCall: (p) => exportTagContactsApi(p),
  secured: true,
  startAction: EXPORT_TAG_CONTACTS_LOADING,
  successAction: EXPORT_TAG_CONTACTS_LOADED,
  errorAction: EXPORT_TAG_CONTACTS_LOAD_ERROR,
  afterSuccessAction: ({ result }) => {
    let a = document.createElement("a");
    a.href = URL.createObjectURL(result);
    a.download = "tag-contact-list.xlsx";
    a.click();
  },
});

export const RESET_TAG_DETAIL = "RESET_TAG_DETAIL";

export const resetTagDetailAction = () => ({
  type: RESET_TAG_DETAIL,
});
