import React from "react";
import { Col, Row, List } from "antd";
import { Link } from "react-router-dom";

const TagWidget = ({ tags }) => {
  const renderedTag = (item) => (
    <Row align="top" className=" p-0 mx-2" key={item._id}>
      <div
        className="homePage__tagsContainer__tagsCard-circle mr-3"
        style={{
          backgroundColor: `#${item.color}`,
        }}
      />
      <div>
        <Link to={{ pathname: "/tag", data: item }}>
          <h3 className="pt-1">{item.name}</h3>
          <div className="p-0 m-0" style={{ fontSize: 12 }}>
            <p>Aziende: {item.companies}</p>
            <p>Contatti: {item.contacts}</p>
          </div>
        </Link>
      </div>
    </Row>
  );
  return (
    <div className="py-3 px-2">
      <h2>Tags</h2>
      <div className="homePage__tagsContainer__tagsCard">
        <List
          itemLayout="horizontal"
          dataSource={tags}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta description={renderedTag(item)} />
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};

export default TagWidget;
