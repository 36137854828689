import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Input,
  Button,
  Collapse,
  Select,
  Modal,
  DatePicker,
} from "antd";
import SearchOutlined from "@ant-design/icons/es/icons/SearchOutlined";
import SettingOutlined from "@ant-design/icons/es/icons/SettingOutlined";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { isEmpty } from "lodash";
import Tree from "../../components/Tree/Tree";
import Localize from "../../libs/localization";
import BannerTop from "../../components/BannerTop/BannerTop";
import Label from "../../components/Label/Label";
import Form from "../../components/Form/Form";
import InvestmentForm from "../form/InvestmentForm";
import PitchList from "../../components/List/Pitch.list";
import { getTreeCategoryAction } from "../../actions/registry/registry.category.actions";
import {
  resetSearchAction,
  searchPitchesAction,
} from "../../actions/search.action";
import { statusOptions } from "./_pitch.search.data";
import { getPitchGraphAction } from "../../actions/pitch.actions";
import { getMainCategoryAction } from "../../actions/registry/registry.main_category.actions";
import PitchGraph from "../../components/PitchGraph/PitchGraph";
import CompanyAutocomplete from "../../components/CompanyAutocomplete/CompanyAutocomplete";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const PAGE_SIZE = 20;

const PitchSearch = (props) => {
  const {} = props;
  const dispatch = useDispatch();

  const [showOther, setShowOther] = useState(false);
  const [showBrandModal, setShowBrandModal] = useState(false);
  const [moveSearchButton, setMoveSearchButton] = useState(false);
  const [dataForm, setDataForm] = useState({ company_filters: {} });
  const [filters, setFilters] = useState({});
  const [companyFilters, setCompanyFilters] = useState({});
  const [order, setOrder] = useState("name");
  const [orderDir, setOrderDir] = useState("asc");

  const token = useSelector((state) => state.auth.token);
  const category = useSelector((state) => state.pitch_category.data);
  const mainCategory = useSelector((state) => state.main_category.data);
  const resultSearch = useSelector((state) => state.search.results.data);
  const pitchGraph = useSelector((state) => state.pitch.graph);

  useEffect(() => {
    if (category.length === 0) {
      dispatch(getTreeCategoryAction());
    }

    if (mainCategory.length === 0) {
      dispatch(getMainCategoryAction());
    }
  }, [category, mainCategory]);

  useEffect(() => {
    dispatch(resetSearchAction());
  }, []);

  const searchRequest = () => {
    const dataParams = {};
    Object.keys(dataForm).forEach((p) => {
      delete dataForm.name;
      delete dataForm.main_category;
      if (Array.isArray(dataForm[p])) {
        if (dataForm[p].length > 0) {
          dataParams[p] = dataForm[p];
        }
      } else if (dataForm[p] && dataForm[p].length > 0) {
        dataParams[p] = dataForm[p];
      } else if (dataForm[p] === true) {
        dataParams[p] = dataForm[p];
      }
    });

    if (order) {
      dataParams.sort_key = order;
      dataParams.sort_direction = orderDir;
    }

    dataParams.company_filters = {};
    if (!isEmpty(companyFilters)) {
      delete companyFilters.title;
      delete companyFilters.category;
      delete companyFilters.min_start_date;
      delete companyFilters.max_start_date;
      delete companyFilters.nielsen_filters;
      delete companyFilters.status;
      const compFilt = {};
      let company = "";
      Object.keys(companyFilters).forEach((p) => {
        if (Array.isArray(companyFilters[p])) {
          if (companyFilters[p].length > 0) {
            compFilt[p] = companyFilters[p];
          }
        } else if (companyFilters[p] && companyFilters[p].length > 0) {
          compFilt[p] = companyFilters[p];
        }
      });
      if (Array.isArray(compFilt.name)) {
        company = compFilt.name.pop();
        compFilt.name = company;
        setCompanyFilters({ name: company });
      }
      dataParams.company_filters = { ...compFilt };
      setDataForm({ ...dataForm, company_filters: { ...compFilt } });
    }

    dataParams.pageNum = 1;
    setFilters(dataParams);
    dispatch(searchPitchesAction({ data: dataParams }));
    // TODO: rimuovere da qui il dispatch
    delete dataParams.pageNum;
    delete dataParams.limit;
    delete dataParams.sort_direction;
    delete dataParams.sort_key;
    dispatch(getPitchGraphAction({ data: dataParams }));
  };

  useEffect(() => {
    searchRequest();
  }, [orderDir, order]);

  const onMoreBrandClick = (title, content) => {
    Modal.info({
      title,
      content,
      width: "800px",
      onOk: () => setShowBrandModal(false),
    });
  };

  const setStatusText = (statusKey) => {
    switch (statusKey) {
      case "ALL":
        return "Tutti";
      case "ONGOING":
        return "In corso";
      case "CLOSED":
        return "Chiusi";
      default:
        return "";
    }
  };

  const handleOrder = (val) => {
    if (val === order) {
      setOrderDir(orderDir == "asc" ? "desc" : "asc");
    } else {
      setOrderDir("asc");
    }
    setOrder(val);
  };

  const orderLabel = (value, key) => {
    return (
      <Button
        type="link"
        className={`${
          order === value ? "selected" : ""
        } searchPage__orders-button`}
        onClick={() => handleOrder(value)}
      >
        {key}
        {order === value && (
          <span className="searchPage__orders-icon">
            {orderDir == "asc" ? <UpOutlined /> : <DownOutlined />}
          </span>
        )}
      </Button>
    );
  };

  const renderSearchButton = () => (
    <Row justify="end" align="bottom" style={{ height: "100%" }}>
      <Button type="primary" onClick={() => searchRequest()}>
        <SearchOutlined />
        {Localize("COMMON.SEARCH")}
      </Button>
    </Row>
  );

  return (
    <>
      <BannerTop small>
        <h1 className="mt-4">{Localize("PAGES.PITCH.TITLE")}</h1>
      </BannerTop>
      <div className="searchPage__filters animation fadein-up slow \">
        <Card
          bordered={false}
          style={{ borderRadius: "10px" }}
          className="companyPage__body-card companyPage__body-card--custom customCard"
        >
          <Form
            initialValues={dataForm}
            onChange={({ values, errors }) => {
              setDataForm({
                ...dataForm,
                company_filters: { ...companyFilters },
                ...values,
                ...errors,
              });
              setCompanyFilters({
                ...companyFilters,
                ...values,
                ...errors,
              });
            }}
          >
            {({ updateValue: updateValueParent }) => (
              <>
                <Row gutter={16} align="bottom">
                  <Col span={8}>
                    <Label value={Localize("PAGES.SEARCH.ORDER.TITLE")} />
                    <Input
                      value={dataForm.title}
                      onChange={(e) => {
                        updateValueParent({
                          key: "title",
                          value: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col span={8}>
                    <Label value={Localize("PAGES.SEARCH.ORDER.CATEGORY")} />
                    <Tree
                      datatree={category}
                      onChange={(value) =>
                        updateValueParent({ key: "category", value })
                      }
                      onSelect={(value) =>
                        updateValueParent({ key: "category", value })
                      }
                    />
                  </Col>
                  <Col span={8}>
                    <Label value={Localize("COMMON.DATE")} />
                    <RangePicker
                      format="DD-MM-YYYY"
                      placeholder={[
                        Localize("COMMON.START"),
                        Localize("COMMON.END"),
                      ]}
                      className="mr-5"
                      allowEmpty={[false, true]}
                      defaultValue={[
                        dataForm.min_start_date &&
                          moment(dataForm.min_start_date),
                        dataForm.max_start_date &&
                          moment(dataForm.max_start_date),
                      ]}
                      onChange={(dates) => {
                        const [dateStart, dateEnd] = dates || [];
                        if (dateStart) {
                          updateValueParent({
                            key: "min_start_date",
                            value: dateStart.format("YYYY-MM-DD"),
                          });
                        } else {
                          updateValueParent({
                            key: "min_start_date",
                            value: "",
                          });
                        }
                        if (dateEnd) {
                          updateValueParent({
                            key: "max_start_date",
                            value: dateEnd.format("YYYY-MM-DD"),
                          });
                        } else {
                          updateValueParent({
                            key: "max_start_date",
                            value: "",
                          });
                        }
                      }}
                    />
                  </Col>
                </Row>
                <Row gutter={16} className="mt-3" align="bottom">
                  <Col span={8}>
                    <Label value={Localize("COMMON.COMPANY")} />
                    <CompanyAutocomplete
                      singleValue
                      value={dataForm.company_filters.name}
                      token={token}
                      onChange={(value) => {
                        updateValueParent({ key: "name", value });
                      }}
                    />
                  </Col>
                  <Col span={8}>
                    <Label value={Localize("PAGES.SEARCH.ORDER.SECTOR")} />
                    <Select
                      defaultValue={dataForm.company_filters.main_category}
                      showSearch
                      allowClear
                      style={{ width: "100%" }}
                      onChange={(value) =>
                        updateValueParent({ key: "main_category", value })
                      }
                      options={
                        mainCategory.enum &&
                        mainCategory.enum.map((e, j) => {
                          return {
                            value: e.id,
                            key: e.id,
                          };
                        })
                      }
                    />
                  </Col>
                  <Col span={8}>
                    <Label value="Status" />
                    <Select
                      style={{ width: "100%" }}
                      allowClear
                      value={setStatusText(dataForm.status)}
                      options={statusOptions}
                      onChange={(value, option) => {
                        if (value) {
                          updateValueParent({
                            key: "status",
                            value: option.key,
                          });
                        } else {
                          updateValueParent({
                            key: "status",
                            value: "",
                          });
                        }
                      }}
                    />
                  </Col>
                </Row>
                <Row justify="space-between" className="mt-3" align="middle">
                  <Col>
                    <Button
                      type="link"
                      onClick={() => {
                        setShowOther(!showOther);
                        setMoveSearchButton(!moveSearchButton);
                      }}
                      className="secondary p-0"
                    >
                      <SettingOutlined className="mr-1" />
                      {Localize(
                        `COMMON.${!showOther ? "OTHER_FILTERS" : "CLOSE"}`
                      )}
                    </Button>
                  </Col>
                  <Col>{!moveSearchButton && renderSearchButton()}</Col>
                </Row>
                {showOther && (
                  <>
                    <div className="animation fadein-right slow">
                      <Form
                        initialValues={dataForm}
                        onChange={({ values, errors }) => {
                          setDataForm({
                            ...dataForm,
                            ...values,
                            ...errors,
                          });
                        }}
                      >
                        {({ updateValue }) => (
                          <Collapse ghost={true} style={{ marginLeft: -15 }}>
                            <Panel
                              key="1"
                              header={Localize(
                                "PAGES.SEARCH.ORDER.ADEVERTISING_INVESTMENTS"
                              )}
                            >
                              <InvestmentForm
                                onChange={(key, value) =>
                                  updateValue({ key, value })
                                }
                                dataForm={dataForm}
                              />
                            </Panel>
                          </Collapse>
                        )}
                      </Form>
                    </div>
                    {moveSearchButton && renderSearchButton()}
                  </>
                )}
              </>
            )}
          </Form>
        </Card>
      </div>
      <div>
        <Row
          align="middle"
          className="searchPage__orders"
          style={{ height: 60 }}
        >
          <Col>{Localize("COMMON.ORDER_BY")}</Col>
          <Col span={15}>
            {orderLabel("name", Localize("PAGES.SEARCH.ORDER.NAME"))}
            {orderLabel("start_date", Localize("PAGES.SEARCH.ORDER.DATE"))}
          </Col>
          <div>
            <h2
              style={{ color: "white", fontWeight: "bold" }}
              className="p-0 m-0 searchPage__orders-button"
            >
              {Localize("PAGES.SEARCH.PITCH_FOUND")}{" "}
              <span>
                {resultSearch.pitches && resultSearch.pitches.totalResults
                  ? resultSearch.pitches.totalResults
                  : "0"}
              </span>
            </h2>
          </div>
        </Row>
      </div>
      {pitchGraph && pitchGraph.length > 0 ? (
        <PitchGraph data={pitchGraph} />
      ) : null}
      {resultSearch.pitches && resultSearch.pitches.data && (
        <PitchList
          pitchList={(resultSearch.pitches && resultSearch.pitches.data) || []}
          pageSize={PAGE_SIZE}
          total={resultSearch.pitches && resultSearch.pitches.totalResults}
          onMoreBrandClick={onMoreBrandClick}
          filters={filters}
        />
      )}
      <div />
    </>
  );
};

export default PitchSearch;
