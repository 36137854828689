import React, { useState } from "react";
import { List, Row, Collapse } from "antd";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import PictureOutlined from "@ant-design/icons/es/icons/PictureOutlined";
import { searchBrandsAction } from "../../actions/search.action";
import BrandModal from "../../pages/brand/brand.modal";
import { upperCase } from "lodash";

const { Panel } = Collapse;

const BrandList = (props) => {
  const {
    data,
    pageSize = 5,
    title = "",
    onRowClick,
    total,
    filters,
    disablePagination,
    disableDetail,
  } = props;

  const [pSize, setPSize] = useState(pageSize);
  const [showBrandModal, setShowBrandModal] = useState(false);
  const [detailId, setDetailId] = useState(null);

  const dispatch = useDispatch();
  const location = useLocation();

  const fetchData = (page, pageSize) => {
    if (pSize != pageSize) {
      setPSize(pageSize);
    }
    const query = queryString.parse(location.search);
    let params = "";
    if (query.key) {
      params = `?name=${query.key}&pageNum=${page}&limit=${pageSize}`;
    }
    if (filters) {
      params = `${filters}&pageNum=${page}&limit=${pageSize}`;
    }

    dispatch(
      searchBrandsAction({
        params,
      })
    );
  };

  const openBrandDetail = (item) => {
    setDetailId(item._id);
    setShowBrandModal(true);
  };

  return (
    <>
      {data.length > 0 && (
        <div title={title}>
          <List
            className="searchPage__list animation"
            itemLayout="horizontal"
            dataSource={data}
            pagination={
              disablePagination
                ? false
                : {
                    onChange: (page, pageSize) => {
                      fetchData(page, pageSize);
                    },
                    pageSize: pSize,
                    total,
                  }
            }
            renderItem={(item) => (
              <div className="searchPage__item-more animation fadein-right slow">
                <List.Item
                  className="searchPage__item"
                  onClick={() => !disableDetail && openBrandDetail(item)}
                >
                  <List.Item.Meta
                    avatar={
                      item.logo ? (
                        <div
                          style={{ backgroundImage: `url(${item.logo})` }}
                          className="searchPage__item-logo"
                        />
                      ) : (
                        <Row align="middle">
                          <PictureOutlined className="searchPage__item-logo--placeholder" />
                        </Row>
                      )
                    }
                    title={
                      <div>
                        <h3 className="searchPage__item-title">
                          <b>{upperCase(item.name)}</b>
                        </h3>
                      </div>
                    }
                    description={
                      <>
                        <p>
                          {item.company && (
                            <b>{upperCase(item.company.name)}</b>
                          )}
                        </p>
                      </>
                    }
                  />
                </List.Item>
              </div>
            )}
          />
        </div>
      )}
      {detailId && (
        <BrandModal
          id={detailId}
          visible={showBrandModal}
          close={() => {
            setDetailId(null);
            setShowBrandModal(false);
          }}
        />
      )}
    </>
  );
};

export default BrandList;
