import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Collapse, Input, Select } from "antd";
import SearchOutlined from "@ant-design/icons/es/icons/SearchOutlined";
import SettingOutlined from "@ant-design/icons/es/icons/SettingOutlined";
import { useDispatch, useSelector } from "react-redux";
import DownOutlined from "@ant-design/icons/es/icons/DownOutlined";
import UpOutlined from "@ant-design/icons/es/icons/UpOutlined";
import { isEmpty } from "lodash";
import Localize from "../../components/Localized/Localize.comp";
import BannerTop from "../../components/BannerTop/BannerTop";
import InvestmentForm from "../form/InvestmentForm";
import Form from "../../components/Form/Form";
import { getTreeSectorAction } from "../../actions/registry/registry.sector.actions";
import Tree from "../../components/Tree/Tree";
import Label from "../../components/Label/Label";
import { companyTypeOptions } from "./_workRelation.search.data";
import {
  resetSearchAction,
  searchWorkRelationAction,
} from "../../actions/search.action";
import WorkRelationList from "../../components/List/WorkRelation.list";

const { Panel } = Collapse;
const PAGE_SIZE = 20;

const WorkRelationSearch = (props) => {
  const {} = props;
  const dispatch = useDispatch();

  const [showOther, setShowOther] = useState(false);
  const [dataForm, setDataForm] = useState({ company_filters: {} });
  const [companyFilters, setCompanyFilters] = useState({});
  const [filters, setFilters] = useState({});
  const [order, setOrder] = useState("company");
  const [orderDir, setOrderDir] = useState("asc");
  const sector = useSelector((state) => state.sector.data);
  const resultSearch = useSelector((state) => state.search.results.data);

  useEffect(() => {
    if (sector.length === 0) {
      dispatch(getTreeSectorAction());
    }
  }, [sector]);

  useEffect(() => {
    dispatch(resetSearchAction());
  }, []);

  const searchRequest = () => {
    const dataParams = {};
    Object.keys(dataForm).forEach((p) => {
      delete dataForm.type;
      if (Array.isArray(dataForm[p])) {
        if (dataForm[p].length > 0) {
          dataParams[p] = dataForm[p];
        }
      } else if (dataForm[p] && dataForm[p].length > 0) {
        dataParams[p] = dataForm[p];
      } else if (dataForm[p] === true) {
        dataParams[p] = dataForm[p];
      }
    });

    if (order) {
      dataParams.sort_key = order;
      dataParams.sort_direction = orderDir;
    }

    dataParams.company_filters = {};
    if (!isEmpty(companyFilters)) {
      delete companyFilters.brand_company_agency;
      delete companyFilters.sector;
      delete companyFilters.nielsen_filters;
      const compFilt = {};
      Object.keys(companyFilters).forEach((p) => {
        if (Array.isArray(companyFilters[p])) {
          if (companyFilters[p].length > 0) {
            compFilt[p] = companyFilters[p];
          }
        } else if (companyFilters[p] && companyFilters[p].length > 0) {
          compFilt[p] = companyFilters[p];
        }
      });

      dataParams.company_filters = { ...compFilt };
      setDataForm({ ...dataForm, company_filters: { ...compFilt } });
    }
    setFilters(dataParams);
    dataParams.pageNum = 1;
    dispatch(searchWorkRelationAction({ data: dataParams }));
  };

  const handleOrder = (val) => {
    if (val === order) {
      setOrderDir(orderDir === "asc" ? "desc" : "asc");
    } else {
      setOrderDir("asc");
    }
    setOrder(val);
  };

  useEffect(() => {
    searchRequest();
  }, [orderDir, order]);

  const orderLabel = (value, key) => {
    return (
      <Button
        type="link"
        className={`${
          order === value ? "selected" : ""
        } searchPage__orders-button`}
        onClick={() => handleOrder(value)}
      >
        {key}
        {order === value && (
          <span className="searchPage__orders-icon">
            {orderDir === "asc" ? <UpOutlined /> : <DownOutlined />}
          </span>
        )}
      </Button>
    );
  };

  return (
    <>
      <BannerTop small>
        <h1 className="mt-4">{Localize("PAGES.WORK_RELATION.TITLE")}</h1>
      </BannerTop>
      <div className="searchPage__filters animation fadein-up slow \">
        <Card
          bordered={false}
          style={{ borderRadius: "10px" }}
          className="companyPage__body-card companyPage__body-card--custom customCard"
        >
          <Form
            initialValues={dataForm}
            onChange={({ values, errors }) => {
              setDataForm({
                ...dataForm,
                ...values,
                ...errors,
              });
              setCompanyFilters({
                ...companyFilters,
                ...values,
                ...errors,
              });
            }}
          >
            {({ updateValue: updateValueParent }) => (
              <>
                <Row gutter={16}>
                  <Col span={8}>
                    <Label value={Localize("COMMON.SEARCH")} />
                    <Input
                      value={dataForm.brand_company_agency}
                      onChange={(e) =>
                        updateValueParent({
                          key: "brand_company_agency",
                          value: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col span={8}>
                    <Label value={Localize("PAGES.SEARCH.ORDER.SECTOR")} />
                    <Tree
                      valueData={dataForm.sector}
                      datatree={sector}
                      onChange={(value) => {
                        updateValueParent({ key: "sector", value });
                      }}
                      onSelect={(value, e) => {
                        updateValueParent({ key: "sector", value });
                      }}
                    />
                  </Col>
                  <Col span={8}>
                    <Label
                      value={Localize("PAGES.SEARCH.ORDER.COMPANY_TYPE")}
                    />
                    <Select
                      style={{ width: "100%" }}
                      allowClear
                      options={companyTypeOptions}
                      defaultValue={dataForm.company_filters.type}
                      onChange={(value, option) => {
                        if (value) {
                          updateValueParent({ key: "type", value: option.key });
                        } else {
                          updateValueParent({ key: "type", value: "" });
                        }
                      }}
                    />
                  </Col>
                </Row>
                <Row justify="end" className="mt-3" align="middle">
                  <Col>
                    <Button type="primary" onClick={searchRequest}>
                      <SearchOutlined />
                      {Localize("COMMON.SEARCH")}
                    </Button>
                    <Button
                      type="link"
                      onClick={() => setShowOther(!showOther)}
                      className="secondary"
                    >
                      <SettingOutlined className="mr-1" />
                      {Localize(
                        `COMMON.${!showOther ? "OTHER_FILTERS" : "CLOSE"}`
                      )}
                    </Button>
                  </Col>
                </Row>
                {showOther && (
                  <div className="animation fadein-right slow">
                    <Form
                      initialValues={dataForm}
                      onChange={({ values, errors }) => {
                        setDataForm({
                          ...dataForm,
                          ...values,
                          ...errors,
                        });
                      }}
                    >
                      {({ updateValue }) => (
                        <Collapse ghost={true} style={{ marginLeft: "-15px" }}>
                          <Panel
                            key="1"
                            header={Localize(
                              "PAGES.SEARCH.ORDER.ADEVERTISING_INVESTMENTS"
                            )}
                          >
                            <InvestmentForm
                              onChange={(key, value) =>
                                updateValue({ key, value })
                              }
                              dataForm={dataForm}
                            />
                          </Panel>
                        </Collapse>
                      )}
                    </Form>
                  </div>
                )}
              </>
            )}
          </Form>
        </Card>
      </div>
      {resultSearch &&
        resultSearch.workRelations &&
        resultSearch.workRelations.data && (
          <div>
            <Row align="middle" className="searchPage__orders">
              <Col>{Localize("COMMON.ORDER_BY")}</Col>
              <Col>
                {orderLabel("company", Localize("PAGES.SEARCH.ORDER.NAME"))}
              </Col>
            </Row>
            <div>
              <WorkRelationList
                data={
                  (resultSearch.workRelations &&
                    resultSearch.workRelations.data) ||
                  []
                }
                total={
                  resultSearch.workRelations &&
                  resultSearch.workRelations.totalResults
                }
                pageSize={PAGE_SIZE}
                filters={filters}
              />
            </div>
          </div>
        )}
    </>
  );
};

export default WorkRelationSearch;
