import Config from "../Config";
import ErrorMessage from "../libs/helpers/ErrorMessage";

export const getTreeDataApi = async ({ headers, entity }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.tree.list}?entity=${entity}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getFlatEntityListApi = async ({ headers, entity }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.flat.list}?entity=${entity}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};
