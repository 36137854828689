import React, { lazy } from "react";
import Config from "./Config";
import {
  HomeOutlined,
  ShopOutlined,
  ContactsOutlined,
  AuditOutlined,
  RocketOutlined,
  SettingOutlined,
  TeamOutlined,
  StarOutlined,
} from "@ant-design/icons";

// npm libs
// ---

// internal libs
import { type } from "./libs/router";
import { AdminLayout, SiteLayout } from "./layouts";
import {
  removeFromLocalStorage,
  setOnLocalStorage,
} from "./libs/helpers/localstorage";
import Localize from "./components/Localized/Localize.comp";

// components
import Loading from "./components/Loading/Loading";
import {
  CompanyDetail,
  CompanySearch,
  HomePage,
  LoginPage,
  Contact,
  WorkRelationSearch,
  TagPage,
} from "./pages";
import SearchPage from "./pages/search/search.page";
import ContactSearch from "./pages/contact/contact.search";
import PitchSearch from "./pages/pitch/pitch.search";
import { refreshPage } from "./libs/utils";
const AuthPage = lazy(() => import("./pages/system/auth.page"));
const Forbidden = lazy(() => import("./pages/system/forbidden.page"));

export default [
  {
    id: "login",
    path: "/login",
    title: Localize("PAGES.LOGIN.TITLE"),
    noFollow: true,
    showInMenu: false,
    type: type.AUTHENTICATION,
    /*component: ({ location }) => {
      const { search } = location;
      const URLparams = new URLSearchParams(search);
      const redirectUri = URLparams.get("redirectUri");
      setOnLocalStorage({ redirectUri });
      const loginUrl = `${Config.apiBaseurl}/${Config.apis.oauth.login}?redirect_uri=${Config.appBaseurl}/authorize`;
      window.location = loginUrl;
      return Loading({ coverBack: true });
    },*/
    component: LoginPage,
  },
  {
    id: "authorize",
    path: "/authorize",
    title: Localize("PAGES.AUTHORIZE.TITLE"),
    noFollow: true,
    component: AuthPage,
    showInMenu: false,
    type: type.AUTHENTICATION,
  },
  {
    id: "logout",
    path: "/logout/do",
    showInMenu: false,
    noFollow: true,
    type: type.SECURED,
    component: () => {
      removeFromLocalStorage("user");
      removeFromLocalStorage("token");
      // window.location = `${Config.apiBaseurl}/${Config.apis.oauth.logout}`;
      removeFromLocalStorage(null, "lefacWebTenant");
      window.location.href = "/";
      return Loading({ coverBack: true });
    },
  },
  {
    id: "home",
    title: Localize("PAGES.HOME.TITLE"),
    path: "/",
    component: HomePage,
    showInMenu: true,
    icon: <HomeOutlined />,
    layout: SiteLayout,
    type: type.SECURED,
  },
  {
    id: "tag",
    title: Localize("PAGES.TAG.TITLE"),
    path: "/tag",
    component: TagPage,
    showInMenu: true,
    icon: <StarOutlined />,
    layout: SiteLayout,
    type: type.SECURED,
    scopes: ["company", "contact"],
  },
  {
    id: "companySearch",
    title: Localize("PAGES.COMPANIES.TITLE"),
    path: "/companies",
    component: CompanySearch,
    showInMenu: true,
    icon: <ShopOutlined />,
    layout: SiteLayout,
    type: type.SECURED,
    scopes: ["company"],
  },
  {
    id: "company",
    title: Localize("PAGES.COMPANIES.TITLE"),
    path: "/companies/:id",
    component: CompanyDetail,
    showInMenu: false,
    icon: <ShopOutlined />,
    layout: SiteLayout,
    type: type.SECURED,
    scopes: ["company"],
  },
  {
    id: "contactSearch",
    title: Localize("PAGES.CONTACTS.TITLE"),
    path: "/contacts",
    component: ContactSearch,
    showInMenu: true,
    icon: <ContactsOutlined />,
    layout: SiteLayout,
    type: type.SECURED,
    scopes: ["contact"],
  },
  {
    id: "contact",
    title: Localize("PAGES.CONTACTS.TITLE"),
    path: "/contacts/:id",
    component: Contact,
    showInMenu: false,
    icon: <ContactsOutlined />,
    layout: SiteLayout,
    type: type.SECURED,
    scopes: ["contact"],
  },
  {
    id: "pitchSearch",
    title: Localize("PAGES.PITCH.TITLE"),
    path: "/pitch",
    component: PitchSearch,
    showInMenu: true,
    icon: <AuditOutlined />,
    layout: SiteLayout,
    type: type.SECURED,
    scopes: ["pitch"],
  },
  {
    id: "workRelation",
    title: Localize("PAGES.WORK_RELATION.TITLE"),
    path: "/workRelation",
    component: WorkRelationSearch,
    showInMenu: true,
    icon: <TeamOutlined />,
    layout: SiteLayout,
    type: type.SECURED,
    scopes: ["pitch"],
  },
  {
    id: "searchPage",
    title: "searchPage",
    path: "/searchPage",
    component: SearchPage,
    showInMenu: false,
    layout: SiteLayout,
    type: type.SECURED,
  },
  {
    id: "forbidden",
    title: Localize("LOGIN.FORBIDDEN"),
    path: "/forbidden",
    component: Forbidden,
    showInMenu: false,
    // layout: AdminLayout,
    layoutOptions: { boxShadow: false, noPadding: true },
    type: type.PUBLIC,
  },
  /*  {
    title: Localize("PAGES.COMPANIES.TITLE"),
    showInMenu: true,
    icon: <ShopOutlined />,
    id: "company_list",
    path: "/companies",
    component: CompanyListPage,
    layout: AdminLayout,
    layoutOptions: { boxShadow: false, noPadding: true },
    type: type.PUBLIC,
  },
  {
    id: "company_detail",
    title: Localize("PAGES.COMPANIES.DETAIL.TITLE"),
    path: "/companies/:id",
    component: CompanyDetailPage,
    showInMenu: false,
    layout: AdminLayout,
    layoutOptions: { boxShadow: false, noPadding: true },
    type: type.PUBLIC,
  },
  {
    title: Localize("PAGES.CONTACTS.TITLE"),
    showInMenu: true,
    icon: <ContactsOutlined />,
    type: type.PUBLIC,
    id: "contact_list",
    path: "/contacts",
    component: ContactListPage,
    layout: AdminLayout,
    layoutOptions: { boxShadow: false, noPadding: true },
  },
  {
    id: "contact_detail",
    title: Localize("PAGES.CONTACTS.DETAIL.TITLE"),
    path: "/contacts/:id",
    component: ContactDetailPage,
    showInMenu: false,
    layout: AdminLayout,
    layoutOptions: { boxShadow: false, noPadding: true },
    type: type.PUBLIC,
  },
  {
    title: Localize("PAGES.BRANDS.TITLE"),
    icon: <RocketOutlined />,
    type: type.PUBLIC,
    id: "brand_list",
    path: "/brands",
    component: BrandListPage,
    showInMenu: true,
    layout: AdminLayout,
    layoutOptions: { boxShadow: false, noPadding: true },
  },
  {
    id: "brand_detail",
    title: Localize("PAGES.BRANDS.DETAIL.TITLE"),
    path: "/brands/:id",
    component: BrandDetailPage,
    showInMenu: false,
    layout: AdminLayout,
    layoutOptions: { boxShadow: false, noPadding: true },
    type: type.PUBLIC,
  },
  {
    title: Localize("PAGES.SETTINGS.TITLE"),
    icon: <SettingOutlined />,
    type: type.PUBLIC,
    id: "settings",
    path: "/settings",
    component: SettingsPage,
    showInMenu: true,
    layout: AdminLayout,
    layoutOptions: { boxShadow: false, noPadding: true },
  },*/
];
