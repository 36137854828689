import React, { Suspense } from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { pick } from "lodash";

import MetaTags from "../components/MetaTags/MetaTags";
import {
  removeFromLocalStorage,
  getFromLocalStorage,
} from "./helpers/localstorage";
import Loading from "../components/Loading/Loading";

export const type = {
  PUBLIC: "PUBLIC",
  SECURED: "SECURED",
  AUTHENTICATION: "AUTHENTICATION",
};

export const getFlatRouteList = (routes) => {
  const routeList = [];
  routes.map((route, i) => {
    if (route.path) {
      routeList.push(route);
    }
    if (route.children && route.children.length > 0) {
      route.children.map((child, j) => {
        child.parent_id = route.id;
        if (child.path) {
          routeList.push(child);
        }
      });
    }
  });
  return routeList;
};

const drawComponent = (route, props) => (
  <>
    <MetaTags
      title={`${
        route.title && typeof route.title === "string" ? `${route.title}` : ""
      }`}
      noFollow={route.noFollow}
    />
    <Suspense fallback={<Loading coverBack={true} />}>
      {route.layout ? (
        <route.layout
          {...{
            ...props,
            ...pick(route, ["id", "parent_id", "path", "title", "showInMenu"]),
            ...route.layoutOptions,
          }}
        >
          <route.component {...props} />
        </route.layout>
      ) : (
        <route.component {...props} />
      )}
    </Suspense>
  </>
);

const Router = ({ routes = null, authorized = false, permissions = {} }) =>
  routes ? (
    <>
      <Switch>
        {getFlatRouteList(routes).map((r, i) => (
          <Route
            key={i}
            path={r.path}
            exact={r.exact || true}
            render={(props) => {
              const { location, match } = props;
              const { pathname, hash, search } = location;
              const nextRedirectUri = `${pathname || match.url}${hash || ""}${
                search || ""
              }`;
              const ls = getFromLocalStorage();
              const redirectUri = ls && ls.redirectUri;
              // console.log(r);
              // console.log(r.path, !!r.component);
              if (r.redirectToExternal) {
                window.location = r.redirectToExternal;
                return <Loading />;
              } else if (r.redirectTo) {
                return <Redirect to={r.redirectTo} />;
              } else if (!authorized && r.type === type.SECURED) {
                return (
                  <Redirect
                    to={`/login?redirectUri=${encodeURIComponent(
                      nextRedirectUri
                    )}`}
                  />
                );
              } else {
              /*              else
                if (authorized && r.type === type.AUTHENTICATION) {
                removeFromLocalStorage("redirectUri");
                return <Redirect to={redirectUri? decodeURIComponent(redirectUri) : "/"} />;
              } */
                if (!r.component) {
                  return <Loading />;
                } else {
                  if (
                    r.type === type.SECURED &&
                    r.scopes &&
                    r.scopes.length > 0
                  ) {
                    return (r.scopes || []).some((s) =>
                      Object.keys(permissions).some((p) => p === s)
                    ) ? (
                      drawComponent(r, {
                        ...props,
                        permissions: (r.scopes || []).map((s) => ({
                          [s]: permissions[s],
                        })),
                      })
                    ) : (
                      <Redirect to={"/forbidden"} />
                    );
                  }
                  return drawComponent(r, props);
                }
              }
            }}
          />
        ))}

        <Route key={-1} render={() => <Redirect to={"/"} />} />
      </Switch>
    </>
  ) : (
    <p>No routes</p>
  );

Router.propTypes = {
  prompt: PropTypes.shape({
    message: PropTypes.string.isRequired,
    callback: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect()(Router));
