import React, { useEffect } from "react";
import { Col, DatePicker, InputNumber, Row, Select } from "antd";
import Label from "../../components/Label/Label";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getIncomeRangeAction } from "../../actions/registry/registry.income_range.actions";
import { getEmployerRangeAction } from "../../actions/registry/registry.employer_range.actions";
import { getInternationalInvestAction } from "../../actions/registry/registry.international_invest.actions";
import { getMediumPlanningAction } from "../../actions/registry/registry.medium_planning.actions";
import Localize from "../../components/Localized/Localize.comp";

const country = require("../../assets/json/country");

const InfoForm = (props) => {
  const { dataForm, onChange } = props;

  const incomeRange = useSelector((state) => state.income_range.data);
  const employerRange = useSelector((state) => state.employer_range.data);
  const internationalInvest = useSelector(
    (state) => state.international_invest.data
  );
  const mediumPlanning = useSelector((state) => state.medium_planning.data);
  const dispatch = useDispatch();

  useEffect(() => {
    if (incomeRange.length == 0) {
      dispatch(getIncomeRangeAction());
    }
  }, [incomeRange]);

  useEffect(() => {
    if (employerRange.length == 0) {
      dispatch(getEmployerRangeAction());
    }
  }, [employerRange]);

  useEffect(() => {
    if (internationalInvest.length == 0) {
      dispatch(getInternationalInvestAction());
    }
  }, [internationalInvest]);

  useEffect(() => {
    if (mediumPlanning.length == 0) {
      dispatch(getMediumPlanningAction());
    }
  }, [mediumPlanning]);

  return (
    <Row gutter={16}>
      <Col span={8}>
        <Label value={Localize("PAGES.SEARCH.ORDER.CREATION_YEAR")} />
        <DatePicker
          placeholder={""}
          value={dataForm.creation_year && moment(dataForm.creation_year)}
          onChange={(value) => {
            onChange("creation_year", value?.format("YYYY"));
          }}
          picker="year"
        />
      </Col>
      <Col span={8}>
        <Label value={Localize("PAGES.SEARCH.ORDER.NATION_ORIGIN")} />
        <Select
          mode="multiple"
          allowClear={true}
          style={{ width: "100%" }}
          value={dataForm.nation_orig}
          onChange={(value) => onChange("nation_orig", value)}
          showSearch
          options={
            country &&
            country.map((e, j) => {
              return {
                value: e.name,
                key: e.name,
              };
            })
          }
        />
      </Col>
      <Col span={8}>
        <Label value={Localize("PAGES.SEARCH.ORDER.STORE_NUMBER")} />
        <Row>
          <Row align="bottom">
            <b className="mr-2">{Localize("COMMON.FROM")}</b>
            <InputNumber
              value={dataForm.min_store_number}
              onChange={(value) => onChange("min_store_number", value)}
            />
          </Row>
          <Row align="bottom">
            <b className="mx-2">{Localize("COMMON.TO")}</b>
            <InputNumber
              value={dataForm.max_store_number}
              onChange={(value) => onChange("max_store_number", value)}
            />
          </Row>
        </Row>
      </Col>
      <Col span={8} className="mt-3">
        <Label value={Localize("PAGES.SEARCH.ORDER.INCOME_RANGE")} />
        <Select
          mode="multiple"
          allowClear={true}
          style={{ width: "100%" }}
          value={dataForm.income_range}
          onChange={(value) => onChange("income_range", value)}
          showSearch
          options={
            incomeRange.enum &&
            incomeRange.enum
              .filter((e) => e.id.length > 0)
              .map((e, j) => {
                return {
                  value: e.id,
                  key: e.id,
                };
              })
          }
        />
      </Col>
      <Col span={8} className="mt-3">
        <Label value={Localize("PAGES.SEARCH.ORDER.EMPLOYER_RANGE")} />
        <Select
          mode="multiple"
          allowClear={true}
          style={{ width: "100%" }}
          value={dataForm.employer_range}
          onChange={(value) => onChange("employer_range", value)}
          showSearch
          options={
            employerRange.enum &&
            employerRange.enum
              .filter((e) => e.id.length > 0)
              .map((e, j) => {
                return {
                  value: e.id,
                  key: e.id,
                };
              })
          }
        />
      </Col>
      <Col span={8} className="mt-3">
        <Label value={Localize("PAGES.SEARCH.ORDER.INTERNATIONAL_INVEST")} />
        <Select
          mode="multiple"
          allowClear={true}
          style={{ width: "100%" }}
          value={dataForm.international_invest}
          onChange={(value) => onChange("international_invest", value)}
          showSearch
          options={
            internationalInvest.set &&
            internationalInvest.set
              .filter((e) => e.id.length > 0)
              .map((e, j) => {
                return {
                  value: e.id,
                  key: e.id,
                };
              })
          }
        />
      </Col>

      <Col span={8} className="mt-3">
        <Label value={Localize("PAGES.SEARCH.ORDER.MEDIUM_PLANNIG")} />
        <Select
          mode="multiple"
          allowClear={true}
          style={{ width: "100%" }}
          value={dataForm.medium_planning}
          onChange={(value) => onChange("medium_planning", value)}
          showSearch
          options={
            mediumPlanning.enum &&
            mediumPlanning.enum
              .filter((e) => e.id.length > 0)
              .map((e, j) => {
                return {
                  value: e.id,
                  key: e.id,
                };
              })
          }
        />
      </Col>
    </Row>
  );
};

export default InfoForm;
