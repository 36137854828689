import React from "react";

const Label = (props) => {
  const { value, className = "" } = props;

  return (
    <p className={className}>
      <b>{value}</b>
    </p>
  );
};

export default Label;
