import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Collapse,
  Input,
  Popover,
  Row,
  Select,
  Tooltip,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import SearchOutlined from "@ant-design/icons/es/icons/SearchOutlined";
import SettingOutlined from "@ant-design/icons/es/icons/SettingOutlined";
import DownOutlined from "@ant-design/icons/es/icons/DownOutlined";
import UpOutlined from "@ant-design/icons/es/icons/UpOutlined";
import StarOutlined from "@ant-design/icons/es/icons/StarOutlined";
import StarFilled from "@ant-design/icons/es/icons/StarFilled";
import BannerTop from "../../components/BannerTop/BannerTop";
import Localize from "../../libs/localization";
import Form from "../../components/Form/Form";
import Tree from "../../components/Tree/Tree";
import { getMainCategoryAction } from "../../actions/registry/registry.main_category.actions";
import Label from "../../components/Label/Label";
import SearchPlaces from "../../components/SearchPlaces/SearchPlaces";
import CompanyAutocomplete from "../../components/CompanyAutocomplete/CompanyAutocomplete";
import { getTreeSectorAction } from "../../actions/registry/registry.sector.actions";
import {
  createSearchAction,
  deleteSearchAction,
  getSearchListAction,
  resetSearchAction,
  searchCompaniesAction,
} from "../../actions/search.action";
import CompanyList from "../../components/List/Company.list";
import InfoForm from "../form/InfoForm";
import InvestmentForm from "../form/InvestmentForm";
import TagOptions from "../../components/Tag/TagOptions";
import CreateTagModal from "../../components/CreateTagModal/CreateTagModal";
import {
  associateTagAction,
  associationsTagIdCompanyAction,
  createTagAction,
  getTagAction,
} from "../../actions/tag.actions";
import FinancialForm from "../form/FinancialForm";
import SearchManagement from "../../components/SearchManagement/SearchManagement";
import { SaveOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

const PAGE_SIZE = 20;

const CompanySearch = (props) => {
  const {} = props;

  const [open, setOpen] = useState([1]);
  const [dataForm, setDataForm] = useState({});
  const [showOther, setShowOther] = useState(false);
  const [moveSearchButton, setMoveSearchButton] = useState(false);
  const [filters, setFilters] = useState({});
  const [order, setOrder] = useState("name");
  const [orderDir, setOrderDir] = useState("asc");

  // TAG
  const [tagMode, setTagMode] = useState(false);
  const [tagAddModal, setAddTagModal] = useState(false);
  const [tagValue, setTagValue] = useState("");
  const [colorValue, setColorValue] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const [selectedSearchElement, setSelectedSearchElement] = useState(null);
  const [searchName, setSearchName] = useState(null);
  const [showPopoverSearch, setShowPopoverSearch] = useState(false);

  const resultSearch = useSelector((state) => state.search.results.data);
  const mainCategory = useSelector((state) => state.main_category.data);
  const token = useSelector((state) => state.auth.token);
  const sector = useSelector((state) => state.sector.data);
  const tags = useSelector((state) => state.tag.tagsList);
  const loadingTagsIdsCompany = useSelector(
    (state) => state.tag.tagsForIdsCompany.loading
  );
  const associatedTagsIdsCompany = useSelector(
    (state) => state.tag.tagsForIdsCompany.data
  );
  const searchList = useSelector((state) => state.search.searchList.data);
  const searchCreated = useSelector((state) => state.search.searchCreated.data);

  const ids = [];

  const dispatch = useDispatch();

  useEffect(() => {
    if (mainCategory.length === 0) {
      dispatch(getMainCategoryAction());
    }
  }, [mainCategory]);

  useEffect(() => {
    if (sector.length === 0) {
      dispatch(getTreeSectorAction());
    }
  }, [sector]);

  useEffect(() => {
    dispatch(getTagAction());
  }, [tagAddModal]);

  useEffect(() => {
    searchRequest();
  }, [selectedSearchElement]);

  useEffect(() => {
    searchRequest();
  }, [searchCreated]);

  const callback = (key) => {
    setOpen(key);
  };

  useEffect(() => {
    dispatch(getSearchListAction({ params: "entity=company" }));
    dispatch(resetSearchAction());
  }, []);

  const searchRequest = () => {
    const dataParams = {};
    Object.keys(dataForm).forEach((p) => {
      if (Array.isArray(dataForm[p])) {
        if (dataForm[p].length > 0) {
          dataParams[p] = dataForm[p];
        }
      } else if (dataForm[p] && dataForm[p].length > 0) {
        dataParams[p] = dataForm[p];
      }
    });

    if (order) {
      dataParams.sort_key = order;
      dataParams.sort_direction = orderDir;
    }

    setFilters(dataParams);
    setShowOther(false);
    setMoveSearchButton(false);
    dataParams.pageNum = 1;
    dispatch(searchCompaniesAction({ data: dataParams }));
  };

  const getCompanyPageIds = () => {
    if (resultSearch.companies && resultSearch.companies.data) {
      resultSearch.companies.data.forEach((company) => {
        ids.push(company._id);
      });
      dispatch(associationsTagIdCompanyAction({ data: { id: ids } }));
    }
  };

  useEffect(() => {
    getCompanyPageIds();
  }, [resultSearch.companies.data, tagMode]);

  const handleOrder = (val) => {
    if (val === order) {
      setOrderDir(orderDir == "asc" ? "desc" : "asc");
    } else {
      setOrderDir("asc");
    }
    setOrder(val);
  };

  useEffect(() => {
    searchRequest();
  }, [orderDir, order]);

  const clearTagFields = () => {
    setTagValue("");
    setColorValue("");
    setErrorMessage("");
  };

  const createTag = () => {
    const params = {
      name: tagValue,
      color: colorValue.hex.replace("#", "").toUpperCase(),
    };

    dispatch(createTagAction({ data: params }));
    clearTagFields();
  };

  const associateTagCompany = () => {
    const dataParams = {};
    let id = "";

    tags.forEach((tag) => {
      if (tag.name === selectedTag) {
        id = tag._id;
      }
    });

    dataParams.name = selectedTag;
    dataParams.companies = selectedCompany;
    dispatch(associateTagAction({ id, data: dataParams }));
    setTagMode(false);
    setSelectedTag("");
  };

  const orderLabel = (value, key) => {
    return (
      <Button
        type="link"
        className={`${
          order === value ? "selected" : ""
        } searchPage__orders-button`}
        onClick={() => handleOrder(value)}
      >
        {key}
        {order === value && (
          <span className="searchPage__orders-icon">
            {orderDir == "asc" ? <UpOutlined /> : <DownOutlined />}
          </span>
        )}
      </Button>
    );
  };

  useEffect(() => {
    setSelectedCompany([]);
  }, [tagMode]);

  const renderSearchButton = () => (
    <Row justify="end" align="bottom" style={{ height: "100%" }}>
      <SearchManagement
        searchList={searchList}
        value={selectedSearchElement}
        onDeleteSearch={() => {
          dispatch(
            deleteSearchAction({
              id: selectedSearchElement,
              type: "company",
            })
          );
          setSelectedSearchElement(null);
          setDataForm({ company_filters: {} });
        }}
        onSearchElementChange={(id, data) => {
          setSelectedSearchElement(id);
          setDataForm(data ? data : { company_filters: {} });
        }}
      />
      <Popover
        placement="topLeft"
        title={Localize("PAGES.SEARCH.SEARCH_NAME")}
        content={searchNameContent}
        trigger="click"
        visible={showPopoverSearch}
        onVisibleChange={(visibility) => setShowPopoverSearch(visibility)}
      >
        <Button
          type="primary"
          className="mr-3"
          onClick={() => setShowPopoverSearch(true)}
        >
          <SaveOutlined />
          {Localize("PAGES.SEARCH.SAVE_SEARCH")}
        </Button>
      </Popover>
      <Button type="primary" onClick={() => searchRequest()}>
        <SearchOutlined />
        {Localize("COMMON.SEARCH")}
      </Button>
    </Row>
  );

  const searchNameContent = () => (
    <Row>
      <Col span={16}>
        <Input
          value={searchName}
          onChange={(e) => {
            setSearchName(e.target.value);
          }}
        />
      </Col>
      <Col align="right" span={8}>
        <Button
          type="primary"
          disabled={!searchName}
          onClick={() => {
            dispatch(
              createSearchAction({
                body: { data: dataForm, name: searchName, type: "company" },
              })
            );
            setShowPopoverSearch(false);
            setSearchName(null);
          }}
        >
          {Localize("COMMON.SAVE")}
        </Button>
      </Col>
    </Row>
  );

  return (
    <>
      <BannerTop small>
        <h1 className="mt-4">{Localize("PAGES.COMPANIES.TITLE")}</h1>
      </BannerTop>
      <div className="searchPage__filters animation fadein-up slow \">
        <Card
          bordered={false}
          style={{ borderRadius: "10px" }}
          className="companyPage__body-card companyPage__body-card--custom customCard"
        >
          <Form
            name="form-create"
            initialValues={dataForm}
            onChange={({ values, errors, completed }) => {
              setDataForm({
                ...dataForm,
                ...values,
                ...errors,
              });
            }}
          >
            {({ values, errors, completed, updateValue }) => (
              <>
                <Row gutter={16}>
                  <Col span={8}>
                    <Label value={Localize("PAGES.SEARCH.ORDER.NAME")} />
                    <CompanyAutocomplete
                      value={dataForm.name}
                      token={token}
                      onChange={(value) => {
                        updateValue({ key: "name", value });
                      }}
                    />
                  </Col>
                  <Col span={8}>
                    <div>
                      <Label value={Localize("PAGES.SEARCH.ORDER.CATEGORY")} />
                      <Select
                        mode="multiple"
                        allowClear={true}
                        style={{ width: "100%" }}
                        value={dataForm.main_category}
                        onChange={(value) =>
                          updateValue({ key: "main_category", value })
                        }
                        showSearch
                        options={
                          mainCategory.enum &&
                          mainCategory.enum.map((e, j) => {
                            return {
                              value: e.id,
                              key: e.id,
                            };
                          })
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col>
                    <Button
                      type="link"
                      onClick={() => {
                        setShowOther(!showOther);
                        setMoveSearchButton(!moveSearchButton);
                      }}
                      className="secondary mt-3 pl-2"
                    >
                      <SettingOutlined className="mr-1" />
                      {Localize(
                        `COMMON.${!showOther ? "OTHER_FILTERS" : "CLOSE"}`
                      )}
                    </Button>
                  </Col>
                  <Col>{!moveSearchButton && renderSearchButton()}</Col>
                </Row>
                {showOther && (
                  <>
                    <div
                      className="animation fadein-right slow "
                      style={{ marginLeft: "-15px" }}
                    >
                      <Collapse
                        onChange={callback}
                        ghost={true}
                        activeKey={open}
                      >
                        <Panel header="Società" key="1" className="pt-3">
                          {" "}
                          <Row gutter={16}>
                            <Col span={8}>
                              <Label
                                value={Localize("PAGES.SEARCH.ORDER.CITY")}
                              />
                              <SearchPlaces
                                value={dataForm.city}
                                placeholder={Localize("COMMON.FIND_ADDRESS")}
                                type={["(cities)"]}
                                onChange={(place) => {
                                  if (place && place.address_components) {
                                    const city =
                                      place.address_components[0].long_name;
                                    updateValue({ key: "city", value: city });
                                  } else {
                                    updateValue({ key: "city", value: "" });
                                  }
                                }}
                              />
                            </Col>
                            <Col span={8}>
                              <Label
                                value={Localize("PAGES.SEARCH.ORDER.SECTOR")}
                              />
                              <Tree
                                valueData={dataForm.sector}
                                datatree={sector}
                                onChange={(value) => {
                                  updateValue({ key: "sector", value });
                                }}
                                onSelect={(value, e) => {
                                  updateValue({ key: "sector", value });
                                }}
                              />
                            </Col>
                            <Col span={8}>
                              <Label
                                value={Localize("PAGES.SEARCH.ORDER.OTHER_CAT")}
                              />
                              <Select
                                mode="multiple"
                                allowClear={true}
                                style={{ width: "100%" }}
                                value={dataForm.other_categories}
                                onChange={(value) =>
                                  updateValue({
                                    key: "other_categories",
                                    value,
                                  })
                                }
                                showSearch
                                options={[
                                  { value: "Lusso", key: "Lusso" },
                                  {
                                    value: "Online Pure Player",
                                    key: "Online Pure Player",
                                  },
                                ]}
                              />
                            </Col>
                            <Col span={8} className="mt-3">
                              <Label
                                value={Localize(
                                  "PAGES.SEARCH.ORDER.ATECO_CODE"
                                )}
                              />
                              <Input
                                value={dataForm.ateco_code}
                                onChange={(e) => {
                                  updateValue({
                                    key: "ateco_code",
                                    value: e.target.value,
                                  });
                                }}
                              />
                            </Col>
                          </Row>
                        </Panel>
                        <Panel
                          header={Localize("PAGES.SEARCH.ORDER.FINANCIAL_DATA")}
                          key="2"
                          className="pt-3"
                        >
                          <FinancialForm
                            onChange={(key, value) =>
                              updateValue({ key, value })
                            }
                            dataForm={dataForm}
                          />
                        </Panel>
                        <Panel
                          header={Localize("PAGES.SEARCH.ORDER.ADDITION_INFO")}
                          key="3"
                          className="pt-3"
                        >
                          <InfoForm
                            onChange={(key, value) =>
                              updateValue({ key, value })
                            }
                            dataForm={dataForm}
                          />
                        </Panel>
                        <Panel
                          header={Localize(
                            "PAGES.SEARCH.ORDER.ADEVERTISING_INVESTMENTS"
                          )}
                          key="4"
                          className="pt-3"
                        >
                          <InvestmentForm
                            onChange={(key, value) =>
                              updateValue({ key, value })
                            }
                            dataForm={dataForm}
                          />
                        </Panel>
                      </Collapse>
                    </div>
                    {moveSearchButton && renderSearchButton()}
                  </>
                )}
              </>
            )}
          </Form>
        </Card>
      </div>
      {resultSearch.companies && resultSearch.companies.data && (
        <div>
          <Row align="middle" className="searchPage__orders">
            <Tooltip title="Associa Tag">
              <Button
                type="primary"
                shape="circle"
                onClick={() => setTagMode(!tagMode)}
                icon={
                  tagMode ? (
                    <StarFilled className="mr-2" style={{ fontSize: 20 }} />
                  ) : (
                    <StarOutlined className="mr-2" style={{ fontSize: 20 }} />
                  )
                }
              />
            </Tooltip>
            <Col>{Localize("COMMON.ORDER_BY")}</Col>
            <Col span={15}>
              {orderLabel("name", Localize("PAGES.SEARCH.ORDER.NAME"))}
              {orderLabel("sector", Localize("PAGES.SEARCH.ORDER.SECTOR"))}
              {orderLabel("city", Localize("PAGES.SEARCH.ORDER.CITY"))}
            </Col>
            <div>
              <h2
                style={{ color: "white", fontWeight: "bold" }}
                className="p-0 m-0 searchPage__orders-button"
              >
                {Localize("PAGES.SEARCH.COMPANIES_FOUND")}:{" "}
                <span>
                  {resultSearch.companies && resultSearch.companies.totalResults
                    ? resultSearch.companies.totalResults
                    : 0}
                </span>
              </h2>
            </div>
          </Row>
          <div className="animation fadein-right slow">
            <CreateTagModal
              visible={tagAddModal}
              onCancel={() => {
                clearTagFields();
                setAddTagModal(false);
              }}
              onOk={() => {
                createTag();
                setAddTagModal(false);
              }}
              okDisabled={!tagValue || !colorValue || errorMessage}
              tagValue={tagValue}
              colorValue={colorValue}
              onTagChange={(e) => {
                const foundedTag = tags.find(
                  (tag) =>
                    tag.name.toLowerCase() === e.target.value.toLowerCase()
                );
                if (foundedTag) {
                  setErrorMessage(Localize("TAG.ERROR_MESSAGE"));
                } else {
                  setErrorMessage("");
                }
                setTagValue(e.target.value);
              }}
              onColorChange={(color) => setColorValue(color)}
              errorMessage={errorMessage}
            />
            {tagMode && (
              <TagOptions
                onAddTagClick={() => setAddTagModal(true)}
                tagList={tags}
                value={selectedTag}
                onTagChange={(value) => setSelectedTag(value)}
                onAssociateClick={associateTagCompany}
                associateDisabled={!selectedTag || selectedCompany.length === 0}
              />
            )}
          </div>
          <div>
            <CompanyList
              data={
                (resultSearch.companies && resultSearch.companies.data) || []
              }
              pageSize={PAGE_SIZE}
              total={
                resultSearch.companies && resultSearch.companies.totalResults
              }
              title={
                <>
                  {Localize("PAGES.COMPANIES.TITLE")} (
                  {resultSearch.companies &&
                    resultSearch.companies.totalResults}
                  )
                </>
              }
              filters={filters}
              tagMode={tagMode}
              setSelectedCompany={(id) => setSelectedCompany(id)}
              loadingTag={loadingTagsIdsCompany}
              associatedTagsIds={
                associatedTagsIdsCompany && associatedTagsIdsCompany
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CompanySearch;
