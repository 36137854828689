import React from "react";
import { Card, Col, Tag } from "antd";
import Localize from "../Localized/Localize.comp";
import "./Card.scss";
import { upperCase } from "lodash";

const CardBrands = (props) => {
  const { data } = props;

  return (
    <Card
      title={
        <h2>
          <b>{Localize("PAGES.CONTACTS.DETAIL.SECTIONS.BRANDS.TITLE")}</b>
        </h2>
      }
      bordered={false}
      className="companyPage__body-card companyPage__body-card--custom customCard"
    >
      <Col
        span={24}
        className="mb-3"
        style={{ paddingLeft: 0, paddingRight: 0 }}
      >
        {data.slice(0, 5).map((b) => (
          <Tag className="m-1" key={b._id}>
            {upperCase(b.name)}
          </Tag>
        ))}
        {data.length > 5 && (
          <Tag className="pointer">
            + {data.length - 5}{" "}
            {Localize("PAGES.COMPANIES.DETAIL.SECTIONS.MODAL.BRANDS")}
          </Tag>
        )}
      </Col>
    </Card>
  );
};

export default CardBrands;
