import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import FacebookFilled from "@ant-design/icons/es/icons/FacebookFilled";
import InstagramFilled from "@ant-design/icons/es/icons/InstagramFilled";
import YoutubeFilled from "@ant-design/icons/es/icons/YoutubeFilled";
import LinkOutlined from "@ant-design/icons/es/icons/LinkOutlined";
import TwitterOutlined from "@ant-design/icons/es/icons/TwitterOutlined";
import GoogleOutlined from "@ant-design/icons/es/icons/GoogleOutlined";

import { getCompanySocialMediaAction } from "../../actions/company.actions";
import SocialMedia from "../../components/SocialMedia/SocialMedia";
import { getUniqueList } from "../../libs/utils";
import Localize from "../../libs/localization";

const CompanySocialMedia = (props) => {
  const { companyId } = props;
  const dispatch = useDispatch();
  const [socialToDisplay, setSocialToDisplay] = useState([]);

  const socialMedia = useSelector((state) => state.company.socialMedia);
  const socialsDetail = [];

  useEffect(() => {
    dispatch(getCompanySocialMediaAction({ id: companyId }));
  }, []);

  const getData = (socialData) => {
    if (socialData && socialData.data?.social) {
      const { social } = socialData.data;
      const socialValuesArray = Object.values(social);

      _.forEach(socialValuesArray, (value, key) => {
        const keys = Object.keys(value);
        if (value.hasOwnProperty("FB_FANS"))
          socialsDetail.push({
            name: "Facebook",
            icon: <FacebookFilled />,
            socialKeys: ["FB_LIKES", "FB_FANS"],
          });
        if (value.hasOwnProperty("INSTA_FOLLOWERS"))
          socialsDetail.push({
            name: "Instagram",
            icon: <InstagramFilled />,
            socialKeys: [
              "INSTA_POSTS_LIKES",
              "INSTA_FOLLOWING",
              "INSTA_FOLLOWERS",
            ],
          });
        if (value.hasOwnProperty("PIN_FOLLOWERS"))
          socialsDetail.push({
            name: "Pinterest",
            icon: <LinkOutlined />,
            socialKeys: ["PIN_FOLLOWING", "PIN_FOLLOWERS"],
          });
        if (value.hasOwnProperty("TW_FOLLOWERS"))
          socialsDetail.push({
            name: "Twitter",
            icon: <TwitterOutlined />,
            socialKeys: [
              "TW_TWEETS",
              "TW_LIKES",
              "TW_FOLLOWING",
              "TW_FOLLOWERS",
            ],
          });
        if (value.hasOwnProperty("YOUT_FOLLOWERS"))
          socialsDetail.push({
            name: "YouTube",
            icon: <YoutubeFilled />,
            socialKeys: ["YOUT_VIEWS", "YOUT_FOLLOWERS"],
          });
        if (value.hasOwnProperty("GOOGLE_FOLLOWERS"))
          socialsDetail.push({
            name: "Google",
            icon: <GoogleOutlined />,
            socialKeys: ["GOOGLE_FOLLOWERS"],
          });
      });

      setSocialToDisplay(getUniqueList(socialsDetail, "name"));
    }
  };

  useEffect(() => {
    socialMedia && socialMedia.data && getData(socialMedia);
  }, [socialMedia]);

  return (
    <div>
      <Card
        className="companyPage__body-card companyPage__body-card--custom mb-3"
        title={
          <h2>
            <b>
              {Localize(
                "PAGES.COMPANIES.DETAIL.SECTIONS.SOCIAL_NETWORK.OVERVIEW"
              )}
            </b>
          </h2>
        }
      >
        {socialMedia && socialMedia.data && socialMedia.data.social && (
          <SocialMedia data={socialMedia.data.social} />
        )}
      </Card>
      {socialToDisplay.length > 0 &&
        socialToDisplay.map((s) => (
          <Card
            key={s.name}
            className="companyPage__body-card companyPage__body-card--custom "
            title={
              <h2>
                {s.icon}
                <b> {s.name}</b>
              </h2>
            }
          >
            {socialMedia && socialMedia.data && socialMedia.data.social && (
              <SocialMedia data={socialMedia.data.social} socialToDisplay={s} />
            )}
          </Card>
        ))}
    </div>
  );
};

export default CompanySocialMedia;
