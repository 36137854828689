import React from "react";
import { Row } from "antd";
import { ReactComponent as Square } from "../../assets/images/dot-purple.svg";

import "./bannerTop.scss";

const BannerTop = (props) => {
  const { children, borderTop = "", small, height } = props;

  return (
    <Row
      justify="space-between"
      className={`banner-top ${borderTop} ${small && "banner-top--small"}`}
      style={{ height }}
    >
      <Square className="square square--left" />
      {children}
      <Square className="square square--right" />
    </Row>
  );
};

export default BannerTop;
