import React from "react";
import moment from "moment";
import { Col, Collapse, Row, Tag } from "antd";
import { Link } from "react-router-dom";
import CheckOutlined from "@ant-design/icons/es/icons/CheckOutlined";
import CloseOutlined from "@ant-design/icons/es/icons/CloseOutlined";
import Label from "../Label/Label";
import Localize from "../../libs/localization";
import { get } from "../../libs/helpers/ioc";
import { upperCase } from "lodash";

const { Panel } = Collapse;

const Pitch = (props) => {
  const { item, onMoreBrandClick, isPitchPage = false } = props;
  const permissions = get("scopes");

  return (
    <Collapse ghost expandIconPosition="right">
      <Panel
        header={
          isPitchPage ? (
            <>
              <Row align="middle">
                {moment(item.start_date).format("DD/MM/YYYY")} {` | `}{" "}
                {isPitchPage && item.company && (
                  <p className="ml-1 my-0 p-0">
                    {upperCase(item.company.name)}
                  </p>
                )}
              </Row>
              <Row>
                <b className="customCard__pitchList-title-no-margin">
                  {item.title}
                </b>
                {item.category &&
                  item.category.map((c) => (
                    <Tag key={c._id} className="ml-3 mr.0">
                      {c.key}
                    </Tag>
                  ))}
              </Row>
            </>
          ) : (
            <Row align="middle">
              {moment(item.start_date).format("DD/MM/YYYY")} {` | `}{" "}
              <b className="customCard__pitchList-title mr-2">{item.title}</b>
              {item.category &&
                item.category.map((c) => <Tag key={c._id}>{c.key}</Tag>)}
            </Row>
          )
        }
        key="1"
      >
        {onMoreBrandClick && item.brands.length > 0 && (
          <Col span={24} className="mb-3 p-0 align-content-center">
            <Row align="middle">
              <Label
                value={Localize("PAGES.COMPANIES.DETAIL.SECTIONS.MODAL.BRANDS")}
                className="my-0 mr-1 p-0"
              />
              <span className="mx-1">:</span>
              {item.brands.slice(0, 5).map((b) => (
                <Tag key={b.id}>{upperCase(b.name)}</Tag>
              ))}
              {item.brands.length > 5 && (
                <Tag
                  className="pointer"
                  onClick={() =>
                    onMoreBrandClick(
                      <Label
                        value={Localize(
                          "PAGES.COMPANIES.DETAIL.SECTIONS.MODAL.BRANDS"
                        )}
                      />,
                      <ul>
                        {item.brands.map((b) => (
                          <li>{upperCase(b.name)}</li>
                        ))}
                      </ul>
                    )
                  }
                >
                  + {item.brands.length - 5}{" "}
                </Tag>
              )}
            </Row>
          </Col>
        )}
        <div>
          {item.winnerCompany && (
            <p>
              <b>{Localize("PAGES.COMPANIES.DETAIL.SECTIONS.MODAL.WINNER")}</b>
              <span className="mx-1">:</span>
              <Link
                style={{
                  pointerEvents: Object.keys(permissions)?.includes("company")
                    ? ""
                    : "none",
                }}
                to={`/companies/${item.winnerCompany.id}`}
              >
                {upperCase(item.winnerCompany.name)}
              </Link>
            </p>
          )}
          {item.formerWinner && (
            <p>
              <b>
                {Localize(
                  "PAGES.COMPANIES.DETAIL.SECTIONS.MODAL.FORMER_WINNER"
                )}
              </b>
              <span className="mx-1">:</span>
              <Link
                style={{
                  pointerEvents: Object.keys(permissions)?.includes("company")
                    ? ""
                    : "none",
                }}
                to={`/companies/${item.formerWinner.id}`}
              >
                {upperCase(item.formerWinner.name)}
              </Link>
            </p>
          )}
          {item.comment && <p>{item.comment}</p>}
          <p>
            <b>
              {Localize("PAGES.COMPANIES.DETAIL.SECTIONS.MODAL.COMPETITION")}
            </b>
            <span className="mx-1">:</span>
            {item.competition ? (
              <CheckOutlined
                style={{
                  color: "#1b6c6e",
                  fontSize: "1rem",
                  marginLeft: "10px",
                }}
              />
            ) : (
              <CloseOutlined
                style={{
                  color: "red",
                  fontSize: "1rem",
                  marginLeft: "10px",
                }}
              />
            )}
          </p>
        </div>
      </Panel>
    </Collapse>
  );
};

export default Pitch;
