import React, { useEffect, useState } from "react";
import ContactList from "../../components/List/Contact.list";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanyBrandsAction,
  getCompanyContactsAction,
} from "../../actions/company.actions";
import { searchBrandsAction } from "../../actions/search.action";
import { Alert, Button, Col, Row, Spin } from "antd";
import UpOutlined from "@ant-design/icons/es/icons/UpOutlined";
import DownOutlined from "@ant-design/icons/es/icons/DownOutlined";
import Localize from "../../components/Localized/Localize.comp";
import { getFunctionAction } from "../../actions/registry/registry.function.actions";
import { groupBy, upperCase } from "lodash";
import queryString from "query-string";
import BrandList from "../../components/List/Brand.list";
import { getCompanyBrandsApi } from "../../api/company.api";

const CompanyBrands = (props) => {
  const { companyId, disableDetail } = props;

  const brands = useSelector((state) => state.company.brands);
  const loading = useSelector((state) => state.company.loading);
  const dispatch = useDispatch();
  const [mainFunct, setMainFunct] = useState(" ");
  const [functionList, setFunctionList] = useState([]);

  const functions = useSelector((state) => state.functionReg.data);
  const functionsLoading = useSelector((state) => state.functionReg.loading);

  useEffect(() => {
    searchRequest();
  }, []);

  useEffect(() => {
    if (functions.length == 0) {
      dispatch(getFunctionAction());
    }
  }, []);

  const handleOrder = (val) => {
    setMainFunct(val);
  };

  const searchRequest = () => {
    dispatch(getCompanyBrandsAction({ id: companyId }));
  };

  const filterLabel = (value) => {
    return (
      <Button
        type="link"
        className={`uppercase ${mainFunct == value ? "selected" : ""}`}
        onClick={() => handleOrder(value)}
        a
      >
        {value.length == 1 ? Localize("COMMON.ALL") : value}
      </Button>
    );
  };

  const parent = groupBy(functions.enum, "parent");

  return (
    <>
      {brands && brands.data && (
        <div className="animation fadein-right slow">
          <BrandList
            data={brands.data}
            disablePagination={true}
            disableDetail={disableDetail}
          />
        </div>
      )}
    </>
  );
};

export default CompanyBrands;
