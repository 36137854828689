import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row, Tooltip } from "antd";
import { groupBy } from "lodash";
import StarOutlined from "@ant-design/icons/es/icons/StarOutlined";
import StarFilled from "@ant-design/icons/es/icons/StarFilled";
import Localize from "../../components/Localized/Localize.comp";
import { getFunctionAction } from "../../actions/registry/registry.function.actions";
import { searchContactsAction } from "../../actions/search.action";
import ContactList from "../../components/List/Contact.list";
import TagOptions from "../../components/Tag/TagOptions";
import CreateTagModal from "../../components/CreateTagModal/CreateTagModal";
import {
  associateTagAction,
  associationsTagIdContactAction,
  createTagAction,
  getTagAction,
} from "../../actions/tag.actions";

const CompanyContacts = (props) => {
  const { companyId } = props;
  const dispatch = useDispatch();

  const [mainFunct, setMainFunct] = useState(null);

  // TAG
  const [tagMode, setTagMode] = useState(false);
  const [tagAddModal, setAddTagModal] = useState(false);
  const [tagValue, setTagValue] = useState("");
  const [colorValue, setColorValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedContact, setSelectedContact] = useState("");

  const contacts = useSelector((state) => state.search.results.data);
  const functions = useSelector((state) => state.functionReg.data);
  const tags = useSelector((state) => state.tag.tagsList);

  const ids = [];

  const loadingTagsIdsContact = useSelector(
    (state) => state.tag.tagsForIdsContact.loading
  );
  const associatedTagsIdsContact = useSelector(
    (state) => state.tag.tagsForIdsContact.data
  );

  useEffect(() => {
    searchRequest();
  }, []);

  useEffect(() => {
    if (functions.length === 0) {
      dispatch(getFunctionAction());
    }
  }, []);

  useEffect(() => {
    dispatch(getTagAction());
  }, [tagAddModal]);

  const handleOrder = (val) => {
    setMainFunct(val);
  };

  const searchRequest = () => {
    const params = {
      company_filters: {
        id: companyId,
      },
      pageNum: 1,
      limit: 20,
    };

    if (mainFunct) {
      params.function_parent = mainFunct;
    }

    dispatch(
      searchContactsAction({
        data: params,
      })
    );
  };

  const getContactPageIds = () => {
    if (contacts.contacts && contacts.contacts.data) {
      contacts.contacts.data.forEach((contact) => {
        ids.push(contact._id);
      });
      dispatch(associationsTagIdContactAction({ data: { id: ids } }));
    }
  };

  useEffect(() => {
    getContactPageIds();
  }, [contacts.contacts.data, tagMode]);

  useEffect(() => {
    searchRequest();
  }, [mainFunct]);

  const clearTagFields = () => {
    setTagValue("");
    setColorValue("");
    setErrorMessage("");
  };

  const createTag = () => {
    const params = {
      name: tagValue,
      color: colorValue.hex.replace("#", "").toUpperCase(),
    };

    dispatch(createTagAction({ data: params }));
    clearTagFields();
  };

  const associateTagContact = () => {
    const dataParams = {};
    let id = "";

    tags.forEach((tag) => {
      if (tag.name === selectedTag) {
        id = tag._id;
      }
    });

    dataParams.name = selectedTag;
    dataParams.contacts = selectedContact;
    dispatch(associateTagAction({ id, data: dataParams }));
    setTagMode(false);
    setSelectedTag("");
  };

  const filterLabel = (value) => {
    return (
      <Button
        type="link"
        className={`uppercase ${mainFunct === value ? "selected" : ""}`}
        onClick={() => handleOrder(value)}
      >
        {!value ? Localize("COMMON.ALL") : value}
      </Button>
    );
  };

  const parent = groupBy(functions.enum, "parent");

  return (
    <>
      {contacts && contacts.contacts.data && (
        <div className="animation fadein-right slow">
          <Row gutter={0} align="middle" className="searchPage__lineFilters">
            <Tooltip title="Associa Tag">
              <Button
                type="primary"
                shape="circle"
                onClick={() => setTagMode(!tagMode)}
                icon={
                  tagMode ? (
                    <StarFilled className="mr-2" style={{ fontSize: 20 }} />
                  ) : (
                    <StarOutlined className="mr-2" style={{ fontSize: 20 }} />
                  )
                }
              />
            </Tooltip>
            <Col>
              {filterLabel(null)}
              {Object.keys(parent).map((val) => filterLabel(val))}
            </Col>
            <CreateTagModal
              visible={tagAddModal}
              onCancel={() => {
                clearTagFields();
                setAddTagModal(false);
              }}
              onOk={() => {
                createTag();
                setAddTagModal(false);
              }}
              okDisabled={!tagValue || !colorValue || errorMessage}
              tagValue={tagValue}
              colorValue={colorValue}
              onTagChange={(e) => {
                const foundedTag = tags.find(
                  (tag) =>
                    tag.name.toLowerCase() === e.target.value.toLowerCase()
                );
                if (foundedTag) {
                  setErrorMessage(Localize("TAG.ERROR_MESSAGE"));
                } else {
                  setErrorMessage("");
                }
                setTagValue(e.target.value);
              }}
              onColorChange={(color) => setColorValue(color)}
              errorMessage={errorMessage}
            />
          </Row>
          {tagMode && (
            <TagOptions
              onAddTagClick={() => setAddTagModal(true)}
              tagList={tags}
              value={selectedTag}
              onTagChange={(value) => setSelectedTag(value)}
              onAssociateClick={associateTagContact}
              associateDisabled={!selectedTag || selectedContact.length === 0}
            />
          )}
          <ContactList
            data={contacts.contacts.data}
            pageSize={20}
            total={contacts.contacts.totalResults}
            tagMode={tagMode}
            setSelectedContact={(id) => setSelectedContact(id)}
            filters={{
              company_filters: {
                id: companyId,
              },
            }}
            loadingTag={loadingTagsIdsContact}
            associatedTagsIds={
              associatedTagsIdsContact && associatedTagsIdsContact
            }
          />
        </div>
      )}
    </>
  );
};

export default CompanyContacts;
