import React, { useState } from "react";
import { AutoComplete, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import SearchOutlined from "@ant-design/icons/es/icons/SearchOutlined";
import { upperCase } from "lodash";
import { Link } from "react-router-dom";
import { goTo, userLogout } from "../../../actions";
import { searchAutocompleteApi } from "../../../api/common.api";
import { headersBuilder } from "../../../libs/helpers/callApi";
import { ReactComponent as EmptyIcon } from "../../../components/DetailPane/empty.svg";
import Localize from "../../../components/Localized/Localize.comp";
import ContactModal from "../../contact/contact.modal";
import BrandModal from "../../brand/brand.modal";

const { Search } = Input;

const SearchBar = () => {
  const token = useSelector((state) => state.auth.token);

  const [options, setOptions] = useState([]);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showBrandModal, setShowBrandModal] = useState(false);
  const [contactId, setContactId] = useState(false);
  const [brandId, setBrandId] = useState(false);
  const [result, setResult] = useState([]);

  const dispatch = useDispatch();

  const onSelect = (value, opt) => {
    if (opt.callback && typeof opt.callback === "function") {
      opt.callback();
    }
  };

  const onSearch = async (value) => {
    if (!value) {
      return;
    }
    if (value.length >= 3) {
      await searchAutocompleteApi({
        headers: {
          ...headersBuilder(),
          Authorization: `Bearer ${token}`,
        },
        key: value,
      })
        .then((r) => setResult(r))
        .catch((e) => dispatch(userLogout()));

      if (result.data) {
        setOptions([]);
        const { data } = result;
        const options = Object.keys(data).map((k) => {
          if (data[k]?.totalResults > 0) {
            return {
              label: (
                <span className="fast-search__dropdown-title">
                  {Localize(`PAGES.SEARCH.${upperCase(k)}`)}
                </span>
              ),
              options: renderItem(k, data[k], data[k].totalResults, value),
            };
          }
        });
        setOptions(options.filter((o) => o !== undefined));
      }
    } else {
      setOptions([]);
    }
  };

  const onSearchClick = (value) => {
    if (value.length >= 3) {
      dispatch(goTo(`/searchPage?key=${value}`));
    }
  };

  const renderItem = (key, data, total, value) => {
    console.log(key, "key");

    if (!token) {
      return;
    }
    const dataMap = data.data.map((item) => {
      let label = "";
      let callback = null;

      switch (key) {
        case "companies":
          label = `${item.name}`;
          callback = () => dispatch(goTo(`/${key}/${item._id}`));
          break;
        case "contacts":
          label = `${item.surname} ${item.name}`;
          callback = () => {
            setContactId(item._id);
            setShowContactModal(true);
          };
          break;
        case "brands":
          label = item.name;
          callback = () => {
            setBrandId(item._id);
            setShowBrandModal(true);
          };
          break;
      }

      return {
        value: label,
        callback,
        label,
      };
    });
    if (total - data.data.length > 0) {
      dataMap.push({
        value: null,
        callback: null,
        label: (
          <Link
            to={`/${key === "brands" ? "searchPage" : key}?key=${value}`}
            className="fast-search__dropdown--other"
          >
            {`+ ${total - data.data.length} `} {Localize("COMMON.RESULTS")}
          </Link>
        ),
      });
    }

    return dataMap;
  };

  return (
    <>
      <AutoComplete
        dropdownClassName="fast-search__dropdown"
        dropdownMatchSelectWidth={false}
        style={{ width: 300, marginLeft: "20px" }}
        options={options}
        height={500}
        onSelect={onSelect}
        onSearch={onSearch}
        notFoundContent={
          <div style={{ textAlign: "center", opacity: 0.8 }}>
            <EmptyIcon />
            <p style={{ opacity: 0.6 }}>{Localize("COMMON.NO_DATA")}</p>
          </div>
        }
      >
        <Search
          className="siteLayout__header-search"
          // placeholder={Localize("COMMON.SEARCH")}
          allowClear
          onSearch={onSearchClick}
          enterButton={
            <>
              <SearchOutlined /> {Localize("COMMON.SEARCH")}
            </>
          }
        />
      </AutoComplete>

      {contactId && (
        <ContactModal
          id={contactId}
          visible={showContactModal}
          close={() => setShowContactModal(false)}
        />
      )}
      {brandId && (
        <BrandModal
          id={brandId}
          visible={showBrandModal}
          close={() => {
            setShowBrandModal(false);
          }}
        />
      )}
    </>
  );
};

export default SearchBar;
