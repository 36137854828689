import React, { useEffect, useState } from "react";
import _ from "lodash";
import { ResponsiveLine } from "@nivo/line";
import { Col, Row } from "antd";
import Localize from "../../libs/localization";

const FinancialGraph = ({ data }) => {
  const [dataSet, setDataSet] = useState();
  const [employeesDataSet, setEmployeesDataSet] = useState();

  useEffect(() => {
    let revenueData = [];
    let incomeData = [];
    let employeesData = [];
    data &&
      data.forEach((element) => {
        revenueData.push({
          x: element.year,
          y: element.revenue ? element.revenue : 0,
        });
        incomeData.push({
          x: element.year,
          y: element.operatingResult ? element.operatingResult : 0,
        });
        employeesData.push({
          x: element.year,
          y: element.employees ? element.employees : 0,
        });
      });
    const completeArray = [
      {
        id: Localize("COMMON.REVENUE"),
        data: revenueData,
      },
      {
        id: Localize("COMMON.INCOME"),
        data: incomeData,
      },
    ];
    setDataSet(completeArray);
    setEmployeesDataSet([
      { id: Localize("COMMON.EMPLOYEES"), data: employeesData },
    ]);
  }, [data]);

  return (
    <>
      <Row style={{ marginTop: 24 }}>
        <Col>
          <h3 style={{ fontWeight: "bold" }}>
            {Localize("COMMON.REVENUE")} {Localize("COMMON.AND")}{" "}
            {Localize("COMMON.INCOME")}
          </h3>
        </Col>
      </Row>
      <Row style={{ height: "300px", width: "100%" }}>
        {dataSet && (
          <ResponsiveLine
            data={dataSet}
            margin={{ top: 10, right: 180, bottom: 50, left: 50 }}
            xScale={{ type: "point" }}
            yScale={{ type: "linear", min: "auto", max: "auto" }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: "bottom",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: Localize("COMMON.YEAR"),
              legendOffset: 36,
              legendPosition: "middle",
            }}
            axisLeft={{
              orient: "left",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: "middle",
              format: (e) => Math.floor(e) === e && e,
            }}
            useMesh={true}
            colors={{ scheme: "spectral" }}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            legends={[
              {
                anchor: "top-right",
                direction: "column",
                itemWidth: 10,
                itemHeight: 20,
                itemTextColor: "#999",
                symbolSize: 18,
                symbolShape: "circle",
                translateX: 30,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#000",
                    },
                  },
                ],
              },
            ]}
          />
        )}
      </Row>
      <Row style={{ marginTop: 24 }}>
        <Col>
          <h3 style={{ fontWeight: "bold" }}>{Localize("COMMON.EMPLOYEES")}</h3>
        </Col>
      </Row>
      <Row style={{ height: "300px", width: "100%" }}>
        {employeesDataSet && (
          <ResponsiveLine
            data={employeesDataSet}
            margin={{ top: 10, right: 180, bottom: 50, left: 50 }}
            xScale={{ type: "point" }}
            yScale={{ type: "linear" }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: "bottom",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: Localize("COMMON.YEAR"),
              legendOffset: 36,
              legendPosition: "middle",
            }}
            axisLeft={{
              orient: "left",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: "middle",
              format: (e) => Math.floor(e) === e && e,
            }}
            useMesh={true}
            colors={{ scheme: "spectral" }}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            legends={[
              {
                anchor: "top-right",
                direction: "column",
                itemWidth: 10,
                itemHeight: 20,
                itemTextColor: "#999",
                symbolSize: 18,
                symbolShape: "circle",
                translateX: 30,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#000",
                    },
                  },
                ],
              },
            ]}
          />
        )}
      </Row>
    </>
  );
};

export default FinancialGraph;
