import React from "react";
import { Select, Card, Row, Button, Col, Popconfirm } from "antd";
import Localize from "../../libs/localization";

const { Option } = Select;

const SearchManagement = ({
  searchList,
  onSearchElementChange,
  onDeleteSearch,
  value,
}) => {
  return (
    <Row align="bottom" className={!value ? "mr-4" : "mr-5"}>
      {value && (
        <Col span={8}>
          <Popconfirm
            title={Localize("CONFIRM.DELETE")}
            onConfirm={onDeleteSearch}
            okText={Localize("COMMON.YES")}
            cancelText={Localize("COMMON.NO")}
          >
            <Button type="link" className="secondary">
              {Localize("PAGES.SEARCH.DELETE_SEARCH")}
            </Button>
          </Popconfirm>
        </Col>
      )}
      <Col span={8}>
        <Select
          value={value}
          allowClear
          style={{ width: "300px" }}
          onChange={(id) =>
            onSearchElementChange(
              id,
              id
                ? searchList[
                    searchList.findIndex((search) => search._id === id)
                  ].data
                : null
            )
          }
          onSelect={(id) =>
            onSearchElementChange(
              id,
              id
                ? searchList[
                    searchList.findIndex((search) => search._id === id)
                  ].data
                : null
            )
          }
          placeholder={Localize("PAGES.SEARCH.SELECT_SEARCH")}
        >
          {searchList &&
            searchList.map((search) => {
              return (
                <Option value={search._id} label={search.name} key={search._id}>
                  {search.name}
                </Option>
              );
            })}
        </Select>
      </Col>
    </Row>
  );
};

export default SearchManagement;
