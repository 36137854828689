import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";
import BannerTop from "../../components/BannerTop/BannerTop";
import Tabs from "../../components/Tabs/Tabs";
import CompanyList from "../../components/List/Company.list";
import ContactList from "../../components/List/Contact.list";
import TagOptions from "../../components/Tag/TagOptions";
import {
  associationsTagIdCompanyAction,
  associationsTagIdContactAction,
  deleteTagAction,
  exportTagContactsAction,
  getTagAction,
  getTagCompanyDetailAction,
  getTagContactDetailAction,
  resetTagDetailAction,
} from "../../actions/tag.actions";
import "./tag.style.scss";
import Localize from "../../libs/localization";
import { getFromLocalStorage } from "../../libs/helpers/localstorage";
import { get } from "../../libs/helpers/ioc";

const PAGE_SIZE = 100;
const { confirm } = Modal;
const tenant = getFromLocalStorage("lefacWebTenant")?.data;

const TagPage = ({ location, user }) => {
  const dispatch = useDispatch();

  const permissions = get("scopes");

  const selectedTagWidget = location.data; // route param
  const tags = useSelector((state) => state.tag.tagsList);

  const [selectedTag, setSelectedTag] = useState(
    selectedTagWidget && selectedTagWidget.name
  );

  const companiesDetail = useSelector((state) => state.tag.companiesTagDetail);
  const contactsDetail = useSelector((state) => state.tag.contactsTagDetail);

  const companiesDetailData = useSelector(
    (state) => state.tag.companiesTagDetailData
  );
  const contactsDetailData = useSelector(
    (state) => state.tag.contactsTagDetailData
  );

  // Associated tag - id COMPANY
  const loadingTagsIdsCompany = useSelector(
    (state) => state.tag.tagsForIdsCompany.loading
  );
  const associatedTagsIdsCompany = useSelector(
    (state) => state.tag.tagsForIdsCompany.data
  );

  // Associated tag - id CONTACT
  const loadingTagsIdsContact = useSelector(
    (state) => state.tag.tagsForIdsContact.loading
  );
  const associatedTagsIdsContact = useSelector(
    (state) => state.tag.tagsForIdsContact.data
  );

  const companyIds = [];
  const contactIds = [];

  useEffect(() => {
    if (tags.length === 0) {
      dispatch(getTagAction());
    }
  }, []);

  const [contactTagPage, setContactTagPage] = useState(1);
  const [companyTagPage, setCompanyTagPage] = useState(1);

  const searchRequest = () => {
    if (selectedTag) {
      let id = "";

      tags &&
        tags.forEach((tag) => {
          if (tag.name === selectedTag) {
            id = tag._id;
          }
        });

      if (id) {
        dispatch(
          getTagCompanyDetailAction({
            id,
            pageNum: companyTagPage,
          })
        );
        dispatch(
          getTagContactDetailAction({
            id,
            pageNum: contactTagPage,
          })
        );
      }
    } else {
      setSelectedTag("");
      dispatch(resetTagDetailAction());
    }
  };

  const setContactPage = (val) => {
    if (contactsDetailData && contactsDetailData.totalPages) {
      if (contactsDetailData.totalPages === 1) {
        return;
      }
      setContactTagPage(val);
    }
  };

  const setCompanyPage = (val) => {
    if (companiesDetailData && companiesDetailData.totalPages) {
      if (companiesDetailData.totalPages === 1) {
        return;
      }
      setCompanyTagPage(val);
    }
  };

  useEffect(() => {
    searchRequest();
  }, [contactTagPage]);

  useEffect(() => {
    searchRequest();
  }, [selectedTag]);

  const deleteTag = () => {
    let id = "";

    tags &&
      tags.forEach((tag) => {
        if (tag.name === selectedTag) {
          id = tag._id;
        }
      });

    return confirm({
      title: Localize("TAG.CONFIRM_DELETE_TITLE"),
      content: Localize("TAG.CONFIRM_DELETE_DESC"),
      cancelText: Localize("COMMON.CANCEL"),
      onOk() {
        dispatch(deleteTagAction({ id }));
        setSelectedTag("");
      },
    });
  };

  const getCompanyPageIds = () => {
    if (companiesDetail) {
      companiesDetail.forEach((company) => {
        companyIds.push(company._id);
      });
      dispatch(associationsTagIdCompanyAction({ data: { id: companyIds } }));
    }
  };

  const getContactPageIds = () => {
    if (contactsDetail) {
      contactsDetail.forEach((contact) => {
        contactIds.push(contact._id);
      });
      dispatch(associationsTagIdContactAction({ data: { id: contactIds } }));
    }
  };

  const exportContacts = () => {
    const id = tags.find((tag) => tag.name === selectedTag)._id;
    dispatch(exportTagContactsAction({ id }));
  };

  useEffect(() => {
    getCompanyPageIds();
  }, [companiesDetail]);

  useEffect(() => {
    getContactPageIds();
  }, [contactsDetail]);

  const renderContacts = () => {
    if (Object.keys(permissions)?.includes("contact")) {
      return (
        <ContactList
          data={contactsDetail || []}
          title={
            <>
              {Localize("PAGES.CONTACTS.TITLE")} (
              {(contactsDetailData && contactsDetailData.totalResults) || 0})
            </>
          }
          total={contactsDetailData && contactsDetailData.totalResults}
          pageSize={PAGE_SIZE}
          isTagPage
          loadingTag={loadingTagsIdsContact}
          associatedTagsIds={
            associatedTagsIdsContact && associatedTagsIdsContact
          }
          setContactTagPage={(val) => setContactPage(val)}
        />
      );
    }
  };

  const renderCompanies = () => {
    if (Object.keys(permissions)?.includes("company")) {
      return (
        <CompanyList
          data={(companiesDetail && companiesDetail) || []}
          title={
            <>
              {Localize("PAGES.COMPANIES.TITLE")} (
              {(companiesDetailData && companiesDetailData.totalResults) || 0})
            </>
          }
          total={companiesDetailData && companiesDetailData.totalResults}
          pageSize={PAGE_SIZE}
          isTagPage
          loadingTag={loadingTagsIdsCompany}
          associatedTagsIds={
            associatedTagsIdsCompany && associatedTagsIdsCompany
          }
          setCompanyTagPage={(val) => setCompanyPage(val)}
        />
      );
    }
  };

  return (
    <>
      <BannerTop small>
        <h1 className="mt-4">{Localize("PAGES.TAG.TITLE")}</h1>
      </BannerTop>
      <div className="searchPage__filters animation fadein-up slow">
        <TagOptions
          isForm
          value={selectedTag}
          tagList={tags}
          onTagChange={(value) => setSelectedTag(value)}
          onTagSelect={searchRequest}
          onDeleteClick={deleteTag}
          deleteDisabled={!selectedTag}
          showExport={
            selectedTag &&
            contactsDetailData?.totalResults > 0 &&
            (user.group === "SUPER-ADMIN" ||
              user.group === "ADMIN" ||
              user.scopes[tenant]?.includes("p_contactsExport:any"))
          }
          onExportContacts={exportContacts}
        />
      </div>
      <Tabs
        containerClass="tagPage__tabs"
        options={{
          onTabClick: () => {
            window.scrollTo({
              behavior: "smooth",
              left: 0,
              top: 0,
            });
          },
          animated: true,
        }}
      >
        {renderCompanies()}
        {renderContacts()}
      </Tabs>
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

export default connect(mapStateToProps, {})(TagPage);
