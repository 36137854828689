import { reducerFromMap } from "../libs/helpers/reducers";
import {
  getPaginatedProps,
  getDetailAndSchemaProps,
} from "../libs/helpers/callApi";

import {
  COMPANY_LIST_LOADING,
  COMPANY_LIST_LOADED,
  COMPANY_LIST_LOAD_ERROR,
  COMPANY_DETAIL_LOADING,
  COMPANY_DETAIL_LOADED,
  COMPANY_DETAIL_LOAD_ERROR,
  COMPANY_CONTACTS_LOADING,
  COMPANY_CONTACTS_LOADED,
  COMPANY_CONTACTS_LOAD_ERROR,
  COMPANY_BRANDS_LOADING,
  COMPANY_BRANDS_LOADED,
  COMPANY_BRANDS_LOAD_ERROR,
  COMPANY_NIELSEN_LOADING,
  COMPANY_NIELSEN_LOADED,
  COMPANY_NIELSEN_LOAD_ERROR,
  COMPANY_PITCH_LOADING,
  COMPANY_PITCH_LOAD_ERROR,
  COMPANY_PITCH_LOADED,
  COMPANY_CREA_LOADING,
  COMPANY_CREA_LOADED,
  COMPANY_CREA_LOAD_ERROR,
  SOCIAL_MEDIA_LOADING,
  SOCIAL_MEDIA_LOADED,
  SOCIAL_MEDIA_LOAD_ERROR,
} from "../actions/company.actions";

const reducer = {
  /*
   * COMPANY_LIST
   */
  [COMPANY_LIST_LOADING]: (state) => ({
    ...state,
    list: { ...state.list, loading: true },
  }),
  [COMPANY_LIST_LOADED]: (state, { data }) => ({
    ...state,
    list: {
      loading: false,
      ...getPaginatedProps(data),
    },
  }),
  [COMPANY_LIST_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    list: { ...state.list, loading: false },
    error: data,
  }),
  /*
   * COMPANY_DETAIL
   */
  [COMPANY_DETAIL_LOADING]: (state) => ({
    ...state,
    detail: {
      // ...state.detail,
      loading: true,
    },
  }),
  [COMPANY_DETAIL_LOADED]: (state, { data }) => ({
    ...state,
    detail: {
      loading: false,
      ...getDetailAndSchemaProps(data),
    },
  }),
  [COMPANY_DETAIL_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    detail: { loading: false },
    error: data,
  }),
  /*
   * COMPANY_CONTACTS
   */
  [COMPANY_CONTACTS_LOADING]: (state) => ({
    ...state,
    contacts: { loading: true },
  }),
  [COMPANY_CONTACTS_LOADED]: (state, { data }) => ({
    ...state,
    contacts: {
      ...state.contacts,
      loading: false,
      ...getPaginatedProps(data),
    },
  }),
  [COMPANY_CONTACTS_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    contacts: { loading: false },
    error: data,
  }),
  /*
   * COMPANY_BRANDS
   */
  [COMPANY_BRANDS_LOADING]: (state) => ({
    ...state,
    brands: { loading: true },
  }),
  [COMPANY_BRANDS_LOADED]: (state, { data }) => ({
    ...state,
    brands: {
      ...state.brands,
      loading: false,
      ...getPaginatedProps(data),
    },
  }),
  [COMPANY_BRANDS_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    brands: { loading: false },
    error: data,
  }),
  /*
   * COMPANY_NIELSEN
   */
  [COMPANY_NIELSEN_LOADING]: (state) => ({
    ...state,
    nielsen: { loading: true },
  }),
  [COMPANY_NIELSEN_LOADED]: (state, { data }) => ({
    ...state,
    nielsen: {
      ...state.nielsen,
      loading: false,
      ...getPaginatedProps(data),
    },
  }),
  [COMPANY_NIELSEN_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    nielsen: { loading: false },
    error: data,
  }),
  /*
   * COMPANY_PITCHS
   */
  [COMPANY_PITCH_LOADING]: (state) => ({
    ...state,
    pitch: { loading: true },
  }),
  [COMPANY_PITCH_LOADED]: (state, { data }) => ({
    ...state,
    pitch: {
      ...state.pitch,
      loading: false,
      ...getPaginatedProps(data),
    },
  }),
  [COMPANY_PITCH_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    pitch: { loading: false },
    error: data,
  }),
  /*
   * COMPANY_CREAS
   */
  [COMPANY_CREA_LOADING]: (state) => ({
    ...state,
    crea: { loading: true },
  }),
  [COMPANY_CREA_LOADED]: (state, { data }) => ({
    ...state,
    crea: {
      ...state.crea,
      loading: false,
      ...getPaginatedProps(data),
    },
  }),
  [COMPANY_CREA_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    crea: { loading: false },
    error: data,
  }),
  /*
   * COMPANY_SOCIAL_MEDIA
   */
  [SOCIAL_MEDIA_LOADING]: (state) => ({
    ...state,
    socialMedia: { loading: true },
  }),
  [SOCIAL_MEDIA_LOADED]: (state, { data }) => ({
    ...state,
    socialMedia: {
      ...state.socialMedia,
      loading: false,
      data: data.data,
    },
  }),
  [SOCIAL_MEDIA_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
};

export default reducerFromMap(reducer, { list: {}, detail: {} });
