import React, { useEffect, useState } from "react";
import { List, Row, Tag, Col, Button, Spin, Checkbox } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import PictureOutlined from "@ant-design/icons/es/icons/PictureOutlined";
import queryString from "query-string";
import StarOutlined from "@ant-design/icons/es/icons/StarOutlined";
import StarFilled from "@ant-design/icons/es/icons/StarFilled";
import { searchCompaniesAction } from "../../actions/search.action";
import { getGmapStringAddress } from "../../libs/utils";
import Localize from "../../libs/localization";
import { deleteAssociatedIdTagAction } from "../../actions/tag.actions";
import { upperCase } from "lodash";

const CompanyList = (props) => {
  const {
    data: companyList,
    pageSize,
    title = "",
    onRowClick,
    total,
    filters = "",
    tagMode,
    setSelectedCompany,
    isTagPage = false,
    loadingTag,
    associatedTagsIds,
    setCompanyTagPage,
  } = props;

  const [pSize, setPSize] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(1);
  const [companyIds, setCompanyIds] = useState([]);
  const [isItemSelected, setIsItemSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const fetchData = (page, pageSize) => {
    if (!isTagPage) {
      if (pSize != pageSize) {
        setPSize(pageSize);
      }
      const query = queryString.parse(location.search);
      let params = "";

      if (filters) {
        params = {
          ...filters,
          pageNum: page,
          limit: pageSize,
        };
      }

      if (query.key && !params.name) {
        params = {
          name: query.key,
          pageNum: page,
          limit: pageSize,
        };
      }

      dispatch(
        searchCompaniesAction({
          data: params,
        })
      );
    }
  };

  useEffect(() => {
    fetchData(1, pageSize);
  }, [location.key]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters]);

  useEffect(() => {
    setIsItemSelected([]);
    setSelectedCompany && setSelectedCompany([]);
    setCompanyIds([]);
    setSelectAll(false);
  }, [tagMode]);

  useEffect(() => {
    if (tagMode) {
      setIsItemSelected([]);
      setSelectedCompany && setSelectedCompany([]);
      setCompanyIds([]);
    }
  }, [tagMode]);

  const onStarClick = (item) => {
    let idsToSend = [];
    const founded = isItemSelected.find(
      (itemSelected) => itemSelected.id === item._id
    );

    if (founded) {
      const itemFoundedArr = [...isItemSelected];
      const filtered = itemFoundedArr.filter((itemF) => itemF !== founded);
      setIsItemSelected(filtered);
      idsToSend = filtered.map((el) => el.id);
      setSelectedCompany(idsToSend);
    } else {
      setIsItemSelected([...isItemSelected, { id: item._id }]);
      companyIds.push(item._id);
      setSelectedCompany(companyIds);
    }
  };

  const renderTag = (item) => {
    let renderedTags = [];
    associatedTagsIds &&
      associatedTagsIds.map((association) => {
        const founded = association._id === item._id;
        if (founded) {
          renderedTags = association.tags.map((tag) => (
            <Tag
              key={tag.tagId}
              color={`#${tag.color}`}
              className="searchPage__item-tag"
              closable
              onClose={() =>
                dispatch(
                  deleteAssociatedIdTagAction({
                    tagId: tag.tagId,
                    associatedId: association._id,
                  })
                )
              }
            >
              {tag.name}
            </Tag>
          ));
        }
      });
    return renderedTags;
  };

  const selectAllCompanies = (e) => {
    const ids = [];
    if (e) {
      companyList.length > 0 &&
        companyList.forEach((company) => ids.push({ id: company._id }));
      setIsItemSelected([...isItemSelected, ...ids]);
      const arrayIds = ids.map((id) => id.id);
      const isItemSelectedIds = isItemSelected.map((item) => item.id);
      setSelectedCompany([...isItemSelectedIds, ...arrayIds]);
    } else {
      let itemOnPrevPage = [];
      if (ids.length === pageSize) {
        setIsItemSelected([]);
        setSelectedCompany([]);
      }
      if (isItemSelected.length === pageSize) {
        setIsItemSelected([]);
        setSelectedCompany([]);
      } else {
        itemOnPrevPage = isItemSelected && isItemSelected.splice(0, pageSize);
        setIsItemSelected(itemOnPrevPage);
        const idItemOnPrevPage = itemOnPrevPage.map((i) => i.id);
        setSelectedCompany(idItemOnPrevPage);
      }
    }
  };

  const renderStar = (item) => {
    const founded = isItemSelected.find(
      (itemSelected) => itemSelected.id === item._id
    );
    if (founded) {
      return <StarFilled style={{ fontSize: 20 }} />;
    }
    return <StarOutlined style={{ fontSize: 20 }} />;
  };

  return (
    <>
      <div title={title}>
        {tagMode && (
          <Checkbox
            checked={selectAll}
            onChange={({ target }) => {
              setSelectAll(!selectAll);
              selectAllCompanies(target.checked);
            }}
          >
            {Localize("COMMON.SELECT_ALL")}
          </Checkbox>
        )}
        <List
          className="searchPage__list animation"
          itemLayout="horizontal"
          dataSource={companyList}
          pagination={{
            onChange: (page, pageSize) => {
              setSelectAll(false);
              setCurrentPage(page);
              setCompanyTagPage && setCompanyTagPage(page);
              fetchData(page, pageSize);
            },
            current: currentPage,
            pageSize: pSize,
            total,
          }}
          renderItem={(item) => {
            return (
              <div className="searchPage__item-more animation fadein-right slow">
                <List.Item
                  className="searchPage__item"
                  onClick={() => {
                    typeof onRowClick === "function" && onRowClick(item);
                    !tagMode && history.push(`/companies/${item._id}`);
                  }}
                >
                  <List.Item.Meta
                    avatar={
                      item.logo ? (
                        <div
                          style={{ backgroundImage: `url(${item.logo})` }}
                          className="searchPage__item-logo"
                        />
                      ) : (
                        <Row align="middle">
                          <PictureOutlined className="searchPage__item-logo--placeholder" />
                        </Row>
                      )
                    }
                    title={
                      <Row align="middle" justify="space-between">
                        <Col>
                          <h3 className="searchPage__item-title">
                            {tagMode && (
                              <Button
                                type="text"
                                shape="circle"
                                size="small"
                                className="mr-2"
                                onClick={() => onStarClick(item)}
                              >
                                {renderStar(item)}
                              </Button>
                            )}
                            <b className="mr-2">{upperCase(item.name)}</b>
                            {loadingTag ? (
                              <Spin size="small" className="ml-4" />
                            ) : (
                              renderTag(item)
                            )}
                          </h3>
                        </Col>
                      </Row>
                    }
                    description={
                      <>
                        <p>{getGmapStringAddress(item)}</p>
                        <p>
                          {item.sector &&
                            item.sector.map((s, i) => (
                              <Tag key={i}> {s.key} </Tag>
                            ))}
                        </p>
                      </>
                    }
                  />
                </List.Item>
              </div>
            );
          }}
        />
      </div>
    </>
  );
};

export default CompanyList;
