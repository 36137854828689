import React from "react";
import { Col, Row } from "antd";
import Label from "../Label/Label";
import { label } from "../../libs/utils";

const CardField = (props) => {
  const { data, val, span = 8, schema, icon } = props;

  return (
    <>
      {data[val] && data[val].length > 0 && (
        <Col span={span} className="mb-3">
          <Row>
            {icon && icon}
            <Label value={label(schema, val)} />
          </Row>
          {data[val]}
        </Col>
      )}
    </>
  );
};

export default CardField;
