import React, { useEffect, useState } from "react";
import { Avatar, Row, Col, Modal, Spin, Tag, Button } from "antd";
import "./contact.scss";
import LinkedinOutlined from "@ant-design/icons/es/icons/LinkedinOutlined";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Localize from "../../components/Localized/Localize.comp";
import CardAddress from "../../components/Cards/Card.address";
import CardContacts from "../../components/Cards/Card.contacts";
import BannerTop from "../../components/BannerTop/BannerTop";
import { ReactComponent as Xing } from "../../assets/icons/icons8-xing.svg";
import {
  getContactBrandsAction,
  getContactDetailAction,
} from "../../actions/contact.actions";
import {
  deleteAssociatedIdTagAction,
  getTagAction,
} from "../../actions/tag.actions";
import CardNomina from "../../components/Cards/Card.nomina";
import CardBrands from "../../components/Cards/Card.brands";
import { toTitleCase } from "../../libs/utils";
import { upperCase } from "lodash";

const xingIconStyle = {
  width: "28px",
  border: "2px solid white",
  borderRadius: "3px",
  marginTop: "4px",
  height: "28px",
};

const ContactModal = (props) => {
  const { visible, close, id, savedTags } = props;

  const [detailTags, setDetailTags] = useState([]);

  const data = useSelector((state) => state.contact.detail.data);
  const contactBrands = useSelector((state) => state.contact.brands.data);
  const loading = useSelector((state) => state.contact.detail.loading);
  const tags = useSelector((state) => state.tag.tagsList);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContactDetailAction({ id }));
    dispatch(getContactBrandsAction({ id }));
    if (savedTags?.length > 0) {
      const founded = savedTags.find((st) => st.id === id);
      founded && setDetailTags(founded.tags);
    }
  }, [id]);

  useEffect(() => {
    if (tags.length === 0) {
      dispatch(getTagAction());
    }
  }, []);

  const renderTags = () => {
    const foundedTags = [];
    detailTags.map((dTag) => {
      const founded = tags.find((tag) => tag._id === dTag);
      foundedTags.push(founded);
    });
    return (
      <>
        {foundedTags.map((foundedTag) => (
          <Tag
            key={foundedTag._id}
            color={`#${foundedTag.color}`}
            className="searchPage__item-tag mt-3"
            style={{ borderWidth: 0 }}
          >
            {foundedTag.name}
          </Tag>
        ))}
      </>
    );
  };

  const showContactCard =
    data?.website ||
    data?.e_commerce ||
    data?.tel ||
    data?.phone ||
    data?.fax ||
    data?.email;

  return (
    <div className="contactPage">
      <>
        {loading ? (
          <Spin style={{ marginTop: 20, marginBottom: 20, marginRight: 10 }} />
        ) : (
          <>
            {data && data.surname && (
              <>
                <Modal
                  wrapClassName="contactPage__modal"
                  visible={visible}
                  onCancel={() => close()}
                  // width="600px"
                  title={
                    <div className="contactPage__head profile__container">
                      <BannerTop height="350px">
                        <Row
                          className="profile__container--left"
                          align="middle"
                        >
                          <div className="profile__avatar pt-3 pb-3">
                            <Avatar size={90} src={data.profile_img} />
                          </div>
                          <Row className="profile__data">
                            <Col span={20}>
                              <h2>
                                {data.prefix} {toTitleCase(data.name)}{" "}
                                {toTitleCase(data.surname)}
                              </h2>
                            </Col>
                            {data.linkedin &&
                              data.linkedin != 0 &&
                              data.linkedin.length > 0 && (
                                <a
                                  style={{ zIndex: 1 }}
                                  href={data.linkedin}
                                  target="_blank"
                                >
                                  <LinkedinOutlined />
                                </a>
                              )}
                            <Col span={18}>
                              <div className="profile__data-function">
                                {data.title} {Localize("COMMON.AT")}
                                <br />
                                <Link to={`/companies/${data.company.id}`}>
                                  {upperCase(data.company.name)}
                                </Link>
                                <Col>{renderTags()}</Col>
                              </div>
                            </Col>
                            {data && (
                              <a
                                style={{ zIndex: 1 }}
                                href={`mailto:lefac@test.com?subject=Contact ${toTitleCase(
                                  data?.name
                                )} ${toTitleCase(data?.surname)}`}
                              >
                                <Button type="text" className="help-btn">
                                  {Localize("COMMON.HELP")}
                                </Button>
                              </a>
                            )}
                          </Row>
                        </Row>
                      </BannerTop>
                    </div>
                  }
                  footer={
                    <Col span={24} className="contactPage__footer">
                      <Row className="profile__container--right" align="middle">
                        {data.xing && data.xing.length > 0 && (
                          <a href={data.xing} target="_blank">
                            <Xing style={xingIconStyle} />
                          </a>
                        )}
                      </Row>
                    </Col>
                  }
                >
                  <Col className="contactPage__body">
                    <Row>
                      <Row gutter={16}>
                        {showContactCard && (
                          <Col span={24}>
                            <CardContacts data={data} />
                          </Col>
                        )}
                        {data.address && (
                          <Col span={24}>
                            <CardAddress data={data} vertical={false} />
                          </Col>
                        )}
                        {(data.nomination_date || data.nomination_comment) && (
                          <Col span={24}>
                            <CardNomina data={data} />
                          </Col>
                        )}
                        {contactBrands && contactBrands.length > 0 && (
                          <Col span={24}>
                            <CardBrands data={contactBrands} />
                          </Col>
                        )}
                      </Row>
                    </Row>
                  </Col>
                </Modal>
              </>
            )}
          </>
        )}
      </>
    </div>
  );
};

export default ContactModal;
