import React, { useEffect, useState } from "react";
import { Row, Col, Statistic } from "antd";
import { ResponsivePie } from "@nivo/pie";
import _ from "lodash";
import { ResponsiveLine } from "@nivo/line";
import moment from "moment";
import Tabs from "../Tabs/Tabs";
import { getSocialGraphLabel } from "../../libs/utils";

const SocialMedia = ({ data, socialToDisplay }) => {
  console.log(data, "DATA");
  const [dataSet, setDataSet] = useState(null);
  const [legendYear, setLegendYear] = useState(null);
  const [legendData, setLegendData] = useState(null);

  useEffect(() => {
    if (_.isNil(dataSet) && data) {
      setDataSet(getData(data));
    }
  }, [data]);

  useEffect(() => {
    if (_.isNil(legendData) && dataSet) {
      setLegendData(getLegendData(dataSet));
    }
  }, [dataSet]);

  const getData = (data) => {
    const lineObj = [];
    let filtered = [];

    const years = Object.keys(data).sort();
    const lastYear = years.pop(); // takes the last year
    const yearToTake =
      lastYear && lastYear.substring(lastYear.indexOf("_") + 1);

    _.forEach(data, (values, year) => {
      if (socialToDisplay) {
        if (year.includes(yearToTake)) {
          filtered = Object.keys(values)
            .filter((key) => socialToDisplay.socialKeys.includes(key))
            .reduce((obj, key) => {
              obj[key] = values[key];
              return obj;
            }, {});
        }
      }
      _.forEach(filtered, (val, key) => {
        const find = _.find(lineObj, (o) => o.id === key);
        const date = year.split("_");
        // date[0] = mesi
        setLegendYear(yearToTake);

        const month = moment(date[0]).format("MMMM");
        if (!find) {
          lineObj.push({
            id: key,
            data: [{ x: month, y: val, k: key }],
          });
        } else {
          find.data.push({
            x: month,
            y: val,
            k: key,
          });
        }
      });
    });
    return lineObj;
  };

  const setPieOption = (key) => {
    switch (key) {
      case "FB_FANS":
        return "Facebook";
      case "INSTA_FOLLOWERS":
        return "Instagram";
      case "TW_FOLLOWERS":
        return "Twitter";
      case "YOUT_FOLLOWERS":
        return "YouTube";
      case "PIN_FOLLOWERS":
        return "Pinterest";
      case "GOOGLE_FOLLOWERS":
        return "Google";
      default:
        return "Altro social";
    }
  };

  const getStat = (dataSocial) => {
    const stat = [];
    const pieValue = [];

    _.forEach(dataSocial, (value, key) => {
      if (
        key !== "FB_LIKES" &&
        key !== "INSTA_FOLLOWING" &&
        key !== "INSTA_POSTS_LIKES" &&
        key !== "PIN_FOLLOWING" &&
        key !== "TW_FOLLOWING" &&
        key !== "TW_FOLLOWING" &&
        key !== "TW_TWEETS" &&
        key !== "TW_LIKES" &&
        key !== "YOUT_VIEWS"
      ) {
        stat.push(
          <Col
            key={key}
            span={12}
            style={{ margin: "5px 0" }}
            className="animation fadein-left faster"
          >
            <Statistic
              title={setPieOption(key, "label")}
              value={value}
              precision={0}
            />
          </Col>
        );

        pieValue.push({
          id: key,
          label: setPieOption(key, "label"),
          value,
        });
      }
    });

    return { stat, pieValue };
  };

  const getLegendData = () => {
    const graphLegend = [];

    const getColor = (index) => {
      switch (index) {
        case 0:
          return "hsl(335,99%,31%)";
        case 1:
          return "hsl(353, 64%, 54%)";
        case 2:
          return "hsl(14, 89%, 61%)";
        case 3:
          return "hsl(30, 98%, 68%)";
        case 4:
          return "hsl(44, 98%, 77%)";
        case 5:
          return "hsl(61, 100%, 87%)";
        default:
          return "red";
      }
    };
    dataSet.map((d, index) => {
      graphLegend.push({
        id: d.id,
        label: getSocialGraphLabel(d.id),
        fill: getColor(index),
      });
    });
    return graphLegend;
  };

  return (
    <>
      {data && !socialToDisplay ? (
        <Tabs
          containerClass="investments__tab"
          options={{ tabPosition: "top", animated: true }}
        >
          {Object.entries(data)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .filter((item, i) => item[i] > item[i - 1])
            .map((value) => {
              const [title, _data] = value;
              const { pieValue, stat } = getStat(_data);
              const date = title.split("_");
              return (
                <Row title={date[1]} key={title}>
                  <Col span={6}>
                    <Row gutter={16}>{stat}</Row>
                  </Col>
                  <Col span={18} style={{ height: 400 }}>
                    <ResponsivePie
                      margin={{ top: 0, right: 120, bottom: 20, left: 80 }}
                      data={pieValue}
                      innerRadius={0.5}
                      padAngle={0.9}
                      cornerRadius={3}
                      borderWidth={1}
                      borderColor={{
                        from: "color",
                        modifiers: [["darker", 0.2]],
                      }}
                      colors={{ scheme: "spectral" }}
                      enableRadialLabels={false}
                      radialLabelsSkipAngle={10}
                      radialLabelsTextXOffset={6}
                      radialLabelsTextColor="#333333"
                      radialLabelsLinkOffset={0}
                      radialLabelsLinkDiagonalLength={16}
                      radialLabelsLinkHorizontalLength={24}
                      radialLabelsLinkStrokeWidth={1}
                      radialLabelsLinkColor={{ from: "color" }}
                      slicesLabelsSkipAngle={10}
                      slicesLabelsTextColor="#333333"
                      animate={true}
                      motionStiffness={90}
                      motionDamping={15}
                      // defs={[ ?? ]}
                      legends={[
                        {
                          anchor: "right",
                          direction: "column",
                          itemWidth: 10,
                          itemHeight: 20,
                          itemTextColor: "#999",
                          symbolSize: 18,
                          symbolShape: "circle",
                          effects: [
                            {
                              on: "hover",
                              style: {
                                itemTextColor: "#000",
                              },
                            },
                          ],
                        },
                      ]}
                    />
                  </Col>
                </Row>
              );
            })}
        </Tabs>
      ) : (
        <Col span={20} style={{ height: "400px" }}>
          <ResponsiveLine
            data={dataSet}
            margin={{ top: 10, right: 120, bottom: 50, left: 80 }}
            xScale={{ type: "point" }}
            yScale={{ type: "linear" }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: "bottom",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: legendYear,
              legendOffset: 36,
              legendPosition: "middle",
            }}
            axisLeft={{
              orient: "left",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: "middle",
              format: (e) => Math.floor(e) === e && e,
            }}
            useMesh={true}
            colors={{ scheme: "spectral" }}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            legends={[
              {
                anchor: "top-right",
                direction: "column",
                itemWidth: 10,
                itemHeight: 20,
                itemTextColor: "#999",
                symbolSize: 18,
                symbolShape: "circle",
                translateX: 30,
                data: legendData,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#000",
                    },
                  },
                ],
              },
            ]}
          />
        </Col>
      )}
    </>
  );
};

export default SocialMedia;
