import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Input,
  Row,
  Select,
  Tooltip,
  DatePicker,
  Popover,
} from "antd";
import { find, groupBy, isEmpty } from "lodash";
import { connect, useDispatch, useSelector } from "react-redux";
import SearchOutlined from "@ant-design/icons/es/icons/SearchOutlined";
import SettingOutlined from "@ant-design/icons/es/icons/SettingOutlined";
import DownOutlined from "@ant-design/icons/es/icons/DownOutlined";
import UpOutlined from "@ant-design/icons/es/icons/UpOutlined";
import StarOutlined from "@ant-design/icons/es/icons/StarOutlined";
import StarFilled from "@ant-design/icons/es/icons/StarFilled";
import BannerTop from "../../components/BannerTop/BannerTop";
import Localize from "../../libs/localization";
import Form from "../../components/Form/Form";
import Tree from "../../components/Tree/Tree";
import { getMainCategoryAction } from "../../actions/registry/registry.main_category.actions";
import Label from "../../components/Label/Label";
import SearchPlaces from "../../components/SearchPlaces/SearchPlaces";
import CompanyAutocomplete from "../../components/CompanyAutocomplete/CompanyAutocomplete";
import { getTreeSectorAction } from "../../actions/registry/registry.sector.actions";
import {
  createSearchAction,
  deleteSearchAction,
  getSearchListAction,
  resetSearchAction,
  searchContactsAction,
} from "../../actions/search.action";
import InfoForm from "../form/InfoForm";
import InvestmentForm from "../form/InvestmentForm";
import ContactList from "../../components/List/Contact.list";
import { getFunctionAction } from "../../actions/registry/registry.function.actions";
import CreateTagModal from "../../components/CreateTagModal/CreateTagModal";
import TagOptions from "../../components/Tag/TagOptions";
import {
  associateTagAction,
  associationsTagIdContactAction,
  createTagAction,
  getTagAction,
} from "../../actions/tag.actions";
import { exportContactListApi } from "../../api/contact.api";
import { DownloadOutlined, SaveOutlined } from "@ant-design/icons";
import { exportContactsAction } from "../../actions/contact.actions";
import { getFromLocalStorage } from "../../libs/helpers/localstorage";
import FinancialForm from "../form/FinancialForm";
import moment from "moment";
import SearchManagement from "../../components/SearchManagement/SearchManagement";

const { Panel } = Collapse;
const { Option, OptGroup } = Select;

const PAGE_SIZE = 20;
const tenant = getFromLocalStorage("lefacWebTenant")?.data;

const ContactSearch = (props) => {
  const {} = props;
  const dispatch = useDispatch();

  const [open, setOpen] = useState([1]);
  const [dataForm, setDataForm] = useState({ company_filters: {} });
  const [companyFilters, setCompanyFilters] = useState({});
  const [showOther, setShowOther] = useState(false);
  const [moveSearchButton, setMoveSearchButton] = useState(false);
  const [filters, setFilters] = useState({});
  const [order, setOrder] = useState("modified_at");
  const [orderDir, setOrderDir] = useState("desc");

  // TAG
  const [tagMode, setTagMode] = useState(false);
  const [tagAddModal, setAddTagModal] = useState(false);
  const [tagValue, setTagValue] = useState("");
  const [colorValue, setColorValue] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedContact, setSelectedContact] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const [selectedSearchElement, setSelectedSearchElement] = useState(null);
  const [searchName, setSearchName] = useState(null);
  const [showPopoverSearch, setShowPopoverSearch] = useState(false);

  const resultSearch = useSelector((state) => state.search.results.data);
  const mainCategory = useSelector((state) => state.main_category.data);
  const token = useSelector((state) => state.auth.token);
  const sector = useSelector((state) => state.sector.data);
  const functions = useSelector((state) => state.functionReg.data);
  const tags = useSelector((state) => state.tag.tagsList);
  const searchList = useSelector((state) => state.search.searchList.data);
  const searchCreated = useSelector((state) => state.search.searchCreated.data);

  const ids = [];

  const loadingTagsIdsContact = useSelector(
    (state) => state.tag.tagsForIdsContact.loading
  );
  const associatedTagsIdsContact = useSelector(
    (state) => state.tag.tagsForIdsContact.data
  );

  useEffect(() => {
    if (functions.length == 0) {
      dispatch(getFunctionAction());
    }
  }, [functions]);

  useEffect(() => {
    if (mainCategory.length == 0) {
      dispatch(getMainCategoryAction());
    }
  }, [mainCategory]);

  useEffect(() => {
    if (sector.length == 0) {
      dispatch(getTreeSectorAction());
    }
  }, [sector]);

  useEffect(() => {
    // lista dei tag
    dispatch(getTagAction());
  }, [tagAddModal]);

  useEffect(() => {
    searchRequest();
  }, [selectedSearchElement]);

  useEffect(() => {
    searchRequest();
  }, [searchCreated]);

  const callback = (key) => {
    setOpen(key);
  };

  useEffect(() => {
    dispatch(getSearchListAction({ params: "entity=contact" }));
    dispatch(resetSearchAction());
  }, []);

  const renderFunctions = () => {
    const group = groupBy(functions.enum, "parent");

    return Object.keys(group).map((g, k) => {
      // CHECK if there is almost 1 element active in group
      const findActive = find(group[g], (o) => o.active);
      if (findActive) {
        return (
          <OptGroup key={k} label={g}>
            {group[g].map((el, jl) => {
              if (el.active) {
                return (
                  <Option key={`${g}-${jl}`} value={el.id}>
                    {el.id}
                  </Option>
                );
              }
            })}
          </OptGroup>
        );
      }
    });
  };

  const searchRequest = () => {
    const dataParams = {};
    Object.keys(dataForm).forEach((p) => {
      if (Array.isArray(dataForm[p])) {
        if (dataForm[p].length > 0) {
          dataParams[p] = dataForm[p];
        }
      } else if (dataForm[p] && dataForm[p].length > 0) {
        dataParams[p] = dataForm[p];
      } else if (dataForm[p] === true) {
        dataParams[p] = dataForm[p];
      }
    });

    if (order) {
      dataParams.sort_key = order;
      dataParams.sort_direction = orderDir;
    }

    dataParams.company_filters = {};
    if (!isEmpty(companyFilters)) {
      const compFilt = {};
      Object.keys(companyFilters).forEach((p) => {
        if (Array.isArray(companyFilters[p])) {
          if (companyFilters[p].length > 0) {
            compFilt[p] = companyFilters[p];
          }
        } else if (companyFilters[p] && companyFilters[p].length > 0) {
          compFilt[p] = companyFilters[p];
        }
      });

      if (compFilt.financial_filters && compFilt.financial_filters?.length) {
        dataParams.financial_filters = [...compFilt.financial_filters];
        delete compFilt.financial_filters;
      } else dataParams.company_filters = { ...compFilt };
      setDataForm({
        ...dataForm,
        company_filters: { ...compFilt },
        financial_filters: dataParams.financial_filters
          ? dataParams.financial_filters
          : [],
      });
    }

    setFilters(dataParams);
    setShowOther(false);
    setMoveSearchButton(false);
    dataParams.pageNum = 1;
    dispatch(searchContactsAction({ data: dataParams }));
  };

  const handleOrder = (val) => {
    if (val === order) {
      setOrderDir(orderDir == "asc" ? "desc" : "asc");
    } else {
      setOrderDir("asc");
    }
    setOrder(val);
  };

  const onExportContacts = () => {
    dispatch(exportContactsAction({ data: dataForm }));
  };

  useEffect(() => {
    searchRequest();
  }, [orderDir, order]);

  const orderLabel = (value, key) => {
    return (
      <Button
        type="link"
        className={`${
          order === value ? "selected" : ""
        } searchPage__orders-button`}
        onClick={() => handleOrder(value)}
      >
        {key}
        {order === value && (
          <span className="searchPage__orders-icon">
            {orderDir === "asc" ? <UpOutlined /> : <DownOutlined />}
          </span>
        )}
      </Button>
    );
  };

  const getContactPageIds = () => {
    // ottengo gli id della pagina per capire quali sono i tag associati per i singoli contatti
    if (resultSearch.contacts && resultSearch.contacts.data) {
      resultSearch.contacts.data.forEach((contact) => {
        ids.push(contact._id);
      });
    }
    dispatch(associationsTagIdContactAction({ data: { id: ids } }));
  };

  useEffect(() => {
    // prendo gli id ogni volta che cambia la lista contatti e quando apro/chiudo la tendina tag
    getContactPageIds();
  }, [resultSearch.contacts.data, tagMode]);

  const clearTagFields = () => {
    // svuoto i campi della modale di creazione tag
    setTagValue("");
    setColorValue("");
    setErrorMessage("");
  };

  const createTag = () => {
    // params e action per creare il tag dalla modale
    const params = {
      name: tagValue,
      color: colorValue.hex.replace("#", "").toUpperCase(),
    };

    dispatch(createTagAction({ data: params }));
    clearTagFields();
  };

  const associateTagContact = () => {
    // params e action per associare il tag ai contatti selezionati
    const dataParams = {};
    let id = "";

    tags.forEach((tag) => {
      if (tag.name === selectedTag) {
        id = tag._id;
      }
    });

    dataParams.name = selectedTag; // tag
    dataParams.contacts = selectedContact; // lista contatti
    dispatch(associateTagAction({ id, data: dataParams }));
    setTagMode(false); // chiude la sezione dei tag
    setSelectedTag(""); // svuota i contatti selezionati
  };

  const renderSearchButton = () => (
    <Row justify="end" align="bottom" style={{ height: "100%" }}>
      <SearchManagement
        searchList={searchList}
        value={selectedSearchElement}
        onDeleteSearch={() => {
          dispatch(
            deleteSearchAction({
              id: selectedSearchElement,
              type: "contact",
            })
          );
          setSelectedSearchElement(null);
          setDataForm({ company_filters: {} });
        }}
        onSearchElementChange={(id, data) => {
          setSelectedSearchElement(id);
          setDataForm(data ? data : { company_filters: {} });
        }}
      />
      <Popover
        placement="topLeft"
        title={Localize("PAGES.SEARCH.SEARCH_NAME")}
        content={searchNameContent}
        trigger="click"
        visible={showPopoverSearch}
        onVisibleChange={(visibility) => setShowPopoverSearch(visibility)}
      >
        <Button
          type="primary"
          className="mr-3"
          onClick={() => setShowPopoverSearch(true)}
        >
          <SaveOutlined />
          {Localize("PAGES.SEARCH.SAVE_SEARCH")}
        </Button>
      </Popover>
      <Button type="primary" onClick={() => searchRequest()}>
        <SearchOutlined />
        {Localize("COMMON.SEARCH")}
      </Button>
    </Row>
  );

  const searchNameContent = () => (
    <Row>
      <Col span={16}>
        <Input
          value={searchName}
          onChange={(e) => {
            setSearchName(e.target.value);
          }}
        />
      </Col>
      <Col align="right" span={8}>
        <Button
          disabled={!searchName}
          type="primary"
          onClick={() => {
            dispatch(
              createSearchAction({
                body: { data: dataForm, name: searchName, type: "contact" },
              })
            );
            setShowPopoverSearch(false);
            setSearchName(null);
          }}
        >
          {Localize("COMMON.SAVE")}
        </Button>
      </Col>
    </Row>
  );

  return (
    <>
      <BannerTop small>
        <h1 className="mt-4">{Localize("PAGES.CONTACTS.TITLE")}</h1>
      </BannerTop>
      <div className="searchPage__filters animation fadein-up slow \">
        <Card
          bordered={false}
          style={{ borderRadius: "10px" }}
          className="companyPage__body-card companyPage__body-card--custom customCard"
        >
          <Form
            name="form-contact"
            initialValues={dataForm}
            onChange={({ values, errors, completed }) => {
              setDataForm({
                ...dataForm,
                ...values,
                ...errors,
              });
            }}
          >
            {({
              values,
              errors,
              completed,
              updateValue: updateValueParent,
            }) => (
              <>
                <Row gutter={16}>
                  <Col span={8}>
                    <Label value={Localize("SCHEMA.DEFAULT.CONTACT.SURNAME")} />
                    <Input
                      value={dataForm.surname}
                      onChange={(e) => {
                        updateValueParent({
                          key: "surname",
                          value: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col span={8}>
                    <Label value={Localize("SCHEMA.DEFAULT.CONTACT.TITLE")} />
                    <Input
                      value={dataForm.title}
                      onChange={(e) => {
                        updateValueParent({
                          key: "title",
                          value: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col span={8}>
                    <Row align="bottom" style={{ height: "100%" }}>
                      <Checkbox
                        checked={dataForm.emailExists}
                        onChange={(value) => {
                          updateValueParent({
                            key: "emailExists",
                            value: value.target.checked,
                          });
                        }}
                      >
                        {Localize("PAGES.SEARCH.ONLY_WITH_MAIL")}
                      </Checkbox>
                    </Row>
                  </Col>
                  <Col span={8} className="mt-3">
                    <Label
                      value={Localize("SCHEMA.DEFAULT.CONTACT.FUNCTION")}
                    />
                    <Select
                      mode="multiple"
                      allowClear={true}
                      style={{ width: "100%" }}
                      value={dataForm.function}
                      onChange={(value) =>
                        updateValueParent({ key: "function", value })
                      }
                      showSearch
                    >
                      {functions.enum && renderFunctions()}
                    </Select>
                  </Col>
                  <Col span={8} className="mt-3">
                    <Label value={Localize("PAGES.SEARCH.ORDER.CITY")} />
                    <SearchPlaces
                      value={dataForm.city}
                      placeholder={Localize("COMMON.FIND_ADDRESS")}
                      type={["(cities)"]}
                      onChange={(place) => {
                        if (place && place.address_components) {
                          const city = place.address_components[0].long_name;
                          updateValueParent({ key: "city", value: city });
                        } else {
                          updateValueParent({ key: "city", value: "" });
                        }
                      }}
                    />
                  </Col>
                  <Col span={8} className="mt-3">
                    <Label
                      value={Localize("PAGES.SEARCH.ORDER.MODIFIED_DATE")}
                    />
                    <DatePicker
                      placeholder={""}
                      style={{ width: "100%" }}
                      value={
                        dataForm.modifiedAfter && moment(dataForm.modifiedAfter)
                      }
                      onChange={(value) => {
                        updateValueParent({
                          key: "modifiedAfter",
                          value: value?.format("YYYY-MM-DD"),
                        });
                      }}
                    />
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col>
                    <Button
                      type="link"
                      onClick={() => {
                        setMoveSearchButton(!moveSearchButton);
                        setShowOther(!showOther);
                      }}
                      className="secondary mt-3 pl-2"
                    >
                      <SettingOutlined className="mr-1" />
                      {Localize(
                        `COMMON.${!showOther ? "OTHER_FILTERS" : "CLOSE"}`
                      )}
                    </Button>
                  </Col>
                  <Col>{!moveSearchButton && renderSearchButton()}</Col>
                </Row>
                {showOther && (
                  <>
                    <div className="animation fadein-right slow">
                      <Form
                        name="form-contact-company"
                        className="animation fadein-right slow "
                        initialValues={companyFilters}
                        onChange={({ values, errors, completed }) => {
                          setCompanyFilters({
                            ...companyFilters,
                            ...values,
                            ...errors,
                          });
                          setDataForm({
                            ...dataForm,
                            company_filters: { ...companyFilters },
                          });
                        }}
                      >
                        {({ values, errors, completed, updateValue }) => (
                          <>
                            <Collapse
                              style={{ marginLeft: "-15px" }}
                              onChange={callback}
                              ghost={true}
                              activeKey={open}
                            >
                              <Panel
                                header={Localize("COMMON.COMPANY")}
                                key="1"
                                className="pt-3"
                              >
                                <Row gutter={16}>
                                  <Col span={8}>
                                    <Label
                                      value={Localize(
                                        "PAGES.SEARCH.ORDER.NAME"
                                      )}
                                    />
                                    <CompanyAutocomplete
                                      value={dataForm.company_filters.name}
                                      token={token}
                                      onChange={(value) => {
                                        updateValue({ key: "name", value });
                                      }}
                                    />
                                  </Col>
                                  <Col span={8}>
                                    <div>
                                      <Label
                                        value={Localize(
                                          "PAGES.SEARCH.ORDER.CATEGORY"
                                        )}
                                      />
                                      <Select
                                        mode="multiple"
                                        allowClear={true}
                                        style={{ width: "100%" }}
                                        value={
                                          dataForm.company_filters.main_category
                                        }
                                        onChange={(value) =>
                                          updateValue({
                                            key: "main_category",
                                            value,
                                          })
                                        }
                                        showSearch
                                        options={
                                          mainCategory.enum &&
                                          mainCategory.enum.map((e, j) => {
                                            return {
                                              value: e.id,
                                              key: e.id,
                                            };
                                          })
                                        }
                                      />
                                    </div>
                                  </Col>
                                  <Col span={8}>
                                    <Label
                                      value={Localize(
                                        "PAGES.SEARCH.ORDER.CITY"
                                      )}
                                    />
                                    <SearchPlaces
                                      value={dataForm.company_filters.city}
                                      placeholder={Localize(
                                        "COMMON.FIND_ADDRESS"
                                      )}
                                      type={["(cities)"]}
                                      onChange={(place) => {
                                        if (place && place.address_components) {
                                          const city =
                                            place.address_components[0]
                                              .long_name;
                                          updateValue({
                                            key: "city",
                                            value: city,
                                          });
                                        } else {
                                          updateValue({
                                            key: "city",
                                            value: "",
                                          });
                                        }
                                      }}
                                    />
                                  </Col>
                                  <Col span={8} className="mt-3">
                                    <Label
                                      value={Localize(
                                        "PAGES.SEARCH.ORDER.SECTOR"
                                      )}
                                    />
                                    <Tree
                                      valueData={
                                        dataForm.company_filters.sector
                                      }
                                      datatree={sector}
                                      onChange={(value) => {
                                        updateValue({ key: "sector", value });
                                      }}
                                      onSelect={(value, e) => {
                                        updateValue({ key: "sector", value });
                                      }}
                                    />
                                  </Col>
                                  <Col span={8} className="mt-3">
                                    <Label
                                      value={Localize(
                                        "PAGES.SEARCH.ORDER.OTHER_CAT"
                                      )}
                                    />
                                    <Select
                                      mode="multiple"
                                      allowClear={true}
                                      style={{ width: "100%" }}
                                      value={
                                        dataForm.company_filters
                                          .other_categories
                                      }
                                      onChange={(value) =>
                                        updateValue({
                                          key: "other_categories",
                                          value,
                                        })
                                      }
                                      showSearch
                                      options={[
                                        { value: "Lusso", key: "Lusso" },
                                        {
                                          value: "Online Pure Player",
                                          key: "Online Pure Player",
                                        },
                                      ]}
                                    />
                                  </Col>
                                  <Col span={8} className="mt-3">
                                    <Label
                                      value={Localize(
                                        "PAGES.SEARCH.ORDER.ATECO_CODE"
                                      )}
                                    />
                                    <Input
                                      value={dataForm.ateco_code}
                                      onChange={(e) => {
                                        updateValue({
                                          key: "ateco_code",
                                          value: e.target.value,
                                        });
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </Panel>
                              <Panel
                                header={Localize(
                                  "PAGES.SEARCH.ORDER.FINANCIAL_DATA"
                                )}
                                key="2"
                                className="pt-3"
                              >
                                <FinancialForm
                                  onChange={(key, value) =>
                                    updateValue({ key, value })
                                  }
                                  dataForm={dataForm}
                                />
                              </Panel>
                              <Panel
                                header={Localize(
                                  "PAGES.SEARCH.ORDER.ADDITION_INFO"
                                )}
                                key="3"
                                className="pt-3"
                              >
                                <InfoForm
                                  onChange={(key, value) =>
                                    updateValue({ key, value })
                                  }
                                  dataForm={dataForm.company_filters}
                                />
                              </Panel>
                              <Panel
                                header={Localize(
                                  "PAGES.SEARCH.ORDER.ADEVERTISING_INVESTMENTS"
                                )}
                                key="4"
                                className="pt-3"
                              >
                                <InvestmentForm
                                  onChange={(key, value) =>
                                    updateValueParent({ key, value })
                                  }
                                  dataForm={dataForm}
                                />
                              </Panel>
                            </Collapse>
                          </>
                        )}
                      </Form>
                    </div>
                    {moveSearchButton && renderSearchButton()}
                  </>
                )}
              </>
            )}
          </Form>
        </Card>
      </div>
      {resultSearch && resultSearch.contacts && resultSearch.contacts.data && (
        <div>
          <Row align="middle" className="searchPage__orders">
            <Tooltip title="Associa Tag">
              <Button
                type="primary"
                shape="circle"
                onClick={() => setTagMode(!tagMode)}
                icon={
                  tagMode ? (
                    <StarFilled className="mr-2" style={{ fontSize: 20 }} />
                  ) : (
                    <StarOutlined className="mr-2" style={{ fontSize: 20 }} />
                  )
                }
              />
            </Tooltip>
            <Col>{Localize("COMMON.ORDER_BY")}</Col>
            <Col flex="none">
              {orderLabel(
                "modified_at",
                Localize("PAGES.SEARCH.ORDER.LAST_MODIFY")
              )}
              {orderLabel("surname", Localize("PAGES.SEARCH.ORDER.NAME"))}
              {orderLabel("function", "Funzione")}
            </Col>
            <Col flex="auto">
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <h2
                  style={{ color: "white", fontWeight: "bold" }}
                  className="p-0 m-0 searchPage__orders-button"
                >
                  {Localize("PAGES.SEARCH.CONTACTS_FOUND")}:{" "}
                  <span>
                    {resultSearch.contacts && resultSearch.contacts.totalResults
                      ? resultSearch.contacts.totalResults
                      : 0}
                  </span>
                </h2>
                {resultSearch?.contacts?.data?.length > 0 &&
                  (props.user.group === "SUPER-ADMIN" ||
                    props.user.group === "ADMIN" ||
                    props.user.scopes[tenant]?.includes(
                      "p_contactsExport:any"
                    )) && (
                    <Button
                      type="primary"
                      className="ml-3"
                      onClick={onExportContacts}
                    >
                      <DownloadOutlined className="mr-1" />
                      {Localize("TAG.EXPORT_CONTACTS")}
                    </Button>
                  )}
              </div>
            </Col>
          </Row>
          <div className="animation fadein-right slow">
            <CreateTagModal
              visible={tagAddModal}
              onCancel={() => {
                clearTagFields();
                setAddTagModal(false);
              }}
              okDisabled={!tagValue || !colorValue || errorMessage}
              onOk={() => {
                createTag();
                setAddTagModal(false);
              }}
              tagValue={tagValue}
              colorValue={colorValue}
              onTagChange={(e) => {
                const foundedTag = tags.find(
                  (tag) =>
                    tag.name.toLowerCase() === e.target.value.toLowerCase()
                );
                if (foundedTag) {
                  setErrorMessage(Localize("TAG.ERROR_MESSAGE"));
                } else {
                  setErrorMessage("");
                }
                setTagValue(e.target.value);
              }}
              onColorChange={(color) => setColorValue(color)}
              errorMessage={errorMessage}
            />
            {tagMode && (
              <TagOptions
                onAddTagClick={() => setAddTagModal(true)} // apro modale
                tagList={tags} // lista dei tag nella select
                value={selectedTag} // tag selezionato dalla lista
                onTagChange={(value) => setSelectedTag(value)} // cambio tag selezionato
                onAssociateClick={associateTagContact} // azione al bottone "ASSOCIA"
                associateDisabled={!selectedTag || selectedContact.length === 0} // bottone disabilitato se non c'è il tag E i non ci sono contatti da associare
              />
            )}
          </div>
          <div>
            <ContactList
              data={(resultSearch.contacts && resultSearch.contacts.data) || []}
              pageSize={PAGE_SIZE}
              total={
                resultSearch.contacts && resultSearch.contacts.totalResults
              }
              title={
                <>
                  {Localize("PAGES.COMPANIES.TITLE")} (
                  {resultSearch.contacts && resultSearch.contacts.totalResults})
                </>
              }
              filters={filters}
              tagMode={tagMode} // mostra stelle, seleziona tutto, blocca l'apertura della modale di dettaglio
              setSelectedContact={(id) => setSelectedContact(id)} // callback per comporre l'array di contatti selezionati
              loadingTag={loadingTagsIdsContact} // loader per le tag label
              associatedTagsIds={
                associatedTagsIdsContact && associatedTagsIdsContact
              } // risultato api per l'associazione contatto - tag per fare le label
            />
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

export default connect(mapStateToProps, {})(ContactSearch);
