import Config from "../Config";
import ErrorMessage from "../libs/helpers/ErrorMessage";

export const searchAutocompleteApi = async ({ headers, key }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.frontend.api}/search?key=${key}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;

  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const searchCompaniesApi = async ({ headers, data }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.frontend.api}/company`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    }
  );

  const response = res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;

  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const searchContactsApi = async ({ headers, data }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.frontend.api}/contact`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const searchBrandsApi = async ({ headers, params = "" }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.brand.list}${params}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const searchPitchesApi = async ({ headers, data }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.frontend.api}/pitch`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }

  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const searchWorkRelationApi = async ({ headers, data }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.frontend.api}/clc`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;

  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getSavedSearchList = async ({ headers, params = "" }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.savedSearch.api}?${params}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const createSearch = async ({ headers, body }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.savedSearch.api}`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;

  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const deleteSearch = async ({ headers, id }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.savedSearch.api}/${id}`,
    {
      method: "DELETE",
      headers,
    }
  );

  if (res.ok) {
    return;
  }

  const { status, statusText, httpStatus, clientType, description } = res;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};
