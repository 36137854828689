import { reducerFromMap } from "../libs/helpers/reducers";
import {
  getPaginatedProps,
  getDetailAndSchemaProps,
  getDetailProps,
} from "../libs/helpers/callApi";

import {
  CONTACT_ENTITY_LOADING,
  CONTACT_ENTITY_LOADED,
  CONTACT_ENTITY_LOAD_ERROR,
  CONTACT_LIST_LOADING,
  CONTACT_LIST_LOADED,
  CONTACT_LIST_LOAD_ERROR,
  CONTACT_DETAIL_LOADING,
  CONTACT_DETAIL_LOADED,
  CONTACT_DETAIL_LOAD_ERROR,
  CONTACT_COMPANY_LOADING,
  CONTACT_COMPANY_LOADED,
  CONTACT_COMPANY_LOAD_ERROR,
  CONTACT_BRAND_LOADING,
  CONTACT_BRAND_LOADED,
  CONTACT_BRAND_LOAD_ERROR,
  CONTACT_DETAIL_EDITING,
  CONTACT_DETAIL_EDITED,
  CONTACT_DETAIL_EDIT_ERROR,
  EXPORT_CONTACTS_LOADING,
  EXPORT_CONTACTS_LOADED,
  EXPORT_CONTACTS_LOAD_ERROR,
} from "../actions/contact.actions";

const reducer = {
  /*
   * CONTACT_LIST
   */
  [CONTACT_LIST_LOADING]: (state) => ({
    ...state,
    list: { ...state.list, loading: true },
  }),
  [CONTACT_LIST_LOADED]: (state, { data }) => ({
    ...state,
    list: { loading: false, ...getPaginatedProps(data) },
  }),
  [CONTACT_LIST_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    list: { ...state.list, loading: false },
    error: data,
  }),
  /*
   * CONTACT_DETAIL
   */
  [CONTACT_DETAIL_LOADING]: (state) => ({
    ...state,
    detail: {
      // ...state.detail,
      loading: true,
    },
  }),
  [CONTACT_DETAIL_LOADED]: (state, { data }) => ({
    ...state,
    detail: {
      loading: false,
      ...getDetailAndSchemaProps(data),
    },
  }),
  [CONTACT_DETAIL_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    detail: { loading: false },
    error: data,
  }),
  /*
   * CONTACT_COMPANY
   */
  [CONTACT_COMPANY_LOADING]: (state) => ({
    ...state,
    company: {
      // ...state.company,
      loading: true,
    },
  }),
  [CONTACT_COMPANY_LOADED]: (state, { data }) => ({
    ...state,
    company: {
      loading: false,
      ...getDetailAndSchemaProps(data),
    },
  }),
  [CONTACT_COMPANY_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    company: { loading: false },
    error: data,
  }),
  /*
   * CONTACT_BRAND
   */
  [CONTACT_BRAND_LOADING]: (state) => ({
    ...state,
    brands: {
      // ...state.brands,
      loading: true,
    },
  }),
  [CONTACT_BRAND_LOADED]: (state, { data }) => ({
    ...state,
    brands: {
      loading: false,
      ...getDetailAndSchemaProps(data),
    },
  }),
  [CONTACT_BRAND_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    brands: { loading: false },
    error: data,
  }),
  /*
   * EXPORT CONTACTS
   */
  [EXPORT_CONTACTS_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [EXPORT_CONTACTS_LOADED]: (state) => ({
    ...state,
    loading: false,
  }),
  [EXPORT_CONTACTS_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
};

export default reducerFromMap(reducer, { list: {}, detail: {}, brands: {} });
