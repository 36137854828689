import React, { useState, useEffect } from "react";
import { Checkbox, Input, Row, Col, Modal, Divider, Spin } from "antd";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import CreativityCarousel from "../../components/CreativityCarousel/CreativityCarousel";
import {
  getContactsListAction,
  getCreaListAction,
  getPitchListAction,
  resetNewsSearchAction,
  searchNewsAction,
} from "../../actions/home.actions";
import NewsList from "../../components/List/News.list";
import "./home.styles.scss";
import Localize from "../../libs/localization";
import PitchHomeList from "../../components/List/Pitch.home.list";
import TagWidget from "../../components/Tag/TagWidget";
import { getTagAction } from "../../actions/tag.actions";
import BrandModal from "../brand/brand.modal";
import { get } from "../../libs/helpers/ioc";
import ContactsHomeList from "../../components/List/Contacts.home.list";

const { Search } = Input;

const LIST_MODE = "LIST";
const LIKE_MODE = "LIKE";
const EXACT_MODE = "EXACT";

const HomePage = () => {
  const dispatch = useDispatch();

  const [term, setTerm] = useState("");
  const [newsToShow, setNewsToShow] = useState([]);
  const [lastNews, setLastNews] = useState({});
  const [newsSearchMode, setNewsSearchMode] = useState(LIST_MODE);
  const [searchByBtn, setSearchByBtn] = useState(false);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [areItemsLoading, setAreItemsLoading] = useState(true);
  const [isExactChecked, setIsExactChecked] = useState(false);
  const [noNews, setNoNews] = useState(false);

  const [showBrandModal, setShowBrandModal] = useState(false);
  const [brandId, setBrandId] = useState(null);

  const news = useSelector((state) => state.home.news);
  const crea = useSelector((state) => state.home.crea);
  const pitch = useSelector((state) => state.home.pitch);
  const contacts = useSelector((state) => state.home.contacts);
  const creaLoading = useSelector((state) => state.home.crea.loading);
  const pitchLoading = useSelector((state) => state.home.pitch.loading);
  const contactsLoading = useSelector((state) => state.home.contacts.loading);
  const tags = useSelector((state) => state.tag.tagsList);
  const tagsLoading = useSelector((state) => state.tag.loading);

  const permissions = get("scopes");

  useEffect(() => {
    Object.keys(permissions)?.includes("pitch") &&
      dispatch(
        getCreaListAction({
          data: { pageNum: 1, sort_key: "created_at", sort_direction: "desc" },
        })
      );
    Object.keys(permissions)?.includes("pitch") &&
      dispatch(
        getPitchListAction({
          data: { pageNum: 1, sort_key: "start_date", sort_direction: "desc" },
        })
      );
    Object.keys(permissions)?.includes("contact") &&
      dispatch(
        getContactsListAction({
          page: 1,
          params: ["limit=50", "sort_key=modified_at", "sort_direction=desc"],
        })
      );
    dispatch(searchNewsAction({ data: { mode: LIST_MODE } }));
  }, []);

  useEffect(() => {
    if (news && news.data) {
      const finalArray = newsToShow.concat(news.data);
      const uniqData = _.uniqBy(finalArray, "_id");
      setNewsToShow([...uniqData]);
    }

    if (news && news.data && news.data.length === 0) {
      setAreItemsLoading(false);
      setHasMoreItems(false);
    }

    if (news && news.data && newsToShow.length === 0 && term) {
      setNoNews(true);
    } else {
      setNoNews(false);
    }
  }, [news]);

  useEffect(() => {
    dispatch(resetNewsSearchAction());
  }, [searchByBtn]);

  useEffect(() => {
    const lastEl =
      news && news.data && news.data.length > 0 && news.data.slice(-1)[0];
    lastEl && setLastNews({ lastId: lastEl._id, lastPubDate: lastEl.pubDate });
  }, [news.data]);

  const searchNews = () => {
    const dataParams = {};

    isExactChecked
      ? setNewsSearchMode(EXACT_MODE)
      : setNewsSearchMode(LIKE_MODE);

    if (term.length >= 3) {
      dataParams.text = term;
      dataParams.mode = newsSearchMode;
    }

    if (!term) {
      setNewsSearchMode(LIST_MODE);
      dataParams.mode = LIST_MODE;
    }

    if (lastNews) {
      dataParams.lastPubDate = lastNews.lastPubDate;
      dataParams.lastId = lastNews.lastId;
    }

    dispatch(searchNewsAction({ data: dataParams }));
  };

  useEffect(() => {
    searchNews();
  }, []);
  const handleInfiniteLoad = () => {
    if (news && news.data && news.data.length > 0) {
      setAreItemsLoading(false);
      setHasMoreItems(true);

      const finalArray = newsToShow.concat(news.data);
      const uniqData = _.uniqBy(finalArray, "_id");

      setNewsToShow([...uniqData]);
    }

    if (!areItemsLoading && !hasMoreItems) {
      setHasMoreItems(false);
    }

    searchNews();
  };

  useEffect(() => {
    if (
      tags.length === 0 &&
      (Object.keys(permissions)?.includes("company") ||
        Object.keys(permissions)?.includes("contact"))
    ) {
      dispatch(getTagAction());
    }
  }, []);

  const pitchHeight = tags.length > 0 ? "405px" : "717px";
  const pitchCardHeight = tags.length > 0 ? "350px" : "663px";

  return (
    <div className="mt-4">
      {crea && crea.data && (
        <Col className="homePage__creaContainer">
          <div className="homePage__titleContainer">
            <h1 style={{ color: "white" }}>
              {Localize("PAGES.HOME.LATEST_CREA")}
            </h1>
          </div>
          {creaLoading ? (
            <div className="text-center" style={{ height: 260 }}>
              <Spin className="mt-5" />
            </div>
          ) : (
            <CreativityCarousel
              data={crea && crea.data}
              loading={creaLoading}
              setBrandId={setBrandId}
              setShowBrandModal={setShowBrandModal}
            />
          )}
        </Col>
      )}

      {brandId && (
        <BrandModal
          id={brandId}
          visible={showBrandModal}
          close={() => {
            setBrandId(null);
            setShowBrandModal(false);
          }}
        />
      )}

      <Row gutter={24} justify="space-between" className="m-0">
        {!pitchLoading && !tagsLoading && (
          <Col
            span={
              Object.keys(permissions)?.includes("pitch") ||
              Object.keys(permissions)?.includes("contact") ||
              !!tags.length
                ? 14
                : 24
            }
            className="homePage__newsSection p-0"
          >
            <div className="homePage__titleContainer">
              <h1 style={{ color: "white" }}>
                {Localize("PAGES.HOME.LATEST_NEWS")}
              </h1>
            </div>
            <Row className="m-4" align="middle">
              <Search
                value={term}
                onChange={(e) => {
                  setTerm(e.target.value);
                  setLastNews({});
                  setNewsSearchMode(LIKE_MODE);
                }}
                size="large"
                placeholder="Cerca notizie"
                onSearch={() => {
                  setNewsToShow([]);
                  setHasMoreItems(true);
                  setAreItemsLoading(true);
                  setSearchByBtn(true);
                  searchNews();
                }}
                enterButton
                style={{ width: "60%" }}
              />
              <Checkbox
                className="ml-3"
                checked={isExactChecked}
                onChange={({ target }) => {
                  setIsExactChecked(target.checked);
                  if (target.checked) {
                    setNewsSearchMode(EXACT_MODE);
                  } else {
                    setNewsSearchMode(LIKE_MODE);
                  }
                }}
              >
                {Localize("PAGES.HOME.EXACT_SEARCH")}
              </Checkbox>
            </Row>
            <Divider />
            <div
              className="homePage__newsSection__newsContainer"
              style={{
                height:
                  contacts && contacts.data?.length && pitch?.data?.length
                    ? "900px"
                    : "550px",
              }}
            >
              {noNews && newsToShow.length === 0 && (
                <h3 className="text-center mt-5">
                  {Localize("PAGES.HOME.NO_NEWS_FOUND")}
                  <span>.</span>
                </h3>
              )}
              <InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={handleInfiniteLoad}
                hasMore={hasMoreItems}
                useWindow={false}
              >
                <NewsList
                  data={newsToShow}
                  loadingScroll={news && news.loading}
                />
              </InfiniteScroll>
            </div>
          </Col>
        )}
        {(Object.keys(permissions)?.includes("pitch") ||
          Object.keys(permissions)?.includes("contact") ||
          tags.length > 0) &&
          !pitchLoading &&
          !tagsLoading && (
            <Col span={9} className="p-0">
              {Object.keys(permissions)?.includes("pitch") && (
                <Col
                  className="homePage__pitchContainer p-0"
                  style={{ height: pitchHeight }}
                >
                  <div className="homePage__titleContainer">
                    <h1 style={{ color: "white" }}>
                      {Localize("PAGES.HOME.LATEST_PITCH")}
                    </h1>
                  </div>
                  {pitchLoading ? (
                    <div className="text-center">
                      <Spin className="mt-5" />
                    </div>
                  ) : (
                    <PitchHomeList
                      pitchCardHeight={pitchCardHeight}
                      data={pitch && pitch.data}
                      loading={pitchLoading}
                    />
                  )}
                </Col>
              )}
              {tags.length > 0 && (
                <Col
                  className={`homePage__tagsContainer ${
                    Object.keys(permissions)?.includes("pitch") ? "mt-4" : ""
                  }`}
                >
                  <TagWidget tags={tags} />
                </Col>
              )}
              {contacts && contacts.data?.length > 0 && (
                <Col
                  className={`homePage__contactsContainer p-0 ${
                    Object.keys(permissions)?.includes("pitch") ||
                    tags.length > 0
                      ? "mt-4"
                      : ""
                  }`}
                  style={{ height: pitchHeight }}
                >
                  <div className="homePage__titleContainer">
                    <h1 style={{ color: "white" }}>
                      {Localize("PAGES.HOME.LATEST_CONTACTS")}
                    </h1>
                  </div>
                  {contactsLoading ? (
                    <div className="text-center">
                      <Spin className="mt-5" />
                    </div>
                  ) : (
                    <ContactsHomeList
                      cardHeight="350px"
                      data={contacts && contacts.data}
                      loading={contactsLoading}
                    />
                  )}
                </Col>
              )}
            </Col>
          )}
      </Row>
    </div>
  );
};

export default HomePage;
