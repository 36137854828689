import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, orderBy, remove, isEqual } from "lodash";
import { Button, Col, InputNumber, Modal, Row, Select } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import Localize from "../../components/Localized/Localize.comp";

import Label from "../../components/Label/Label";

const FinancialForm = (props) => {
  const { dataForm, onChange } = props;

  const [filter, setFilter] = useState(dataForm.financial_filters || []);
  const [selectedType, setSelectedType] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [min, setMin] = useState(null);
  const [max, setMax] = useState(null);

  const dispatch = useDispatch();

  const clear = () => {
    setSelectedType([]);
    setSelectedYear(null);
    setMin(null);
    setMax(null);
  };

  const generateFilter = () => {
    const filterArray = [];
    selectedType.forEach((e) => {
      const f = {
        year: selectedYear,
        key: e,
      };
      if (min) {
        f.min = min;
      }
      if (max) {
        f.max = max;
      }
      filterArray.push(f);
    });
    const total = [...filter, ...filterArray];
    setFilter(total);
    clear();
    onChange("financial_filters", total);
  };

  const removeFromFilterList = (f) => {
    remove(filter, (c) => {
      return isEqual(f, c);
    });
    setFilter(filter);
    onChange("financial_filters", filter);
  };

  const writeType = (type) => {
    switch (type) {
      case "revenue":
        return Localize("COMMON.REVENUE");
      case "operatingResult":
        return Localize("COMMON.INCOME");
      case "employees":
        return Localize("COMMON.EMPLOYEES");

      default:
        break;
    }
  };

  return (
    <>
      <ul style={{ listStyleType: "none", padding: 0 }}>
        {filter.map((f, i) => (
          <li key={i}>
            <CloseOutlined onClick={() => removeFromFilterList(f)} />
            <b className="secondary"> {writeType(f.key)}</b> | {f.year}{" "}
            <>
              | <span className="mr-2">{Localize("COMMON.FROM")}</span>
              {f.min ? f.min : 0}
            </>
            {f.max && (
              <>
                <span className="mr-2"> {Localize("COMMON.MAX_VALUE")}</span>
                {f.max}
              </>
            )}
          </li>
        ))}
      </ul>
      <Row gutter={16}>
        <Col span={8}>
          <Label value={Localize("COMMON.YEAR")} />
          <InputNumber
            value={selectedYear}
            onChange={(value) => setSelectedYear(value)}
            min={1940}
            style={{ width: "100%" }}
          />
        </Col>
        <Col span={8}>
          <Label value={Localize("COMMON.TYPE")} />
          <Select
            mode="multiple"
            allowClear={true}
            style={{ width: "100%" }}
            value={selectedType}
            onChange={(value) => setSelectedType(value)}
            showSearch
            options={[
              {
                key: "revenue",
                value: "revenue",
                label: Localize("COMMON.REVENUE"),
              },
              {
                key: "operatingResult",
                value: "operatingResult",
                label: Localize("COMMON.INCOME"),
              },
              {
                key: "employees",
                value: "employees",
                label: Localize("COMMON.EMPLOYEES"),
              },
            ]}
          />
        </Col>
        <Col span={8} style={{ paddingTop: "36px" }}>
          <Row justify="space-between">
            <Row align="bottom">
              <b className="mr-2">{Localize("COMMON.FROM")}</b>
              <InputNumber
                min={0}
                value={min}
                onChange={(value) => setMin(value)}
              />
            </Row>
            <Row align="bottom">
              <b className="mx-2">{Localize("COMMON.TO")}</b>
              <InputNumber
                min={0}
                value={max}
                onChange={(value) => setMax(value)}
              />
            </Row>
            <Button
              disabled={
                selectedYear === null ||
                selectedType.length === 0 ||
                (min === null && max === null)
              }
              type="primary"
              onClick={() => generateFilter()}
            >
              <PlusOutlined />
              {Localize("COMMON.ADD")}
            </Button>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default FinancialForm;
