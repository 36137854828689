import { matchPath } from "react-router";
import {
  APP_START,
  ROUTER_LOCATION_CHANGE,
  SET_ERROR,
} from "../actions/app.actions";
import {
  AUTHORIZE_DONE,
  USERINFO_LOADED,
  authorizeAction,
  getUserInfoAction,
  updateAuth,
} from "../actions/auth.actions";
import {
  getFromLocalStorage,
  removeFromLocalStorage,
  setOnLocalStorage,
} from "../libs/helpers/localstorage";

import generatePermFromScopes from "../libs/helpers/generatePermFromScopes";
// import { push } from "connected-react-router";
import { refreshPage } from "../libs/utils";
import { register } from "../libs/helpers/ioc";
import { getFlatRouteList, type } from "../libs/router";
import routes from "../routes";

const getStateAuthInfo = (store) =>
  (store.getState() && store.getState().auth) || {};

const checkUser = (store, force = false) => {
  const ls = getFromLocalStorage();
  const tenant = getFromLocalStorage("lefacWebTenant");

  const storageInfoId = ls && ls.user && (ls.user.uid || ls.user._id);
  const storageInfoToken = ls && ls.token;

  const { user, token } = getStateAuthInfo(store);

  const userID = user ? user.uid || user._id : null;

  if (storageInfoId && userID && userID !== storageInfoId) {
    refreshPage();
  } else if (token) {
    store.dispatch(getUserInfoAction());
  } else if (
    (!token || token !== storageInfoToken) &&
    storageInfoToken &&
    storageInfoId
  ) {
    if (ls.scopes) {
      const scopes = tenant ? ls.scopes[tenant.data] : [];
      register("scopes", generatePermFromScopes(scopes));
    }
    store.dispatch(
      updateAuth({ user: ls.user, token: ls.token, scopes: ls.scopes })
    );
  } else {
    removeFromLocalStorage("user");
    removeFromLocalStorage("token");
    removeFromLocalStorage("lefacWebTenant");
  }
};

const checkQueryParams = (store) => {
  let { hash, query } = store.getState().router.location;

  if (query && (query.authorization_code || query.code)) {
    store.dispatch(
      authorizeAction({ code: query.authorization_code || query.code })
    );
    return true;
  }
  if (query && query.logout === "OK") {
    window.location = "/";
  } else if (hash) {
    [, hash] = hash.split("#");
    const params = {};
    hash.split("&").forEach((t) => {
      const [k, v] = t.split("=");
      params[k] = v;
    });
  }
  return false;
};

const authMiddleware = (store) => (next) => (action) => {
  if (action.type === APP_START) {
    if (!checkQueryParams(store)) {
      checkUser(store);
    }
  } else if (action.type === AUTHORIZE_DONE) {
    const { user, token, scopes } = action.data;
    if (user && token) {
      setOnLocalStorage(action.data);
      if (scopes) {
        const tenant = getFromLocalStorage("lefacWebTenant");
        const currentScopes = tenant ? scopes[tenant.data] : [];
        register("scopes", generatePermFromScopes(currentScopes));
      }
    } else {
      console.error("ERROR", "Session not saved");
      return window.location.replace("/login");
    }
  } else if (action.type === USERINFO_LOADED) {
    if (action.data) {
      setOnLocalStorage({ user: action.data });
      if (action.data.scopes) {
        const _tenant = getFromLocalStorage("lefacWebTenant");
        const currentScopes = _tenant ? action.data.scopes[_tenant.data] : [];
        register("scopes", generatePermFromScopes(currentScopes));
      }
    } else {
      console.error("ERROR", "Session not saved");
      return window.location.replace("/login");
    }
  } else if (action.type === SET_ERROR) {
    // next(action);
    // store.dispatch(push("/error"));
  } else if (action.type === ROUTER_LOCATION_CHANGE) {
    window.scrollTo(0, 0);
    if (
      action.payload &&
      action.payload.location &&
      action.payload.location.pathname
    ) {
      const currentPath = getFlatRouteList(routes).find((v) =>
        matchPath(action.payload.location.pathname, {
          path: v.path,
          exact: true,
        })
      );

      checkUser(
        store,
        (currentPath && currentPath.type === type.SECURED) || false
      );
    }
  }

  next(action);
};

export default authMiddleware;
