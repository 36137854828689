import callApi from "../libs/helpers/callApi";

import {
  getCompanyEntityApi,
  getCompanyListApi,
  getCompanyDetailApi,
  editCompanyDetailApi,
  getCompanyContactsApi,
  getCompanyBrandsApi,
  createCompanyDetailApi,
  getCompanyNielsenApi,
  uploadNielsenFileApi,
  getCompanyPitchApi,
  getCompanyCreaApi,
  getCompanySocialMediaApi,
} from "../api/company.api";
import { searchContactsApi, searchPitchesApi } from "../api/search.api";

export const COMPANY_LIST_LOADING = "COMPANY_LIST_LOADING";
export const COMPANY_LIST_LOADED = "COMPANY_LIST_LOADED";
export const COMPANY_LIST_LOAD_ERROR = "COMPANY_LIST_LOAD_ERROR";

export const getCompanyListAction = callApi({
  apiCall: (p) => getCompanyListApi(p),
  secured: true,
  startAction: COMPANY_LIST_LOADING,
  successAction: COMPANY_LIST_LOADED,
  errorAction: COMPANY_LIST_LOAD_ERROR,
});

export const COMPANY_DETAIL_LOADING = "COMPANY_DETAIL_LOADING";
export const COMPANY_DETAIL_LOADED = "COMPANY_DETAIL_LOADED";
export const COMPANY_DETAIL_LOAD_ERROR = "COMPANY_DETAIL_LOAD_ERROR";

export const getCompanyDetailAction = callApi({
  apiCall: (p) => getCompanyDetailApi(p),
  secured: true,
  startAction: COMPANY_DETAIL_LOADING,
  successAction: COMPANY_DETAIL_LOADED,
  errorAction: COMPANY_DETAIL_LOAD_ERROR,
});

export const COMPANY_CONTACTS_LOADING = "COMPANY_CONTACTS_LOADING";
export const COMPANY_CONTACTS_LOADED = "COMPANY_CONTACTS_LOADED";
export const COMPANY_CONTACTS_LOAD_ERROR = "COMPANY_CONTACTS_LOAD_ERROR";

/*export const getCompanyContactsAction = callApi({
  apiCall: (p) => getCompanyContactsApi(p),
  secured: true,
  startAction: COMPANY_CONTACTS_LOADING,
  successAction: COMPANY_CONTACTS_LOADED,
  errorAction: COMPANY_CONTACTS_LOAD_ERROR,
});*/

export const getCompanyContactsAction = callApi({
  apiCall: (p) => searchContactsApi(p),
  secured: true,
  startAction: COMPANY_CONTACTS_LOADING,
  successAction: COMPANY_CONTACTS_LOADED,
  errorAction: COMPANY_CONTACTS_LOAD_ERROR,
});

export const COMPANY_BRANDS_LOADING = "COMPANY_BRANDS_LOADING";
export const COMPANY_BRANDS_LOADED = "COMPANY_BRANDS_LOADED";
export const COMPANY_BRANDS_LOAD_ERROR = "COMPANY_BRANDS_LOAD_ERROR";

export const getCompanyBrandsAction = callApi({
  apiCall: (p) => getCompanyBrandsApi(p),
  secured: true,
  startAction: COMPANY_BRANDS_LOADING,
  successAction: COMPANY_BRANDS_LOADED,
  errorAction: COMPANY_BRANDS_LOAD_ERROR,
});

export const COMPANY_NIELSEN_LOADING = "COMPANY_NIELSEN_LOADING";
export const COMPANY_NIELSEN_LOADED = "COMPANY_NIELSEN_LOADED";
export const COMPANY_NIELSEN_LOAD_ERROR = "COMPANY_NIELSEN_LOAD_ERROR";

export const getCompanyNielsenAction = callApi({
  apiCall: (p) => getCompanyNielsenApi(p),
  secured: true,
  startAction: COMPANY_NIELSEN_LOADING,
  successAction: COMPANY_NIELSEN_LOADED,
  errorAction: COMPANY_NIELSEN_LOAD_ERROR,
});

export const COMPANY_PITCH_LOADING = "COMPANY_PITCH_LOADING";
export const COMPANY_PITCH_LOADED = "COMPANY_PITCH_LOADED";
export const COMPANY_PITCH_LOAD_ERROR = "COMPANY_PITCH_LOAD_ERROR";

export const getCompanyPitchAction = callApi({
  apiCall: (p) => searchPitchesApi(p),
  secured: true,
  startAction: COMPANY_PITCH_LOADING,
  successAction: COMPANY_PITCH_LOADED,
  errorAction: COMPANY_PITCH_LOAD_ERROR,
});

export const COMPANY_CREA_LOADING = "COMPANY_CREA_LOADING";
export const COMPANY_CREA_LOADED = "COMPANY_CREA_LOADED";
export const COMPANY_CREA_LOAD_ERROR = "COMPANY_CREA_LOAD_ERROR";

export const getCompanyCreaAction = callApi({
  apiCall: (p) => getCompanyCreaApi(p),
  secured: true,
  startAction: COMPANY_CREA_LOADING,
  successAction: COMPANY_CREA_LOADED,
  errorAction: COMPANY_CREA_LOAD_ERROR,
});

export const SOCIAL_MEDIA_LOADING = "SOCIAL_MEDIA_LOADING";
export const SOCIAL_MEDIA_LOADED = "SOCIAL_MEDIA_LOADED";
export const SOCIAL_MEDIA_LOAD_ERROR = "SOCIAL_MEDIA_LOAD_ERROR";

export const getCompanySocialMediaAction = callApi({
  apiCall: (p) => getCompanySocialMediaApi(p),
  secured: true,
  startAction: SOCIAL_MEDIA_LOADING,
  successAction: SOCIAL_MEDIA_LOADED,
  errorAction: SOCIAL_MEDIA_LOAD_ERROR,
});
